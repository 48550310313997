import { DataStore } from '@aws-amplify/datastore';
import { Avatar } from "@mui/material";
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { InquiryBid, BidStatus, FreightForwarder, Inquiry, InquiryBidContainerDetails, CustomerBidStatus } from '../../../../models';
import RewardIcon from '../../../../assets/svg/connectpro/RewardIcon.svg';
import Route from '../../../../assets/svg/connectpro/Route.svg';
import { InputTextarea } from 'primereact/inputtextarea';
import uniqid from 'uniqid';
import { toast } from 'react-toastify';
import moment from "moment";
import { GetAWSObjectURL } from '../../../../components/helper/aws';
import "../../../../proConnect.css";
import { reactLocalStorage } from 'reactjs-localstorage';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph, loginRequest, graphConfig } from "../../../../authConfig";
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import EmpLogin from "../../../../setup/auth/emp.component";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component'; import { InquiryBidsComponent } from '../inquiry.invitebids.component';
import { CommentPopup } from '../commentpopup.component';
import InquiryView from "../inquiryview.component";



export default function QuotationDashboard() {

    const { inquiryId } = useParams();
    const [value1, setValue1] = useState('Thank you for sharing your quote. I accept your quote. Thank you');
    const [quotation, setQuotation] = useState('BELOW CHARGES FROM DOOR TO DOOR – DG SURCHARGES INCLUDED POD Net Air Freight Transit time Validity Airline AED POD Net Air Freight Transit time Validity Other charges if needed: at cost. Such as warehouse/inspection/packing etc. SUBJECT TO SPACE CONFIRMATION AT THE TIME OF BOOKING ALL RATES MENTIONED ABOVE EXCLUDE INSURANCE, DUTY AND VAT, IF ANY, AT ACTUALS');

    const [inquiryBidList, setInquiryBidList] = useState([]);
    const [isMailPopup, setMailPopup] = useState(0);
    const [commentsPopup, setCommentsPopup] = useState("");

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const isMicrosoftAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [selectedBidId, setSelectedBidId] = useState("");

    //Top Tiles Data
    const [totalBidsInvited, setTotalBidsInvited] = useState('0');
    const [responsedBidsInvited, setResponsedBidsInvited] = useState('');
    const [notResponsedBidsInvited, setNotResponsedBidsInvited] = useState('');
    const [confirmedBidsInvited, setConfirmedBidsInvited] = useState('');
    const [declinedBidsInvited, setDeclinedBidsInvited] = useState('');
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [inquiryBidInvitee, setInquiryBidInvitee] = useState([]);
    const [attachmentId, setAttachmentId] = useState();
    const [selectedCheckbox, setSelectedCheckbox] = useState('');



    let quotationTemplate = '<p style="text-align:center"><span style="font-size:22px">Quotation</span></p>  <p><span style="font-size:18px">Hello John,</span></p>  <p>&nbsp;</p>  <p>Please find below quotation.</p>  <p>&nbsp;</p>  <table border="1" cellpadding="1" cellspacing="1" style="width:500px"> 	<tbody> 		<tr> 			<td style="border-bottom:1px solid #000000">Description</td> 			<td style="border-bottom:1px solid #000000; text-align:center">Quantity</td> 			<td style="border-bottom:1px solid #000000; text-align:right">Total</td> 		</tr> 		<tr> 			<td>Item 1</td> 			<td style="text-align:center">1</td> 			<td style="text-align:right">$1,000</td> 		</tr> 		<tr> 			<td>Item 2</td> 			<td style="text-align:center">1</td> 			<td style="text-align:right">$500</td> 		</tr> 		<tr> 			<td>&nbsp;</td> 			<td style="text-align:center"><strong>Total</strong></td> 			<td style="text-align:right"><strong>$1,500</strong></td> 		</tr> 	</tbody> </table>  <p>&nbsp;</p>  <p>&nbsp;</p>  <p>Thanks</p>  <p>ProConnect Team</p>';


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    const handleDeleteClick = async (e, id) => {

        e.preventDefault();
        const objDeleteBid = await DataStore.query(InquiryBid, id);


        alertify.confirm('Confirmation', 'Do you want to delete this Bid?', async function () {
            DataStore.delete(objDeleteBid);
            BindList();
            toast.error("Deleted");
        }
            , function () { });

    };

    const [currentEmailMessageId, setCurrentEmailMessageId] = useState("");
    async function BindList() {
        var inquiryDetails = await DataStore.query(Inquiry, inquiryId);
        if (inquiryDetails != null) {
            setCurrentEmailMessageId(inquiryDetails.emailMessageId)
        }

        console.log("inquiryIdinquiryId :", inquiryId)
        var inquiryConfirmedBids = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.isApproved.eq(true)

        ]));


        var bidInvitees = await DataStore.query(InquiryBid, (c) => c.inquiryId.eq(inquiryId));
        var inquiryDeclinedCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.DECLINED)
        ]));

        var inquiryConfirmedCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.CONFIRMED)
        ]));

        var notResponsedBidCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.INVITED)
        ]));



        let inquiryBidInviteeData = []

        for (let i = 0; i < inquiryConfirmedBids.length; i++) {

            var frightForwordersDetails = await DataStore.query(FreightForwarder, inquiryConfirmedBids[i].freightForwarderId);

            var inquiryContainerDetailCount = await DataStore.query(InquiryBidContainerDetails, (c) => c.inquiryBid.eq(inquiryConfirmedBids[i].id))

            let inquiryContainerAmount = 0;
            var counter = inquiryContainerDetailCount.length;
            for (let j = 0; j < counter; j++) {
                let inquiryAmount = inquiryContainerDetailCount[j].amount;
                inquiryContainerAmount = inquiryContainerAmount + inquiryAmount / counter;
            }

            let getLogoPath = await GetAWSObjectURL(frightForwordersDetails.logo);

            let data = {
                "InviteBidId": inquiryConfirmedBids[i].id,
                "InviteDate": inquiryConfirmedBids[i].createdAt,
                "AvgAmount": inquiryContainerAmount.toFixed(2),
                "InviteUpdatedDate": inquiryConfirmedBids[i].updatedAt,
                "FrightForwardName": frightForwordersDetails.name,
                "BidDate": inquiryConfirmedBids[i].bidDate,
                'fLogo': getLogoPath
            }
            inquiryBidInviteeData.push(data);
        }
        setInquiryBidList(inquiryBidInviteeData)
        setSelectedCheckbox(inquiryConfirmedBids[0].customerStatus)
        setInquiryBidInvitee(bidInvitees);
        setTotalBidsInvited(bidInvitees.length);
        setDeclinedBidsInvited(inquiryDeclinedCount.length ? inquiryDeclinedCount.length : 0);
        setConfirmedBidsInvited(inquiryConfirmedCount.length ? inquiryConfirmedCount.length : 0);
        setNotResponsedBidsInvited(notResponsedBidCount.length);
        setResponsedBidsInvited(inquiryDeclinedCount.length + inquiryConfirmedCount.length);
        setAttachmentId(uniqid());
    }


    async function sendForwardEmail() {

        var customQueryURL = graphConfig.graphMeSingleMessages + "/" + currentEmailMessageId + '/replyAll';
        var requestBody = { "comment": quotationTemplate };
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken, customQueryURL, "POST", requestBody).then(async (currentResponse) => {
                toast.success("Quotation send successfully")
            });
        });
    }

    async function openCommentsPopup(bidId) {
        var bidDetails = await DataStore.query(InquiryBid, bidId);
        console.log("biddetail" + bidDetails.commentReason);
        setSelectedBidId(bidId)
        setCommentsPopup(1)
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);


    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="target-main-wrapper inquiesid pl-16">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to="/employee/inquiries" className="text-[#344054] text-[14px] dark:text-white py-2 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Inquiry ID {reactLocalStorage.get('inquiryNo')}</div>
                        </div>

                    </div>

                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link onClick={() => {
                                    reactLocalStorage.set('isInquiryEdit', true);
                                }}
                                    to={"/employee/inquiry/" + inquiryId} className="">Inquiry</Link></li>
                                <li><Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="">Bids</Link></li>
                                <li><Link to={"/employee/inquiry/quotation/dashboard/" + inquiryId} className="active">Quotation</Link></li>
                                <li><Link to={"/employee/inquiry/timeline/dashboard/" + inquiryId} className="">Timeline</Link></li>
                                <li><Link to={"/employee/inquiry/mails/dashboard/" + inquiryId} className="">Mails</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">
                            <Link onClick={() => setViewPopUp(1)} to="#" className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                <i className="pi pi-info-circle m-1"></i>
                                Basic Information
                            </Link>
                        </div>
                    </div>

                    <div className='inquiesbids mt-5'>
                        <div className='mt-10 flex-row grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-6 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-10 bids_feture'>
                            <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] invited-bg'>
                                <div className='mt-2'>
                                    <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Total Bids Invited</h6>
                                    <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{totalBidsInvited}</p>
                                </div>
                            </div>

                            <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] responded-bg'>
                                <div className='mt-2'>
                                    <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Responded</h6>
                                    <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{responsedBidsInvited}</p>
                                </div>
                            </div>
                            <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] unresponsive-bg'>
                                <div className='mt-2'>
                                    <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Unresponsive</h6>
                                    <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{notResponsedBidsInvited}</p>
                                </div>
                            </div>
                            <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] declined-bg'>
                                <div className='mt-2'>
                                    <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Declined</h6>
                                    <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{declinedBidsInvited}</p>
                                </div>
                            </div>
                            <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] confirmed-bg'>
                                <div className='mt-2'>
                                    <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Confirmed Bids</h6>
                                    <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{confirmedBidsInvited}</p>
                                </div>
                            </div>
                            <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] status-bg'>
                                <div className='mt-2'>
                                    <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Status</h6>
                                    <p className='text-[#1B55AF] mt-1 px-3 py-2 rounded-full text-[12px] inline-flex items-center justify-center gap-1 bg-[#E8EEF7]'><div class="w-2 h-2 bg-[#1B55AF] rounded-full"></div>Open</p>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className={"block w-full mt-5"}> {inquiryBidList != 0 ? <div className="p-2 pb-0">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Confirmed Bidder</div>
                        </div>
                        {
                            inquiryBidList.map(item => (
                                <div className="bg-white dark:bg-[#2A2E32] mt-3 py-2 rounded-lg px-3 boxshadow grid grid-cols-1 divide-y">
                                    <div className='flex justify-between my-1'>
                                        <div className='flex'>
                                            <div className="flex gap-4 items-center">
                                                <Avatar
                                                    alt={item.FrightForwardName}
                                                    src={item.fLogo}
                                                    sx={{ width: 56, height: 56 }}
                                                />
                                            </div>

                                            <div>
                                                <p className='font-extrabold text-lg mb-1 dark:text-white ml-1'>{item.FrightForwardName}</p>
                                                <div className='flex gap-x-4 ml-1'>
                                                    <span className='flex '><i className="pi pi-calendar pt-1 pr-1"></i>Bid Invite Date :<p className='font-semibold ml-1'>{moment(item.InviteDate).format('MM/DD/YYYY')}</p></span>
                                                    <span className='flex '><i className="pi pi-calendar pt-1 pr-1"></i>Bid Submitted Date :<p className='font-semibold ml-1'>{moment(item.BidDate).format('MM/DD/YYYY')}</p></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex gap-x-14'>
                                            <span><p className='text-sm'>Avg. Price Per Container</p><p className='font-semibold'>${item.AvgAmount}</p></span>
                                            <span><p className='text-sm'>Avg. Freight Per Container</p><span className='flex'><p className='font-semibold'>-</p><p className='font-medium text-xs text-[#01813F] p-1'>View Breakdown</p></span></span>
                                            <div className='p-4 bg-[#EDF7EE]' style={{ height: '60px', width: '60px', border: '1px solid #EDF7EE', borderRadius: '50%' }}>
                                                <span className='flex gap-x-1 font-semibold'><img src={RewardIcon} alt='RewardIcon' />1</span>
                                            </div>

                                        </div>

                                    </div>

                                    <div className='flex justify-between my-1 py-5'>
                                        <span><p className='text-sm'>Demurrage Free Days</p><p className='font-semibold'>2 Days</p></span>
                                        <span><p className='text-sm'>Detention Free Days</p><p className='font-semibold'>2 Days</p></span>
                                        <span><p className='text-sm'>Combined Free Days</p><p className='font-semibold'>2 Days</p></span>
                                        <span><p className='text-sm'>Transit Days</p><p className='font-semibold'>5 Days</p></span>
                                        <span><p className='text-sm'>Schedule</p><p className='font-semibold'>N/A</p></span>
                                        <span><p className='text-sm'>Valid Till</p><p className='font-semibold'>22/10/2022</p></span>
                                        <span><p className='text-sm'>Transhipment</p><p className='font-semibold'>N/A</p></span>
                                        <span className='flex gap-x-2'><img src={Route} alt="Route" /><span><p className='text-sm'>Route</p><p className='font-semibold'>Direct</p></span></span>

                                    </div>
                                    <div className='flex justify-between py-3'>
                                        <span className='flex '><i className="pi pi-stopwatch pt-1 pr-1"></i>Last Updated Date:<p className='font-semibold'>{moment(item.InviteUpdatedDate).format('MM/DD/YYYY')}</p></span>

                                        <div className='flex gap-x-6'>
                                            <div className='flex justify-between gap-x-2 mt-1'>
                                                {/* <p className='flex font-medium text-xs text-[#01813F] p-1'>
                                                    <i className='pi pi-envelope mr-1'></i>
                                                    <Link onClick={() => setMailPopup(1)} to="#">Mail</Link>
                                                </p> */}
                                                <p className='flex font-medium text-xs text-[#01813F] p-1'>
                                                    <i className='pi pi-trash mr-1'></i>
                                                    <Link onClick={(e) => handleDeleteClick(e, item.InviteBidId)} to="#">Delete</Link>
                                                </p>
                                                <p className='flex font-medium text-xs text-[#01813F] p-1'>
                                                    <i className='pi pi-comment mr-1'></i>
                                                    <Link onClick={() => openCommentsPopup(item.InviteBidId)} to="#">View Notes</Link>
                                                </p>
                                            </div>
                                            {/* <Link to="#" className="flex text-[#344054] text-[14px] dark:text-white py-1.5 px-1 rounded-md border border-[#D0D5DD]">
                                                <i className="pi pi-times-circle m-1"></i>
                                                <p className='font-medium text-xs mt-1'>Decline Bid</p>
                                            </Link> */}
                                            {/* <Link to="#" className="flex bg-[#B3DDC7] text-[#344054] text-[14px] text-white py-1.5 px-1 rounded-md border border-[#D0D5DD]">
                                                <i className="pi pi-check-circle m-1 "></i>
                                                <p className='font-medium text-xs mt-1'>Confirmed Bid</p>
                                            </Link> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }


                        <div className="bg-white dark:bg-[#2A2E32] mt-6 py-4 rounded-lg px-3 boxshadow">
                            <div>
                                <p className='pl-3'>Quotation</p>
                                <div className='p-3'>
                                    <InputTextarea className='p-2 w-full border border-stone-400 rounded' value={quotation} onChange={(e) => setQuotation(e.target.value)} rows={5} autoResize />
                                </div>
                            </div>

                            <div>
                                <p className='pl-3'>Customer Response</p>
                                <div className='flex p-3 gap-x-8'>
                                    <div className="field-radiobutton">
                                        <input type="radio" name="" id="" checked={selectedCheckbox === 'ACCEPTED'}
                                            disabled />
                                        <label htmlFor="city1" className='font-light text-sm ml-2 '>Accepted</label>
                                    </div>
                                    <div className="field-radiobutton" >
                                        <input type="radio" name="" id="" className='"appearance-none checked:bg-red-500 ' disabled checked={selectedCheckbox === 'REJECTED'} />
                                        <label htmlFor="city2" className='font-light text-sm ml-2' >Declined</label>
                                    </div>
                                    <div className="field-radiobutton">
                                        <input type="radio" name="" id="" disabled checked={selectedCheckbox === 'REQUESTFORREVISION'} value="REQUESTFORREVISION" />
                                        <label htmlFor="city3" className='font-light text-sm ml-2'>Request Revision</label>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <p className='pl-3'>Customer Remarks</p>
                                <div className='p-3'>
                                    <InputTextarea className='p-2 w-full border border-stone-400 rounded' value={value1} onChange={(e) => setValue1(e.target.value)} rows={5} autoResize />
                                </div>
                            </div>
                        </div>

                        <div className="grow flex float-right gap-3 my-3">
                            <Link
                                to="#"
                                className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md  dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white"
                            >
                                Close
                            </Link>

                            {isMicrosoftAuthenticated === true && currentEmailMessageId !== "" ?
                                <div>
                                    <button
                                        onClick={async () => await sendForwardEmail()}
                                        className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md "
                                    >Forward </button>
                                </div>
                                :
                                <div>
                                    <button className="disabled py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md "
                                    >Forward </button>
                                </div>
                            }
                        </div>

                    </div> : null}


                        <div id="selector" className={isMailPopup === 1 ? 'overlay' : ''}></div>

                        {
                            isMailPopup === 1 ?
                                <InquiryBidsComponent
                                    closePopUp={() => setMailPopup(0)}
                                // bindList={() => BindList()}
                                />

                                : null
                        }
                    </div>

                </div>
            </div>
            <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>

            {
                isViewPopUp === 1 ?
                    <div>
                        <InquiryView
                            closePopUp={() => setViewPopUp(0)}
                        />
                    </div> : null
            }

            <div id="selector"></div>


            {/* ##################### Add Comment Popup ###################################### */}
            <div id="selector" className={commentsPopup === 1 ? 'overlay' : ''}></div>
            {
                commentsPopup === 1 ?
                    <div><CommentPopup closePopup={() => setCommentsPopup(0)} bidsId={selectedBidId} /></div> : null
            }


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}