
function ConvertResponseForSelect(responses,defaultArray=[]) {
    var myArray = [];
    if(!Array.isArray(defaultArray)) {myArray.push(defaultArray);}
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.name, value: value.id });
    })
    return myArray;
};

function ConvertResponseForSelectTwoNames(responses,defaultArray=[]) {
    var myArray = [];
    if(!Array.isArray(defaultArray)) {myArray.push(defaultArray);}
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ label: value.firstName + ' ' + value.lastName, value: value.id });
    })
    return myArray;
};

function ConvertResponseForSelectAttributes(responses,defaultArray=[]) {
    var myArray = [];
    if(!Array.isArray(defaultArray)) {myArray.push(defaultArray);}
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        var myItem = [];
        for (const [attributeKey, attributeValue] of Object.entries(value)) {
            
            if(attributeKey==='name')
            {
                myItem['label'] =attributeValue;
                myItem[attributeKey] =attributeValue;
            }
            else
            {
                myItem[attributeKey] =attributeValue;
            }
        }
        myArray.push(myItem);
    })
    return myArray;
};


function ObjectToArray(object) {
    let data = [];
    for (const [key, value] of Object.entries(object)) {
        data[key] = value;
    }
    return data;
}

function ConvertEnumToArray(responses,defaultArray=[]) {
    var myArray = [];
    if(!Array.isArray(defaultArray)) {myArray.push(defaultArray);}
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key]) => {
        myArray.push({ label: key, value: key });
    })
    return myArray;
};



function GetSingleValueFromResponse (responses,keyName)
{

    var returnValue = '';
    var myObject = Object.entries(responses);
    myObject.forEach(([key, value]) => {
        for (const [attributeKey, attributeValue] of Object.entries(value)) {
            if(attributeKey === keyName)
            {
                returnValue = attributeValue;
                break;
            }
        }
    })

   return returnValue;
   

}

function GetValueFromArray(myArray,keyName)
{
    for (var key in myArray) {
        if(myArray[key]['Name']===keyName)
        {
           return myArray[key]['Value'];
        }
    }
}


function ObjectLength( object ) {
    var length = 0;
    for( var key in object ) {
        if( object.hasOwnProperty(key) ) {
            ++length;
        }
    }
    return length;
};

function ConvertResponseForId(responses,Id) {
    let id = ""
    var myObject = Object.entries(responses);
    myObject.forEach(([key, value]) => {
        if(Id === value["id"]){
            id = value["id"]
        }
    })
    return id
};


function GetArrayItemIndexFromKeyValue(myArray,key,keyValue) {


    var returnArrayItemIndex=0;
    for(var i=0;i<myArray.length;i++)
    {
        if(myArray[i][key] === keyValue)
        {
            returnArrayItemIndex = i;
            break;
        }
    }
    return returnArrayItemIndex

};

function GetArrayItemFromKeyValue(myArray,key,keyValue) {



    var returnArrayItem=[];
    for(var i=0;i<myArray.length;i++)
    {
        if(myArray[i][key] === keyValue)
        {
            returnArrayItem = myArray[i];
            break;
        }
    }
    return returnArrayItem

};

function GetArrayPreviousItemFromKeyValue(myArray,key,keyValue) {


    var returnArrayItem=[];
    for(var i=0;i<myArray.length;i++)
    {
        if(myArray[i][key] === keyValue)
        {
            var previousItemIndex = i-1;
            if(myArray[previousItemIndex]!=null)
            {
                returnArrayItem = myArray[previousItemIndex];
                break;
            }
        }
    }
    return returnArrayItem

};


function GetArrayNextItemFromKeyValue(myArray,key,keyValue) {


    var returnArrayItem=[];
    for(var i=0;i<myArray.length;i++)
    {
        if(myArray[i][key] === keyValue)
        {
            var nextItemIndex = i+1;
            if(myArray[nextItemIndex]!=null)
            {
                returnArrayItem = myArray[nextItemIndex];
                break;
            }
        }
    }
    return returnArrayItem

};


function RemoveItemFromArrayByIdValue(myArray,compareValue) {

    for(var i=0;i<myArray.length;i++)
    {
        if(myArray[i].id === compareValue)
        {
            myArray.splice(i, 1);
            break;
        }
    }
    return myArray

};




export { ConvertResponseForSelect,ConvertResponseForSelectAttributes,ConvertResponseForId,ConvertEnumToArray,GetValueFromArray,
       GetSingleValueFromResponse,ObjectLength,ObjectToArray,ConvertResponseForSelectTwoNames,RemoveItemFromArrayByIdValue,
       GetArrayItemIndexFromKeyValue,GetArrayItemFromKeyValue,GetArrayPreviousItemFromKeyValue,GetArrayNextItemFromKeyValue
       };
