import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { FreightForwarder, State,Country } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import EditButton from '../../../../../components/common/buttons/EditButton';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import { ValidateEmployeeRole } from "../../../../../components/helper/validateRole";
import { Avatar } from "@mui/material";
import { GetAWSObjectURL } from '../../../../../components/helper/aws';




export default function EmployeeFrightForward() {


    let navigateTo = useNavigate();

    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/employee/settings/createfrightforward/' + id);
    };

    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        const objDeleteFreightForwarder = await DataStore.query(FreightForwarder, id);
        alertify.confirm('Confirmation', 'Do you want to delete this Freight Forwarder?', async function () {
            //Delete Existing FreightForwarder
            DataStore.delete(FreightForwarder, (c) => c.id("eq", id))
            //Delete FreightForwarder
            DataStore.delete(objDeleteFreightForwarder);
            BindList();
            toast.error("Deleted");
        }
            , function () { });

    };

    //*Column Name
    const DataTableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row) => (
                <>
                    <div className="flex gap-4 items-center">
                        <Avatar
                            style={{ width: 28, height: 28 }}
                            alt={row.name}
                            src={row.logo} />
                        {row.name}
                    </div>
                </>
            ),
            width: "200px",
            sortable: true,
        },
        {
            name: 'Address 1',
            selector: row => row.address1,
            sortable: true,
        },
        {
            name: 'Address 2',
            selector: row => row.address2,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Zip Code',
            selector: row => row.zipCode,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNo,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.countryName,
            sortable: true,
        },
        {
            name: 'State',
            selector: row => row.stateName,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row.id)} ><EditButton /></Link>
                        <Link onClick={(e) => handleDeleteClick(e, row.id)} ><DeleteButton /></Link>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true
        }
    ];

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //State
    const [freightForwarders, seFreightForwarders] = useState([]);
    const [FilterFreightForwarders, setFilterFreightForwarders] = useState("");

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {
        let finalData = [];

        var freightForwardersResponses = await DataStore.query(FreightForwarder, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });

        freightForwardersResponses.forEach(async (item, i) => {
            let stateResponses = await DataStore.query(State, (c) => c.id.eq(item.stateId));

            let countryResponses = await DataStore.query(Country, (c) => c.id.eq(stateResponses[0].countryId));
    

            let data={};
            if (item.logo) {
                let getlogoPath = await GetAWSObjectURL(item.logo);
                data = {
                    ...item,
                    "stateName": stateResponses[0].name,
                    "countryName": countryResponses[0].name,
                    "logo": getlogoPath,
                }
            }else{
                data = {
                    ...item,
                    "stateName": stateResponses[0].name,
                    "countryName": countryResponses[0].name,

                }
            }

            finalData.push(data)
            
            if(i === freightForwardersResponses.length-1){
                let sortArray = finalData.sort((a, b) => (a.name > b.name ? 1 : -1))
                seFreightForwarders(finalData)
                setFilterFreightForwarders(sortArray)
            }
        })
    }


    const searchFrightForward = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...FilterFreightForwarders]
            let filteredData = data.filter(e =>
                e.name.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.address1.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.email.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.city.toLowerCase().includes(searchedText.toLowerCase())
            )
            seFreightForwarders(filteredData)
        } else {
            let data = [...FilterFreightForwarders]
            seFreightForwarders(data)
        }
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return (
        isAuthenticated ? (

            <div>
                <div className="pt-16">
                    <TopNavBar />
                    <LeftSideBar />
                    <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                        <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                            <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                                <div className="md:flex block md:col-span-2 mb-4">
                                </div>
                                <div className="md:flex block mb-4">
                                    <h2 className="page-title">Freight Forwarders</h2>
                                </div>
                            </div>

                            <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                                <div className="relative">
                                    <input onChange={(e) => searchFrightForward(e.target.value)} type="text" id="required-search" className=" rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search..." />
                                    <button type="submit" className="absolute left-3 top-5 translate-y-[-50%] dark ">
                                        <i className="icon search_icon "></i>
                                    </button>
                                </div>
                                <div className="md:flex block mb-4 gap-4">
                                    <Link to="/employee/settings" className="flex py-2 px-5 bg-[#FFF] hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-black transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF] dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">
                                        Back
                                    </Link>
                                    <Link to="/employee/settings/createfrightforward" className="flex py-2 px-5 bg-btn-blue hover:bg-blue-0 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-[14px] text-center font-medium drop-shadow shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 leading-6  justify-center rounded-md border-1 border-[#1570EF]">
                                        Create
                                    </Link>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className={"block w-full pt-5"}>
                                {
                                    <DataTable
                                        columns={DataTableColumns}
                                        data={freightForwarders}
                                        customStyles={DataTableCustomStyles}
                                        pagination
                                        highlightOnHover
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
            <EmpLogin sessionExpired={1} />
        )

    )
}



