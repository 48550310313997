import { DataStore } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { FinancialYear } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

export default function EmployeeCreateFinancialYear() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editFinancialYearId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [financialId, setFinancialId] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [dateName, setDateName] = useState("");
    let navigateTo = useNavigate();

    //Save Fields
    const [role, setRole] = useState("");

    //Create FinancialYear
    const saveFinancialYear = async () => {
        if (fromDate === "") { toast.error("Please enter From Date."); return; }
        else if (toDate === "") { toast.error("Please enter To Date."); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            const original = await DataStore.query(FinancialYear, financialId);
            await DataStore.save(
                FinancialYear.copyOf(original, (updated) => {
                    updated.fromDate = moment(fromDate).format('YYYY-MM-DD');
                    updated.toDate = moment(toDate).format('YYYY-MM-DD')
                    updated.name = moment(fromDate).format('YYYY') + "-" + moment(toDate).format('YYYY')
                })
            );
        } else {
            await DataStore.save(new FinancialYear({
                "fromDate": moment(fromDate).format('YYYY-MM-DD'),
                "toDate": moment(toDate).format('YYYY-MM-DD'),
                "name" : moment(fromDate).format('YYYY') + "-" + moment(toDate).format('YYYY'),
            })).then((reponce) => {
                // console.log("reponce------"+ JSON.stringify(reponce))
            })
        }
        setPleaseWaitButton(false);
        navigateTo("/employee/settings/financialyear");
    };

    const setName = async (e) => {
        setToDate(e)
        let dateName = moment(fromDate).format('YYYY') + "-" + moment(e).format('YYYY')
        setDateName(dateName)
    };

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editFinancialYearId != null) {
                setFinancialId(editFinancialYearId);

                const original = await DataStore.query(FinancialYear, editFinancialYearId);
                if (original != null) {
                    setFromDate(moment(original.fromDate).toDate())
                    setToDate(moment(original.toDate).toDate())
                    setEditMode(true);
                    setDateName(original.name)
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/employee/settings/financialyear");
                }

            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />

                <div className="pl-16">
                    <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white dark:bg-[#232528] rounded-md shadow ">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Financial Year
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                            <div className="relative mb-4">
                                                <label htmlFor="" className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    From Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label>
                                                </label>
                                                <DatePicker
                                                    selected={fromDate}
                                                    onChange={(e) => setFromDate(e)}
                                                    // minDate={new Date()}
                                                    //dateFormat="MM-DD-YYYY"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231]  dark:text-[#8E8E8E]"
                                                />

                                            </div>
                                            <div className="relative mb-4">
                                                <label htmlFor="" className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    To Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label>
                                                </label>
                                                <DatePicker
                                                    selected={toDate}
                                                    onChange={(e) => {
                                                        setName(e)
                                                    }}
                                                    // minDate={new Date()}
                                                    //dateFormat="MM-DD-YYYY"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-grey-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />

                                            </div>
                                            {/* <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    Name<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input
                                                    value={dateName}
                                                    onChange={e => setDateName(e.target.value)}
                                                    type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085]  " placeholder="Name" />
                                            </div> */}

                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link
                                                to="/employee/settings/financialyear"
                                                className=" py-2 px-5 bg-white  text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF] "
                                            >
                                                Cancel
                                            </Link>
                                            <button
                                                onClick={() => saveFinancialYear()}
                                                className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                            >
                                                {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}
