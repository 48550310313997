import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
// import React, { useState } from 'react';
// import { Link } from "react-router-dom";

export default function EmployeeDashboardDetailedTradeLine() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const yearList = [
        {
            'Country_of_Origin': 'China',
            'Port_of_Origin': 'Shanghai',
            'Destination_Country': 'South Korea',
            'Port_of_Destination': 'Busan',
            'Inquiry_Received': '50',
            'Quotation_Shared': '40',
            'Quote_Accepted': '25',
            'Conversion': '50%',
            'Revenue': '5M',
            'GM': '0.5M',
            'GM_LP_Var': '2%'
        },
        {
            'Country_of_Origin': 'Singapore',
            'Port_of_Origin': 'Singapore',
            'Destination_Country': 'China',
            'Port_of_Destination': 'Tianjin',
            'Inquiry_Received': '50',
            'Quotation_Shared': '40',
            'Quote_Accepted': '25',
            'Conversion': '50%',
            'Revenue': '5M',
            'GM': '0.5M',
            'GM_LP_Var': '2%'
        },

    ];


    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className='pl-16'>
                <div className="dashboard-main-wrapper dashboardproconnect">
                    <div className="p-5 pb-0">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/dashboard" className="">Summary</Link></li>
                                    <li><Link className='active'>Detailed</Link></li>
                                    <li><Link to="#">Drilldown</Link></li>
                                    <li><Link to="#">GeoSpatial</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:flex justify-between my-6 pl-5">
                    <div className="tabs_two">
                        <ul className="flex">
                            <li><Link to={"/employee/dashboard/detailed"} className="">Year</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/month"}>Month</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/services"} className="">Services</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/customers"} className="">Customers</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/freightforwarders"} className="">Freight Forwarders</Link></li>
                            <li><Link to={'/employee/dashboard/detailed/salesexecutive'} className="">Sales Executive</Link></li>
                            <li><Link className="active">Trade Line</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/margins"} className="">Margins</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/cargotype"} className="">Cargo Type</Link></li>

                        </ul>
                    </div>
                </div>

                <div className="m-5 bg-white rounded-lg mt-2 pt-5 px-1 dark:bg-[#2A2E32] mt-5  border border-[#E4E7EC] dark:border-[#344054] dark:text-white  mb-10 pb-10 ">

                    <div className='flex gap-4 justify-between items=center mr-2 px-4'>
                        <div className='text-lg font-normal dark:text-white'>Trade Line</div>
                        <div className='flex gap-4 flex-right'>
                            <Link to='' className='flex justify-end w-20 flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-print'></i> Print</Link>
                            <Link to='' className='flex justify-end w-34  flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-download'></i> Download</Link>
                        </div>
                    </div>

                    <div>
                        <div className=" rounded-lg mt-5">
                            <DataTable paginator value={yearList} responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="Country_of_Origin" header="Country of Origin" sortable></Column>
                                <Column field="Port_of_Origin" header="Port of Origin" filter></Column>
                                <Column field="Destination_Country" header="Destination Country" sortable={true}></Column>
                                <Column field="Port_of_Destination" header="Port of Destination" sortable={true}></Column>
                                <Column field="Inquiry_Received" header="Inquiry - Received" sortable={true}></Column>
                                <Column field="Quotation_Shared" header="Quotation-Shared" sortable={true}></Column>
                                <Column field="Quote_Accepted" header="Quote-Accepted" sortable={true}></Column>
                                <Column field='Conversion' header="% Conversion" sortable={true}></Column>
                                <Column field='Revenue' header="Revenue ($)" sortable={true}></Column>
                                <Column field='GM' header="GM ($)" sortable={true}></Column>
                                <Column field='GM_LP_Var' header="GM-LP Var (%)" sortable={true}></Column>

                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}