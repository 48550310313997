//***** Employee ******** /
import EmployeeLogin from "./auth/emp.component";
import EmployeeLanding from "../pages/employee/landing.component";
import EmployeeProfile from "../pages/employee/profile.component";
import EmployeeChangePassword from "../pages/employee/changePassword.component";

import EmployeeDashboard from "../pages/employee/dashboard.component";
import EmployeeDashboardDetailedMargins from "../pages/employee/setting/dashboard/dashboard.detailed.margins";
import EmployeeDashboardDetailedCargoType from "../pages/employee/setting/dashboard/dashboard.detailed.cargotype";
import EmployeeSettings from "../pages/employee/settings.component";
import EmployeeMessaging from "../pages/employee/messaging.component";
import MailBox from "../pages/employee/mailbox.component";

//Inquiry
import Inquiries from "../pages/employee/inquiry/inquiries.component";
import InquiryDashboard from "../pages/employee/inquiry/inquiry.component";
import InquiryView from "../pages/employee/inquiry/inquiryview.component";

//Bid
import BidDashboard from "../pages/employee/inquiry/bid/dashboard.component";
import CompareBids from "../pages/employee/inquiry/bid/comparebids.component";

//Mails
import MailsDashboard from "../pages/employee/inquiry/mails/dashboard.component";

//Quotation
import QuotationDashboard from "../pages/employee/inquiry/quotation/dashboard.component";

//Timeline 
import TimelineDashboard from "../pages/employee/inquiry/timeline/dashboard.component";


//Common
import PageNotFound from '../pages/404Page';
import HomePage from '../pages/homePage';

import { Routes, Route } from "react-router-dom";

//Masters
import EmployeeRole from "../pages/employee/setting/masters/role/role.component";
import EmployeeCreateRole from "../pages/employee/setting/masters/role/createrole.component";

import EmployeeFinancialYear from "../pages/employee/setting/masters/financialyear/financialyear.component";
import EmployeeCreateFinancialYear from "../pages/employee/setting/masters/financialyear/createfinancialyear.component";

import EmployeeService from "../pages/employee/setting/masters/services/services.component";
import EmployeeCreateService from "../pages/employee/setting/masters/services/createservice.component";

import EmployeeCustomers from "../pages/employee/setting/masters/customers/customers.component";
import EmployeeCreateCustomer from "../pages/employee/setting/masters/customers/createcustomer.component";

import EmployeeFrightForward from "../pages/employee/setting/masters/frightforward/frightforward.component";
import EmployeeCreateFrightForward from "../pages/employee/setting/masters/frightforward/createfrightforward.component";

import EmployeeSalesPerson from "../pages/employee/setting/masters/salesperson/salesperson.component";
import EmployeeCreateSalesPerson from "../pages/employee/setting/masters/salesperson/createsalesperson.component";

import Employees from "../pages/employee/setting/masters/employee/employee.component";
import CreateEmployee from "../pages/employee/setting/masters/employee/createemployee.component";

import EmployeeCountry from "../pages/employee/setting/masters/country/country.component";
import EmployeeCountryCreate from "../pages/employee/setting/masters/country/createcountry.component";

import EmployeeStates from "../pages/employee/setting/masters/states/state.componet";
import CreateEmployeeState from "../pages/employee/setting/masters/states/createstate.component";

import EmployeeInquiryPorts from "../pages/employee/setting/masters/inquiryport/inquiryport.component";
import EmployeeCreateInquiryPort from "../pages/employee/setting/masters/inquiryport/createinquiryport.component";

import EmployeeCargoTypes from "../pages/employee/setting/masters/cargotype/cargotype.component";
import EmployeeCreateCargoType from "../pages/employee/setting/masters/cargotype/createcargotype.component";

import EmployeeContainers from "../pages/employee/setting/masters/container/container.component";
import EmployeeCreateContainer from "../pages/employee/setting/masters/container/createcontainer.component";

import EmployeeInquiryProducts from "../pages/employee/setting/masters/products/products.component";
import EmployeeInquiryCreateProducts from "../pages/employee/setting/masters/products/createproduct.component";

import EmployeeInquiryMovement from "../pages/employee/setting/masters/movement/movement.component";
import EmployeeInquiryCreateMovement from "../pages/employee/setting/masters/movement/createmovement.component";
import EmployeeInquiryCurrency from "../pages/employee/setting/masters/currency/currency.component";
import EmployeeInquiryCreateCurrency from "../pages/employee/setting/masters/currency/createcurrency.component";

import EmployeeStuffing from "../pages/employee/setting/masters/stuffing/stuffing.component";
import EmployeeCreateStuffing from "../pages/employee/setting/masters/stuffing/createstuffing.component";
import EmployeeDashboardDetailed from "../pages/employee/setting/dashboard/dashboard.detailed.component";

import EmployeeCreateUnit from "../pages/employee/setting/masters/units/createunit.component";
import EmployeeUnits from "../pages/employee/setting/masters/units/units.component";
import EmployeeDashboardDetailedMonth from "../pages/employee/setting/dashboard/month.component";
import EmployeeDashboardDetailedServices from "../pages/employee/setting/dashboard/services.component";
import EmployeeDashboardDetailedCustomers from "../pages/employee/setting/dashboard/customers.component";
import EmployeeDashboardDetailedFreightForwarder from "../pages/employee/setting/dashboard/freightForwarder.component";
import EmployeeDashboardDetailedSalesExecutive from "../pages/employee/setting/dashboard/salesExecutive.component";
import EmployeeDashboardDetailedTradeLine from "../pages/employee/setting/dashboard/tradeLine.component";
import EmployeeDashboardDrillDown from "../pages/employee/dashboard.drilldown.component";
import EmployeeDashboardGeoSpatial from "../pages/employee/dashboard.geospatial.component";
import EmployeeInquiryFreightForwarderQuestions from "../pages/employee/setting/masters/freightforwarderquestion/freightForwarderQuestion.component";
import EmployeeInquiryCreateFreightForwarderQuestions from "../pages/employee/setting/masters/freightforwarderquestion/createFreightForwarderQuestion.component";
import { Report } from "../pages/employee/reports/report.component";
import { CreateReport } from "../pages/employee/reports/createreport.component";
import InquiryAmount from "../pages/employee/inquiryamount.component";

import InquiryConfirmAmount from "../pages/employee/inquiryconfirmamount.component";
import EmployeeIncoTerms from "../pages/employee/setting/masters/incoterms/incoterms.component";
import EmployeeCreateIncoTerms from "../pages/employee/setting/masters/incoterms/createincoterms.component";

export default function RouteComponent() {
    return (
        <Routes>

            {/* {Dashboard} */}

            {/* Employee */}
            <Route exact path="/employee" element={<EmployeeLogin />} />
            <Route exact path="/employee/landing" element={<EmployeeLanding />} />
            <Route exact path="/employee/profile" element={<EmployeeProfile />} />
            <Route exact path="/employee/changePassword" element={<EmployeeChangePassword />} />

            <Route exact path="/employee/dashboard" element={<EmployeeDashboard />} />
            <Route exact path="/employee/dashboard/detailed" element={<EmployeeDashboardDetailed />} />
            <Route exact path="/employee/dashboard/drilldown" element={<EmployeeDashboardDrillDown />} />
            <Route exact path="/employee/dashboard/geospatial" element={<EmployeeDashboardGeoSpatial />} />
            <Route exact path="/employee/dashboard/detailed/margins" element={<EmployeeDashboardDetailedMargins />} />
            <Route exact path="/employee/dashboard/detailed/cargotype" element={<EmployeeDashboardDetailedCargoType />} />
            <Route exact path="/employee/dashboard/detailed/month" element={<EmployeeDashboardDetailedMonth />} />
            <Route exact path="/employee/dashboard/detailed/services" element={<EmployeeDashboardDetailedServices />} />
            <Route exact path="/employee/dashboard/detailed/customers" element={<EmployeeDashboardDetailedCustomers />} />
            <Route exact path="/employee/dashboard/detailed/freightforwarders" element={<EmployeeDashboardDetailedFreightForwarder />} />
            <Route exact path="/employee/dashboard/detailed/salesexecutive" element={<EmployeeDashboardDetailedSalesExecutive />} />
            <Route exact path="/employee/dashboard/detailed/tradeline" element={<EmployeeDashboardDetailedTradeLine />} />
            <Route exact path="/employee/settings" element={<EmployeeSettings />} />
            <Route exact path="/employee/messaging" element={<EmployeeMessaging />} />
            <Route exact path="/employee/mailbox" element={<MailBox />} />

            {/* Inquiry */}
            <Route exact path="/employee/inquiries/:inquirySearchTxt?" element={<Inquiries />} />
            {/* <Route exact path="/employee/inquiries" element={<Inquiries />} /> */}
            <Route exact path="/employee/inquiry/:inquiryId" element={<InquiryDashboard />} />
            <Route exact path="/employee/inquiryView" element={<InquiryView />} />

            {/* Bid */}
            <Route exact path="/employee/inquiry/bid/dashboard/:inquiryId" element={<BidDashboard />} />
            <Route exact path="/employee/inquiry/bid/comparebids/:inquiryId" element={<CompareBids />} />

            {/* Mails */}
            <Route exact path="/employee/inquiry/mails/dashboard/:inquiryId" element={<MailsDashboard />} />

            {/* Quotation */}
            <Route exact path="/employee/inquiry/quotation/dashboard/:inquiryId" element={<QuotationDashboard />} />

            {/* Timeline */}
            <Route exact path="/employee/inquiry/timeline/dashboard/:inquiryId" element={<TimelineDashboard />} />

            {/* {/ Common /} */}
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<PageNotFound />} />

            {/* {/ Settings /} */}
            {/* {/ Masters /} */}
            <Route exact path="/employee/settings/role" element={<EmployeeRole />} />
            <Route exact path="/employee/settings/createrole" element={<EmployeeCreateRole />} />
            <Route exact path="/employee/settings/createrole/:editRoleId" element={<EmployeeCreateRole />} />

            <Route exact path="/employee/settings/financialyear" element={<EmployeeFinancialYear />} />
            <Route exact path="/employee/settings/createfinancialyear" element={<EmployeeCreateFinancialYear />} />
            <Route exact path="/employee/settings/createfinancialyear/:editFinancialYearId" element={<EmployeeCreateFinancialYear />} />

            <Route exact path="/employee/settings/customers" element={<EmployeeCustomers />} />
            <Route exact path="/employee/settings/createcustomer" element={<EmployeeCreateCustomer />} />
            <Route exact path="/employee/settings/createcustomer/:editCustomerId" element={<EmployeeCreateCustomer />} />

            <Route exact path="/employee/settings/frightforward" element={<EmployeeFrightForward />} />
            <Route exact path="/employee/settings/createfrightforward" element={<EmployeeCreateFrightForward />} />
            <Route exact path="/employee/settings/createfrightforward/:editFrightForwardId" element={<EmployeeCreateFrightForward />} />

            <Route exact path="/employee/settings/salesperson" element={<EmployeeSalesPerson />} />
            <Route exact path="/employee/settings/createsalesperson" element={<EmployeeCreateSalesPerson />} />
            <Route exact path="/employee/settings/createsalesperson/:editSalesPersonId" element={<EmployeeCreateSalesPerson />} />

            <Route exact path="/employee/settings/states" element={<EmployeeStates />} />
            <Route exact path="/employee/settings/createstate" element={<CreateEmployeeState />} />
            <Route exact path="/employee/settings/createstate/:editStateId" element={<CreateEmployeeState />} />


            <Route exact path="/employee/settings/country" element={<EmployeeCountry />} />
            <Route exact path="/employee/settings/createcountry" element={<EmployeeCountryCreate />} />
            <Route exact path="/employee/settings/createcountry/:editCountryId" element={<EmployeeCountryCreate />} />


            <Route exact path="/employee/settings/employee" element={<Employees />} />
            <Route exact path="/employee/settings/createemployee" element={<CreateEmployee />} />
            <Route exact path="/employee/settings/createemployee/:editEmployeeId" element={<CreateEmployee />} />

            {/* Inquiry */}
            <Route exact path="/employee/settings/services" element={<EmployeeService />} />
            <Route exact path="/employee/settings/createservice" element={<EmployeeCreateService />} />
            <Route exact path="/employee/settings/createservice/:editServiceId" element={<EmployeeCreateService />} />

            <Route exact path="/employee/settings/inquiryports" element={<EmployeeInquiryPorts />} />
            <Route exact path="/employee/settings/createinquiryports" element={<EmployeeCreateInquiryPort />} />
            <Route exact path="/employee/settings/createinquiryports/:editInquiryPortId" element={<EmployeeCreateInquiryPort />} />

            <Route exact path="/employee/settings/cargotypes" element={<EmployeeCargoTypes />} />
            <Route exact path="/employee/settings/createcargotype" element={<EmployeeCreateCargoType />} />
            <Route exact path="/employee/settings/createcargotype/:editCargoTypeId" element={<EmployeeCreateCargoType />} />

            <Route exact path="/employee/settings/container" element={<EmployeeContainers />} />
            <Route exact path="/employee/settings/createcontainer" element={<EmployeeCreateContainer />} />
            <Route exact path="/employee/settings/createcontainer/:editContainerId" element={<EmployeeCreateContainer />} />

            {/* Inquiry products */}
            <Route exact path="/employee/settings/products" element={<EmployeeInquiryProducts />} />
            <Route exact path="/employee/settings/createproducts" element={<EmployeeInquiryCreateProducts />} />
            <Route exact path="/employee/settings/createproducts/:editProductId" element={<EmployeeInquiryCreateProducts />} />

            {/* {Inquiry Movement} */}
            <Route exact path="/employee/settings/movement" element={<EmployeeInquiryMovement />} />
            <Route exact path="/employee/settings/createmovement" element={<EmployeeInquiryCreateMovement />} />
            <Route exact path="/employee/settings/createmovement/:editMovementId" element={<EmployeeInquiryCreateMovement />} />

            {/* Inquiry Stuffing */}
            <Route exact path="/employee/settings/stuffings" element={<EmployeeStuffing />} />
            <Route exact path="/employee/settings/createstuffing" element={<EmployeeCreateStuffing />} />
            <Route exact path="/employee/settings/createstuffing/:editStuffingId" element={<EmployeeCreateStuffing />} />
            {/* Inquiry Currency */}
            <Route exact path="/employee/settings/currency" element={<EmployeeInquiryCurrency />} />
            <Route exact path="/employee/settings/createcurrency" element={<EmployeeInquiryCreateCurrency />} />
            <Route exact path="/employee/settings/createcurrency/:editCurrencyId" element={<EmployeeInquiryCreateCurrency />} />

            {/* Inquiry Freight Forwarder Question */}
            <Route exact path="/employee/settings/freightforwarderquestion" element={<EmployeeInquiryFreightForwarderQuestions />} />
            <Route exact path="/employee/settings/createfreightforwarderquestion" element={<EmployeeInquiryCreateFreightForwarderQuestions />} />
            <Route exact path="/employee/settings/createfreightforwarderquestion/:editFFQuestionId" element={<EmployeeInquiryCreateFreightForwarderQuestions />} />

            {/* INCO Term  */}
            <Route exact path="/employee/settings/incoterms" element={<EmployeeIncoTerms />} />
            <Route exact path="/employee/settings/createincoterms" element={<EmployeeCreateIncoTerms />} />
            <Route exact path="/employee/settings/createincoterms/:editIncoId" element={<EmployeeCreateIncoTerms />} />

            {/* Units */}
            <Route exact path="/employee/settings/units" element={<EmployeeUnits />} />
            <Route exact path="/employee/settings/createunit" element={<EmployeeCreateUnit />} />
            <Route exact path="/employee/settings/createunit/:editUnitId" element={<EmployeeCreateUnit />} />

            {/* Report  */}
            <Route exact path="/employee/report" element={<Report />} />
            <Route exact path="/employee/createreport" element={<CreateReport />} />

            <Route exact path="/inquirybid/:inquiryBidId" element={<InquiryAmount />} />
            <Route exact path="/confirmbid/:bidId" element={<InquiryConfirmAmount />} />

        </Routes>
    );
}
