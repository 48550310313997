import { DataStore } from '@aws-amplify/datastore';
import { FreightForwarder, InquiryBid, BidStatus, InquiryBidRule, Inquiry, InquiryContainers, Country, ContainerType, InquiryBidContainerDetails } from '../../../../models';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import "../../../../proConnect.css";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import EmpLogin from "../../../../setup/auth/emp.component";
import FlagIndia from '../../../../assets/images/flagindia.png';
import FlagshriLanka from '../../../../assets/images/flagshrilanka.png';
import RouteImg from '../../../../assets/images/route.png';
import FilterIcon from '../../../../assets/images/filter_icon.svg';
import CompareImg from '../../../../assets/images/compare_img.png';
import Bussiness from "../../../../assets/svg/connectpro/business-people-casual-meeting 1.png"
import { Menu } from "@headlessui/react";
import ConfirmImg from "../../../../assets/images/confirmImg.png";
import UserImg from "../../../../assets/images/user5.png";
import FreightForwardIcon from "../../../../assets/svg/connectpro/freightforwordicon.svg";
import cargo_icon from "../../../../assets/svg/connectpro/cragodetail_icon.svg"
import attach_icon from "../../../../assets/svg/connectpro/attach_icon.svg"
import { reactLocalStorage } from 'reactjs-localstorage';
import { GetAWSObjectURL } from '../../../../components/helper/aws';
import InquiryView from '../inquiryview.component';
import InquiryRole from '../inquiryrole.component';
import { InquiryBidsComponent } from '../inquiry.invitebids.component';
import { toast } from 'react-toastify';
import { ConfirmBid } from '../confirmbid.component';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { CustomerQuotationPopup } from '../customerquotation.component';
import { CommentPopup } from '../commentpopup.component';


export default function CompareBids(props) {

    //Pop Up
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [firstTimeRolePopup, setFirstTimeRolePopup] = useState("");
    const [isInvitePopUp, setInvitePopUp] = useState(0);
    const [totalBidsInvited, setTotalBidsInvited] = useState('0');

    //Validate User Logged In
    const { inquiryId } = useParams();

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [selectedFrifreightForwarderId, setSelectedFrifreightForwarderId] = useState("");
    const [commentsPopup, setCommentsPopup] = useState("");
    const [confirmPopup, setConfirmPopup] = useState("");
    const [customerQuotationPopup, setCustomerQuotationPopup] = useState("");
    const [confirmedFreightForwarders, setConfirmedFreightForwarders] = useState([]);

    // Comment popup
    const [commentsReason, setCommentsReason] = useState("");
    const [commentsNotes, setCommentsNotes] = useState("");
    const [selectedBidId, setSelectedBidId] = useState("");
    //Compare Count 
    const [totalRule, setTotalRule] = useState('0');

    const [checkConfirmStatus, setCheckConfirmStatus] = useState("");




    async function onLoad() {

    }

    async function bindData() {
        var inquiryDetails = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.CONFIRMED)
        ]));


        for (var i = 0; i < confirmedFreightForwarders.length; i++) {
            // console.log("confirmbid", confirmedFreightForwarders[i].isConfirmed);
            if (confirmedFreightForwarders[i].isConfirmed === true) {
                setCheckConfirmStatus(true);
                // console.log("true");
            }

        }



        let inquiryContainerAmount = 0;
        //get freightForwarder data
        let confiredFreightForwarders = [];
        for (let i = 0; i < inquiryDetails.length; i++) {
            var inquiryConfirmedId = inquiryDetails[i].id;
            // console.log("data--", inquiryConfirmedId)

            var freightForwarderArray = await DataStore.query(FreightForwarder, (c) => c.id.eq(inquiryDetails[i].freightForwarderId));


            var inquiryContainerDetailCount = await DataStore.query(InquiryBidContainerDetails, (c) => c.inquiryBid.eq(inquiryConfirmedId))

            let inquiryContainerAmount = 0;
            var counter = inquiryContainerDetailCount.length;
            for (let j = 0; j < counter; j++) {
                let inquiryAmount = inquiryContainerDetailCount[j].amount;
                inquiryContainerAmount = inquiryContainerAmount + inquiryAmount;
            }




            let data = {};
            if (freightForwarderArray[0].logo) {
                let getLogoPath = await GetAWSObjectURL(freightForwarderArray[0].logo);
                data = {
                    "sr_No": i + 1,
                    "InviteBidId": inquiryConfirmedId,
                    "freightForwarderName": freightForwarderArray[0].name,
                    "bidId": inquiryDetails[i].id,
                    "avg": inquiryContainerAmount,
                    "freightForwarderLogo": getLogoPath,
                    "freightForwarderId": freightForwarderArray[0].id,
                    "isConfirmed": inquiryDetails[i].isApproved,
                }
            } else {
                data = {
                    "freightForwarderName": freightForwarderArray[0].name,
                    "freightForwarderLogo": 'default.img'
                }
            }

            confiredFreightForwarders.push(data);
        }
        setConfirmedFreightForwarders(confiredFreightForwarders);



        //bid invite count
        var bidInvitees = await DataStore.query(InquiryBid, (c) => c.inquiryId.eq(inquiryId));
        setTotalBidsInvited(bidInvitees.length);

        //compare rule count
        var weights = await DataStore.query(InquiryBidRule, (c) => c.inquiryId.eq(inquiryId));
        setTotalRule(weights.length);




    }



    async function openCommentsPopup(bidId) {
        var bidDetails = await DataStore.query(InquiryBid, bidId);
        console.log(bidDetails.commentReason);
        setSelectedBidId(bidId)
        setCommentsReason(bidDetails.commentReason)
        setCommentsNotes(bidDetails.commentNotes)
        setCommentsPopup(1)
    }

    const handleDeleteClick = async (e, id) => {

        e.preventDefault();
        const objDeleteBid = await DataStore.query(InquiryBid, id);
        alertify.confirm('Confirmation', 'Do you want to delete this Bid?', async function () {


            DataStore.delete(objDeleteBid);

            bindData();
            toast.error("Deleted");
        }
            , function () { });

    };

    // Save comment



    useEffect(() => {
        onLoad();
        bindData();
        // console.log("confirmbid",confirmedFreightForwarders);


    }, [firstTimeRolePopup, customerQuotationPopup, confirmedFreightForwarders]);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="pl-16 dashboard-main-wrapper target-main-wrapper compare_bids" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="items-center space-x-4 sm:flex">
                            <Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="text-[#344054] bg-[#fff] text-[14px] dark:text-white py-2 px-3 rounded-md border shadow-md border-[#D0D5DD]"><i className="mr-6 icon arrow_left_double "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Inquiry ID {reactLocalStorage.get('inquiryNo')}</div>
                        </div>
                    </div>

                    <div className="justify-between my-6 lg:flex">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link to={"/employee/inquiry/" + inquiryId} className="">Inquiry</Link></li>
                                <li><Link to={"#"} className="active">Bids</Link></li>
                                <li><Link to={"/employee/inquiry/quotation/dashboard/" + inquiryId} className="">Quotation</Link></li>
                                <li><Link to={"/employee/inquiry/timeline/dashboard/" + inquiryId} className="">Timeline</Link></li>
                                <li><Link to={"/employee/inquiry/mails/dashboard/" + inquiryId} className="">Mails</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">

                            <div className='flex justify-end gap-2 right-side'>
                                <div className="mt-5 lg:mt-0">
                                    <Link onClick={() => setViewPopUp(1)} to="#" className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                        <i className="pt-1 mr-2 pi pi-info-circle text-md "></i>
                                        Basic Information
                                    </Link>
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <Link onClick={() => setFirstTimeRolePopup(1)} to="#" className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                        Rule ({totalRule})
                                    </Link>
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <Link to="#" className="bg-[#029046] flex text-[#fff] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                        Compare Bids
                                    </Link>
                                </div>
                                <div>
                                    <Link onClick={() => setInvitePopUp(1)} to="#" className="bg-[#029046] flex text-[#fff] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                        Invite Bids ({totalBidsInvited})
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* code here */}
                    <div className={"block w-full"}>
                        <div className='flex justify-between'>
                            <h4 className="text-[#101828] text-[20px] font-medium dark:text-white">
                                Compare Bids
                            </h4>
                            <div>
                                <Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                    Back
                                </Link>
                            </div>
                        </div>
                        <div className='bg-[#fff] dark:bg-[#2A2E32] rounded-lg shadow-lg mt-5'>
                            <div className='flex items-center justify-between w-full gap-3 px-5 py-6 border'>
                                <div className=''>
                                    <h3 className="text-[#344054] text-[18px] font-medium dark:text-white mb-2">{reactLocalStorage.get('inquiryNo')}</h3>
                                    <div className='flex gap-3 text-[#667085] text-[12px] font-medium items-center align-center'>
                                        <p><i className='mr-1 pi pi-clock'></i> 21 September, 2022 | 13:02 Hrs</p>
                                        <p><i className='mr-1 pi pi-clock'></i> 05 October, 2022 | 22:40 Hrs</p>
                                    </div>
                                </div>
                                <div className=''>
                                    <div className='flex gap-3'>
                                        <div className='text-center '>
                                            <p className="mb-2 text-[#344054] text-[18px] font-medium dark:text-white ">
                                                02:30
                                            </p>
                                            <img src={FlagIndia} className="m-auto" />
                                        </div>
                                        <div className=''>
                                            <img src={RouteImg} className="w-full" />
                                            <p className="text-[#344054] text-[18px] font-medium dark:text-white text-center ">
                                                FCL  <span className="text-[#344054] text-[16px] font-normal dark:text-white ">(Export)</span>
                                            </p>
                                        </div>
                                        <div className='text-center '>
                                            <p className="mb-2 text-[#344054] text-[18px] font-medium dark:text-white ">
                                                02:30
                                            </p>
                                            <img src={FlagshriLanka} className="m-auto" />
                                        </div>

                                    </div>

                                </div>

                                <div className='text-center grow'>

                                    <div className='flex justify-end gap-8'>
                                        <img src={FilterIcon} />
                                        <Link className='py-1.5 px-4 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD]  dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'><i className='mr-1 pi pi-plus-circle relative top-1'></i>
                                            Add Compare
                                        </Link>
                                    </div>
                                </div>

                            </div>

                            <div className='grid flex-row gap-0 mb-10 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1 compare'>
                                <div className='col-span-3 pt-5 text-center'>
                                    <div className='titleimg pb-4 border-b border-[#E4E7EC]'>
                                        <img src={CompareImg} className='h-48 m-auto' />
                                    </div>


                                    <div className='flex-row gap-0 text-right table-custom'>
                                        <div className='py-4 px-4 bg-[#fff] border-b border-[#E4E7EC]'>
                                            <h4>Carrier</h4>
                                        </div>
                                        <div className='py-4 px-4 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                            <h4>Bid Validity</h4>
                                        </div>
                                        <div className='py-4 px-4 bg-[#fff] border-b border-[#E4E7EC]'>


                                            <h4> POL</h4>

                                        </div>
                                        <div className='py-4 px-4 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                            <h4>Transit Days</h4>
                                        </div>
                                        <div className='py-4 px-4 bg-[#fff] border-b border-[#E4E7EC]'>

                                            <h4> Route</h4>

                                        </div>
                                        <div className='py-4 px-4 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                            <h4>Ocean Freight $20 Standard</h4>
                                        </div>
                                        <div className='py-4 px-4 bg-[#fff] border-b border-[#E4E7EC]'>
                                            <h4>B/L Fee </h4>
                                        </div>
                                        <div className='py-4 px-4 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                            <h4>Direct post delivery $20 Standard</h4>
                                        </div>
                                        <div className='py-4 px-4 bg-[#fff] border-b border-[#E4E7EC]'>
                                            <Link onClick={() => setConfirmPopup(1)} className='invisible bg-[#029046] text-white rounded-lg px-4 py-2 lg:py-2 text-[16px] lg:text-md flex justify-center w-full'>Confirm</Link>
                                        </div>
                                    </div>
                                </div>


                                <div className='col-span-9'>
                                    <div className='grid flex-row gap-0 mb-10 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1'>

                                        {
                                            confirmedFreightForwarders.map((item) => {

                                                return (
                                                    <div className='col-span-3 border-r border-[#E4E7EC] mb-5'>
                                                        <div className='relative header'>
                                                            <h2>{item.freightForwarderName}</h2>
                                                            <span className='text-[#029046] py-2 px-3 rounded-b-md bg-[#fff] absolute right-2 top-0 font-semibold'>{item.sr_No}</span>
                                                        </div>
                                                        <div className='px-2 logo-section'>
                                                            <img src={item.freightForwarderLogo} className='m-auto h-14 w-w-56' />
                                                        </div>
                                                        <div className='px-2'>
                                                            <p className="text-[#344054] text-[18px] font-medium dark:text-white mb-3 ">Total Value
                                                                <span className="text-[#344054] text-[18px] font-semibold dark:text-white ml-2">${item.avg}</span></p>
                                                        </div>
                                                        <div className='flex justify-between pb-4 px-2 border-b border-[#E4E7EC]'>
                                                            <Link className="text-[#01813F] text-[15px] font-medium  ">View Details</Link>
                                                            <div className='flex gap-4'>
                                                                <Link
                                                                    onClick={(e) => handleDeleteClick(e, item.InviteBidId)}
                                                                > <i className='pi pi-trash text-[#01813F] text-lg'></i></Link>
                                                                <Link onClick={() => openCommentsPopup(item.bidId)} > <i className='pi pi-comment text-[#01813F] text-lg'></i></Link>
                                                            </div>
                                                        </div>
                                                        <div className='flex-row gap-0 table-custom'>
                                                            <div className='py-4 px-2 bg-[#fff] border-b border-[#E4E7EC]'>
                                                                <h6>Undefined</h6>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                                                <h6>22 Oct 2022</h6>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#fff] border-b border-[#E4E7EC]'>
                                                                <div className='flex gap-2'>
                                                                    <img src={FlagIndia} />
                                                                    <h6> Mundra</h6>
                                                                </div>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                                                <h6>5</h6>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#fff] border-b border-[#E4E7EC]'>

                                                                <h6> Direct </h6>

                                                            </div>
                                                            <div className='py-4 px-2 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                                                <h6>$20/Container x 1 units </h6>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#fff] border-b border-[#E4E7EC]'>
                                                                <h6>$20/Container x 1 units  </h6>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#F9FAFB] border-b border-[#E4E7EC]'>
                                                                <h6>$20/Container x 1 units  </h6>
                                                            </div>
                                                            <div className='py-4 px-2 bg-[#fff] border-b border-[#E4E7EC]'>
                                                                <Link onClick={() => {
                                                                    setConfirmPopup(1)
                                                                    setSelectedFrifreightForwarderId(item.freightForwarderId)
                                                                    setSelectedBidId(item.bidId)
                                                                }} className={'bg-[#029046] text-white rounded-lg px-4 py-2 lg:py-2 text-[16px] lg:text-md flex justify-center w-full  ' + (checkConfirmStatus === true ? 'pointer-events-none opacity-50' : null)}>{item.isConfirmed == true ? 'Confirmed' : 'Confirm'}</Link>
                                                                {/* <h3>{item.isConfirmed === false}hello</h3> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>

                {
                    isViewPopUp === 1 ?
                        <div>
                            <InquiryView
                                closePopUp={() => setViewPopUp(0)}
                            />
                        </div> : null
                }
            </div>

            <div id="selector" className={firstTimeRolePopup === 1 ? 'overlay' : ''}></div>
            {
                firstTimeRolePopup === 1 ?
                    <div>
                        <InquiryRole closeRolePopUp={() => setFirstTimeRolePopup(0)}
                            totalcount={totalRule}
                        />
                    </div> : null

            }

            <div id="selector" className={isInvitePopUp === 1 ? 'overlay' : ''}></div>

            {
                isInvitePopUp === 1 ?
                    <InquiryBidsComponent
                        closePopUp={() => setInvitePopUp(0)}
                    // bindList={() => BindList()}
                    /> : null
            }

            <div id="selector"></div>

            {/* ##################### Add Comment Popup ###################################### */}
            <div id="selector" className={commentsPopup === 1 ? 'overlay' : ''}></div>
            {
                commentsPopup === 1 ?
                    <div><CommentPopup closePopup={() => setCommentsPopup(0)} bidsId={selectedBidId} /></div> : null
            }

            {/* ##################### Confirm Popup ###################################### */}

            <div id="selector" className={confirmPopup === 1 ? 'overlay' : ''}></div>
            {
                confirmPopup === 1 ?
                    <div><ConfirmBid
                        closepopup={() => setConfirmPopup(0)}
                        bidsId={selectedBidId}
                        openPopup={function openSecondPopup() {
                            setConfirmPopup(0)
                            setCustomerQuotationPopup(1)
                        }
                        }
                    />
                    </div> : null

            }

            <div id="selector" className={customerQuotationPopup === 1 ? 'overlay' : ''}></div>
            {
                customerQuotationPopup === 1 ?
                    <div><CustomerQuotationPopup closeQuotationPopup={() => { setCustomerQuotationPopup(0) }}
                        FreightForwardId={selectedFrifreightForwarderId}
                        bidsId={selectedBidId}
                    /></div> : null
            }






        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}