import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
// import React, { useState } from 'react';
// import { Link } from "react-router-dom";

export default function EmployeeDashboardDetailed() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const yearList = [
        {
            'Year': 'FY 2022-23', 'Inquiry_Received': '1,200', 'Inquiry_Dropped': '20', 'Inquiry_Open': '10', 'Quotation_Accepted': '770', 'Quotation_Declined': '200', 'Quotation_Closed': '200', 'Inquiry_Lifecycle': '10', 'Revenue': '500 M',
            'GM': '50 M', 'GM_LP_Var': '1%', 'Conversion_ProConnect': '64%', 'Best_Freight_Forwarder': 'CargoGulf Inc'
        },
        {
            'Year': 'FY 2021-22', 'Inquiry_Received': '1,200', 'Inquiry_Dropped': '20', 'Inquiry_Open': '10', 'Quotation_Accepted': '770', 'Quotation_Declined': '200', 'Quotation_Closed': '200', 'Inquiry_Lifecycle': '10', 'Revenue': '500 M',
            'GM': '50 M', 'GM_LP_Var': '1%', 'Conversion_ProConnect': '64%', 'Best_Freight_Forwarder': 'CargoGulf Inc'
        },
        {
            'Year': 'FY 2020-21', 'Inquiry_Received': '1,200', 'Inquiry_Dropped': '20', 'Inquiry_Open': '10', 'Quotation_Accepted': '770', 'Quotation_Declined': '200', 'Quotation_Closed': '200', 'Inquiry_Lifecycle': '10', 'Revenue': '500 M',
            'GM': '50 M', 'GM_LP_Var': '1%', 'Conversion_ProConnect': '64%', 'Best_Freight_Forwarder': 'CargoGulf Inc'
        },
    ];


    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className='pl-16'>
                <div className="dashboard-main-wrapper dashboardproconnect">
                    <div className="p-5 pb-0">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/dashboard">Summary</Link></li>
                                    <li><Link to="/employee/dashboard/detailed" className="active">Detailed</Link></li>
                                    <li><Link to="/employee/dashboard/drilldown">Drilldown</Link></li>
                                    <li><Link to="/employee/dashboard/geospatial">GeoSpatial</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:flex justify-between my-6 pl-5">
                    <div className="tabs_two">
                        <ul className="flex">
                            <li><Link to={""} className="active">Year</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/month"} className="">Month</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/services"} className="">Services</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/customers"} className="">Customers</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/freightforwarders"} className="">Freight Forwarders</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/salesexecutive"} className="">Sales Executive</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/tradeline"} className="">Trade Line</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/margins"} className="">Margins</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/cargotype"} className="">Cargo Type</Link></li>

                        </ul>
                    </div>
                </div>

                <div className="m-5 bg-white rounded-lg mt-2 pt-5 px-1 dark:bg-[#2A2E32] mt-5  border border-[#E4E7EC] dark:border-[#344054] dark:text-white  mb-10 pb-10 ">

                    <div className='flex gap-4 justify-between items=center mr-2 px-4'>
                        <div className='text-lg font-normal dark:text-white'>Year</div>
                        <div className='flex gap-4 flex-right'>
                            <Link to='' className='flex justify-end w-20 flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-print'></i> Print</Link>
                            <Link to='' className='flex justify-end w-34  flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-download'></i> Download</Link>
                        </div>
                    </div>
                    <div>
                        <div className=" rounded-lg mt-5">
                            <DataTable paginator value={yearList} responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="Year" header="Year" filter></Column>
                                <Column field="Inquiry_Received" header="Inquiry - Received" sortable={true}></Column>
                                <Column field="Inquiry_Dropped" header="Inquiry - Dropped" sortable={true}></Column>
                                <Column field="Inquiry_Open" header="Inquiry-Open" sortable={true}></Column>
                                <Column field="Quotation_Accepted" header="Quotation- Accepted" sortable={true}></Column>
                                <Column field="Quotation_Declined" header="Quotation- Declined" sortable={true}></Column>
                                <Column field="Quotation_Closed" header="Quotation- Closed" sortable={true}></Column>
                                <Column field='Inquiry_Lifecycle' header="Avg. Inquiry Lifecycle (days)" sortable={true}></Column>
                                <Column field='Revenue' header="Revenue($)" sortable={true}></Column>
                                <Column field='GM' header="GM($)" sortable={true}></Column>
                                <Column field='GM_LP_Var' header="GM - LP Var" sortable={true}></Column>
                                <Column field='Conversion_ProConnect' header="% Conversion-ProConnect" sortable={true}></Column>
                                <Column field='Best_Freight_Forwarder' header="Best Freight Forwarder" filter></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}