import { useNavigate, useLocation } from 'react-router-dom'
import React from 'react';
import redingToneImg from '../../../assets/svg/proconnect_word.png'
import { reactLocalStorage } from 'reactjs-localstorage';

export default function LeftSideBar() {
    const navigateTo = useNavigate()

    //Active Class Name
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    async function logOut() {
        reactLocalStorage.clear();
        navigateTo('/employee');
        navigateTo(0);
    }

    return (
        // < !--Sidebar -- >
        <div>
            <div className="area"></div>

            <div className="flex flex-shrink-0 transition-all fixed left-wrapper z-10" id="main_menu ">
                {/* <!-- Left mini bar --> */}
                <nav aria-label="Options" className="z-20  py-0 h-screen top-0 h-screen bg-[#fff] dark:bg-[#2D2B29] sm:flex" id="menu_nav">
                    <div className="main-menu shadow-lg">
                        {/* <!-- Menu button --> */}
                        <ul>
                            <li className="mb-3"><button
                                type="button"
                                onClick={() => navigateTo('/employee/landing')}
                                className="focus:outline-none  menu">
                                <i className="icon home_icon "></i>
                                <span className="nav-text">
                                    Home
                                </span>
                            </button>
                            </li>

                            <li className={splitLocation[2] === "dashboard" ? "mb-3 active" : "mb-3"}><button
                                type="button"
                                onClick={() => navigateTo('/employee/dashboard')}
                                className="focus:outline-none menu">
                                <i className="icon dashboard_icon "></i>
                                <span className="nav-text">
                                    Dashboard
                                </span>
                            </button>
                            </li>

                            

                           
                            <li className={splitLocation[2] === "inquiries" ? "mb-3 active" : "mb-3"}><button
                                type="button"
                                onClick={() => navigateTo('/employee/inquiries')}
                                className="focus:outline-none menu">
                                <i className="icon task_square "></i>
                                <span className="nav-text">
                                    Inquiry
                                </span>
                            </button>
                            </li>
                            
                            <li className={splitLocation[2] === "messaging" ? "mb-3 active" : "mb-3"}><button
                                type="button" onClick={() => navigateTo('/employee/messaging')}
                                className="focus:outline-none menu">
                                <i className="icon comment_icon"></i>
                                <span className="nav-text">
                                    Messaging
                                </span>
                            </button>
                            </li>

                            <li className={splitLocation[2] === "mailBox" ? "mb-3 active" : "mb-3"}><button
                                type="button" onClick={() => navigateTo('/employee/mailBox')}
                                className="focus:outline-none menu">
                                <i className="icon mailboxleft_icon"></i>
                                <span className="nav-text">
                                    Mailbox
                                </span>
                            </button>
                            </li>

                            <li className={splitLocation[2] === "reports" ? "mb-3 active" : "mb-3"}><button
                                type="button"
                                onClick={() => navigateTo('/employee/report')}
                                className="focus:outline-none menu">
                                <i className="icon reports_icon"></i>
                                <span className="nav-text">
                                    Reports
                                </span>
                            </button>
                            </li>

                        </ul>
                        <ul className="logout">
                            <li className={splitLocation[2] === "settings" ? "mb-3 active" : "mb-3"}>
                                <button onClick={() => {
                                    localStorage.setItem('selectedTab', 1)
                                    navigateTo('/employee/settings')
                                }} className="menu">
                                    <i className="icon support_icon"></i>
                                    <span className="nav-text">
                                        Settings
                                    </span>
                                </button>
                            </li>
                            <li className="mb-3">
                                <button
                                    onClick={logOut}
                                    className="menu">
                                    <i className="icon logout_icon"></i>
                                    <span className="nav-text">
                                        Logout
                                    </span>
                                </button>
                            </li>
                            <li>
                                <div className="menu">
                                    <i className="icon small_logo"></i>
                                    <span className="nav-text footerlogo">
                                        <img className="" src={redingToneImg} alt="" />
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
               
            </div>
        </div>
    )
}