import { DataStore } from '@aws-amplify/datastore';
import { FreightForwarder, InquiryBid, BidStatus, InquiryServices, Inquiry, InquiryContainers, Country, ContainerType, InquiryCargoType } from '../../../models';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import "../../../proConnect.css";
import Bussiness from "../../../assets/svg/connectpro/business-people-casual-meeting 1.png"
import { Menu } from "@headlessui/react";
import cargo_icon from "../../../assets/svg/connectpro/cragodetail_icon.svg"
import attach_icon from "../../../assets/svg/connectpro/attach_icon.svg"
import { toast } from 'react-toastify';
import UserImg from "../../../assets/images/user5.png";
import FreightForwardIcon from "../../../assets/svg/connectpro/freightforwordicon.svg";


export const CommentPopup = (props) => {

       // Comment popup
       const [commentsReason, setCommentsReason] = useState("");
       const [commentsNotes, setCommentsNotes] = useState("");


       const { inquiryId } = useParams();


    const addComment = async () => {
        const original = await DataStore.query(InquiryBid, props.bidsId);

        await DataStore.save(
            InquiryBid.copyOf(original, (updated) => {
                updated.commentReason = commentsReason;
                updated.commentNotes = commentsNotes;
            })
        );

        props.closePopup()
    }

    async function bindData() {
        //bid invite count
        var bidDetails = await DataStore.query(InquiryBid, props.bidsId);
        // console.log(bidDetails.commentReason);
        setCommentsReason(bidDetails.commentReason);
        setCommentsNotes(bidDetails.commentNotes);
    }
    
    useEffect(() => {
        bindData();
    }, []);

  return (
    <div>  <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
    <div >
        <img src={Bussiness} alt="" style={{ width: "50vw" }} />
        <div className="absolute right-0 left-0 top-0 w-full text-white">
            <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                <div className="text-md"><h6 className='text-[white]'>Bids</h6></div>
                <div><Link onClick={() => props.closePopup()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
            </div>

            <div className="px-6 mt-8">
                <span>
                    <Menu as="div" className="relative inline-block text-left">
                        <div className="flex items-center align-center">
                            <Menu.Button className='flex items-center'>
                            </Menu.Button>
                        </div>
                    </Menu>
                    <div className="text-sm text-[white]">Add</div>
                    <div className="text-2xl">Comments
                    </div>
                </span>
            </div>

        </div>

        <div className="py-2 px-5  height-custtom">
            <div className=' p-5 '>
                <div >
                    <label>Reason</label>
                    <br />
                    <input className='text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]' type="text" onChange={(e) => setCommentsReason(e.target.value)} value={commentsReason} />
                </div>
                <div className='  w-full  '>
                    <div className=' w-full'>
                        <h3 className="mt-3 mb-2">Description</h3>
                        <textarea cols="76" row="30" className="h-40 text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] " onChange={(e) => setCommentsNotes(e.target.value)} value={commentsNotes} ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
            <div className="flex justify-end gap-3">
                <div><Link onClick={() => props.closePopup()} className="bg-white 
 dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                >Cancel</Link></div>
                <div><Link onClick={() => addComment()}
                    className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Add Comment</Link>
                </div>
            </div>
        </div>

    </div>

</div></div>
  )
}
