import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { Link, useNavigate } from 'react-router-dom';
import { DataTableCustomStyles } from '../../../../../components/helper/dataTable.config';
import { Employee, Roles } from "../../../../../models";
import EmpLogin from "../../../../../setup/auth/emp.component";
import { DataStore, Predicates  } from '@aws-amplify/datastore';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { toast } from "react-toastify";
import { ObjectToArray } from "../../../../../components/helper/commonfunctions";
import EditButton from '../../../../../components/common/buttons/EditButton';
import DeleteButton from '../../../../../components/common/buttons/DeleteButton';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { Avatar } from "@mui/material";
import { GetAWSObjectURL } from '../../../../../components/helper/aws';


export default function Employees() {

    let navigateTo = useNavigate();

    //*Employees Table Columns Name
    const parentDataTableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            cell: (row) => (
                <>
                    <div className="flex gap-4 items-center">
                        <Avatar
                            alt={row.firstName + "" + row.lastName}
                            src={row.profilePic} />
                        {row.firstName + " " + row.lastName}
                    </div>
                </>
            ),
            width: "200px",
        },
        {
            name: "Role",
            selector: row => row.role,
            sortable: true,
        },
        {
            name: "Employee Id",
            selector: row => row.employeeId,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Parent Employee',
            selector: row => row.parentemployeename,
            sortable: true,
        },
        {
            name: 'Address 1',
            selector: row => row.address1,
            sortable: true,
        },

        {
            name: 'Address 2',
            selector: row => row.address2,
            sortable: true,
        },
        {
            name: "Phone No",
            selector: row => row.phoneNo,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },

        {
            name: 'Zip Code',
            selector: row => row.zip,
            sortable: true,
        },

        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
        },

        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <div className="flex gap-8">
                        <Link onClick={(e) => handleEditClick(e, row['id'])} ><EditButton /></Link>
                        <Link onClick={(e) => handleDeleteClick(e, row['id'])} ><DeleteButton /></Link>
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            center: true,
            button: true,
        }
    ];

    //Validate admin Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    //State
    const [employeeList, SetEmployeeList] = useState([]);

    const [employeeFilterList] = useState([]);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //Edit Parent
    const handleEditClick = (e, id) => {
        e.preventDefault();
        navigateTo('/employee/settings/createemployee/' + id);
    };

    //Delete Employees
    const handleDeleteClick = async (e, id) => {
        e.preventDefault();
        const objDeleteEmployee = await DataStore.query(Employee, id);
        alertify.confirm('Confirmation', 'Do you want to delete this record?', function () {
            //Delete Employees
            DataStore.delete(objDeleteEmployee);
            toast.error("Deleted");
            BindList();
        }
            , function () { });
    };

    //Get Employees List
    async function BindList() {

        var employeeResponses = await DataStore.query(Employee, Predicates.ALL, {
            // sort : s =>  s.firstName(SortDirection.ASCENDING)
        });

        if (employeeResponses.length > 0) {
            var newResponse = [];
            Object.keys(employeeResponses).map(async (currentItem, i) => {
                let loopEmployeeItem = employeeResponses[currentItem];

                let data = ObjectToArray(loopEmployeeItem);

                //Role List
                var roleResponses = await DataStore.query(Roles, employeeResponses[i].role_id)

                console.log("roles" + JSON.stringify(roleResponses));
                if (roleResponses) {
                    data['role'] = roleResponses.name
                }
                else {
                    data['role'] = ''
                }
                if (loopEmployeeItem.profilePic !== null) {
                    let getprofilePicPath = await GetAWSObjectURL(loopEmployeeItem.profilePic);
                    data["profilePic"] = getprofilePicPath
                }

                newResponse.push(data)

                //On Last Record assign to state
                if (i === employeeResponses.length - 1) {
                    //let sortArray = newResponse.sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
                    SetEmployeeList(newResponse);



                }
            })
        } else {
            SetEmployeeList([]);
        }
    }

    const searchEmployees = (searchedText) => {
        if (searchedText.length > 1) {
            let data = [...employeeFilterList]
            let filteredData = data.filter(e =>
                e.firstName.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.lastName.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.email.toLowerCase().includes(searchedText.toLowerCase()) ||
                e.employeeId.toLowerCase().includes(searchedText.toLowerCase())
            )
            SetEmployeeList(filteredData)
        } else {
            let data = [...employeeFilterList]
            SetEmployeeList(data)
        }
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);


    return isAuthenticated ? (
        <div>
            <div className="pt-16">
                <TopNavBar />
                <LeftSideBar />
                <div className="dashboard-main-wrapper pl-20 pr-4 mt-3">
                    <div className="flex flex-1 flex-wrap p-5 pb-4 px-0 top-action-wrapper">
                        <div className="md:flex xs:w-full sm:w-full md:w-auto flex-row align-center">
                            <div className="md:flex block md:col-span-2 mb-4">
                            </div>
                            <div className="md:flex block mb-4">
                                <h2 className="page-title">Employees</h2>
                            </div>
                        </div>

                        <div className="md:flex flex-1 flex-row xs:w-full sm:w-full md:w-auto align-center justify-end md:ml-auto gap-3 right-side">
                            <div className="relative">
                                <input onChange={(e) => searchEmployees(e.target.value)} type="text" id="required-search" className=" rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search..." />
                                <button type="submit" className="absolute left-3 top-5 translate-y-[-50%] dark ">
                                    <i className="icon search_icon "></i>
                                </button>
                            </div>

                            <div className="md:flex block  gap-3 mb-3">
                                <Link to="/employee/settings" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Back</Link>
                                <Link to="/employee/settings/createemployee" className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">Create</Link>
                            </div>

                        </div>
                    </div>
                    <div>
                        <div className={"block w-full pt-5"}>
                            {
                                <DataTable
                                    columns={parentDataTableColumns}
                                    data={employeeList}
                                    customStyles={DataTableCustomStyles}
                                    pagination
                                    highlightOnHover
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    )
}
