import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { ValidateEmployeeRole } from "../../../components/helper/validateRole";
import EmpLogin from "../../../setup/auth/emp.component";
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Inquiry, InquiryBidRule, FreightForwarderQuestion, Customer, InquiryCargoType, Country } from '../../../models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import moment from 'moment';
import Bussiness from "../../../assets/svg/connectpro/business-people-casual-meeting 1.png"
import RolePopupImg from "../../../assets/images/RolePopup.png";


export default function InquiryRole(props) {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [allFreightForwarderQuestions, setAllFreightForwarderQuestions] = useState([]);
    const [freightForwarderQuestion, setFreightForwarderQuestion] = useState([]);
    const [inputWeightage, setInputWeightage] = useState("");
    const { inquiryId } = useParams();
    const [secoundTimeRolePopup, setSecoundTimeRolePopup] = useState("");

    

    const updateInputWeightage = async (index, value) => {
        var oldArray = [...freightForwarderQuestion]
        oldArray[index]['weights'] = value;
        setFreightForwarderQuestion(oldArray)
    }

    const saveData = async () => {
        var deleatedata = await DataStore.delete(InquiryBidRule, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
        ]));


        for (var i = 0; i < freightForwarderQuestion.length; i++) {
            if (freightForwarderQuestion[i].weights != "") {
                DataStore.save(
                    new InquiryBidRule({
                        "inquiryId": inquiryId,
                        "freightForwarderQuestionId": freightForwarderQuestion[i].questionId.toString(),
                        "weights": freightForwarderQuestion[i].weights.toString(),
                    })
                )
            }

        }
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    //BindList
    async function BindList() {

        // Start
        var freightForwarderQuestions = await DataStore.query(FreightForwarderQuestion,Predicates.ALL,{
            sort: s => s.question(SortDirection.ASCENDING)
        });
        var inquiryBitQuestionArray = [];

        for (var i = 0; i < freightForwarderQuestions.length; i++) {
            var enumData = freightForwarderQuestions[i]
            //  console.log(enumData.id)

            var inquiryDeclinedCount = await DataStore.query(InquiryBidRule, (c) => c.and(c => [
                c.inquiryId.eq(inquiryId),
                c.freightForwarderQuestionId.eq(enumData.id)
            ]));

            var weightage = inquiryDeclinedCount.length ? inquiryDeclinedCount[0].weights : '';
            // console.log(weightage);

            let data = {
                "questionId": [enumData.id],
                "question": [enumData.question],
                "weights": [weightage],
                "index": [i],
            }

            console.log("Data===>" + data);
            inquiryBitQuestionArray.push(data);
        }
        setFreightForwarderQuestion(inquiryBitQuestionArray)


        //get freight forwarder data
        setAllFreightForwarderQuestions(await DataStore.query(FreightForwarderQuestion));
    }

    useEffect(() => {
        onLoad();
        BindList()

    }, []);

    return isAuthenticated ? (

        <div className="justify-end">
            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                <div>
                    {
                        secoundTimeRolePopup != 1 ?
                            props.totalcount == 0 ?
                                <>
                                    <div className="popup_display_img"><img src={RolePopupImg} className="mx-auto mt-8" alt="" /></div>
                                    <div className=" text-center text-[20px] text-[#101828] dark:text-white">There are no set rules, would you like to set a new value?</div>
                                    <div className="px-10 2xl:px-14">
                                        <div className="block text-center my-6">
                                            <Link onClick={() => setSecoundTimeRolePopup(1)} className="bg-[#029046] text-white rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass">Yes</Link>
                                            <Link onClick={() => props.closeRolePopUp()} className='bg-[#FFFFFF] text-[#344054] ml-3  rounded-md px-6 py-2 text-sm shadow-md inline-block removeClass' >No </Link>
                                        </div>
                                    </div>
                                </> : <div>
                                    <img src={Bussiness} alt="" style={{ width: "50vw" }} />
                                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                            <div className="text-md"><h6 className='text-[white]'>Bids</h6></div>
                                            <div><Link onClick={() => props.closeRolePopUp()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                        </div>

                                        <div className="px-6 mt-8">
                                            <span>
                                                <div className="text-sm text-[white]">New</div>
                                                <div className="text-2xl">Ranking Rule
                                                </div>
                                            </span>
                                        </div>

                                    </div>

                                    <div className="py-2 px-5  height-custom">

                                        <div className='border shadow-lg rounded-lg '>
                                            <div>
                                                <h2 className='p-3 text-[#101828] text-[18px] font-medium'>Ranking Rule</h2>
                                            </div>
                                            <div className='  w-full '>
                                                <div className=" border w-full flex-col">

                                                    <div className="py-2 inline-block w-full custom-striped">
                                                        <div>
                                                            <table className="w-full text-[#344054]">
                                                                <thead className="">
                                                                    <tr>
                                                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                            Sr.No.
                                                                        </th>
                                                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                            Parameter
                                                                        </th>
                                                                        <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                            Weightage
                                                                        </th>

                                                                    </tr>
                                                                </thead>

                                                                {
                                                                   freightForwarderQuestion.map((value) => {
                                                                        return (
                                                                            <tbody>
                                                                                <tr className="">
                                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{parseInt(value.index) + 1}</td>
                                                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                                        {value.question}
                                                                                    </td>

                                                                                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                                        <input defaultValue={inputWeightage === "" ? value.weights : inputWeightage}
                                                                                            onChange={(e) => updateInputWeightage(value.index, e.target.value)} className='border p-2 rounded-md w-full' placeholder='Type Here' />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )
                                                                    })
                                                                }
                                                            </table>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                        <div className="flex justify-end gap-3">
                                            <div><Link onClick={() => props.closeRolePopUp()} className="bg-white 
                     dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2  dark:bg-[#4A5056]"
                                            >Cancel</Link></div>
                                            <div><Link onClick={() => {
                                                saveData()
                                                props.closeRolePopUp()
                                            }}
                                                className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Save</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            :
                            <div>
                                <img src={Bussiness} alt="" style={{ width: "50vw" }} />
                                <div className="absolute right-0 left-0 top-0 w-full text-white">
                                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                        <div className="text-md"><h6 className='text-[white]'>Bids</h6></div>
                                        <div><Link onClick={() => props.closeRolePopUp()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                    </div>

                                    <div className="px-6 mt-8">
                                        <span>
                                            <div className="text-sm text-[white]">New</div>
                                            <div className="text-2xl">Ranking Rule
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className="py-2 px-5  height-custom">
                                    <div className='border shadow-lg rounded-lg '>
                                        <div>
                                            <h2 className='p-3 text-[#101828] text-[18px] font-medium'>Ranking Rule</h2>
                                        </div>
                                        <div className='  w-full '>
                                            <div className=" border w-full flex-col">

                                                <div className="py-2 inline-block w-full custom-striped">
                                                    <div>
                                                        <table className="w-full text-[#344054]">
                                                            <thead className="">
                                                                <tr>
                                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                        Sr.No.
                                                                    </th>
                                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                        Parameter
                                                                    </th>
                                                                    <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                                        Weightage
                                                                    </th>

                                                                </tr>
                                                            </thead>

                                                            {
                                                                freightForwarderQuestion.map((value) => {
                                                                    return (
                                                                        <tbody>
                                                                            <tr className="">
                                                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center">{parseInt(value.index) + 1}</td>
                                                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                                    {value.question}
                                                                                </td>

                                                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                                    <input defaultValue={inputWeightage === "" ? value.weights : inputWeightage}
                                                                                        onChange={(e) => updateInputWeightage(value.index, e.target.value)} className='border p-2 rounded-md w-full' placeholder='Type Here' />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                    <div className="flex justify-end gap-3">
                                        <div><Link onClick={() => props.closeRolePopUp()} className="bg-white 
                     dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2  dark:bg-[#4A5056]"
                                        >Cancel</Link></div>
                                        <div><Link onClick={() => {
                                            saveData()
                                            props.closeRolePopUp()
                                        }}
                                            className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Save</Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                    }
                </div>
            </div>
            <div id="selector"></div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}