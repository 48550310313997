import { React, useEffect, useState } from 'react';
import EmpLogin from "../../setup/auth/emp.component";
import logoLanding from '../../assets/images/pro_connect_logo_red.png';
import { Link, useParams } from "react-router-dom";
import { InquiryBid, CustomerBidStatus, Inquiry, FreightForwarderQuestion, InquiryBidRequestedDetails, InquiryContainers, ContainerType, InquiryBidContainerDetails, Units } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { id } from 'date-fns/locale';
import { INQUIRY_INFORMATION_FIELD_NAMES } from "../../components/helper/enum";
import { toast } from "react-toastify";
import { width } from '@mui/system';
import moment from 'moment';
// import ComputerImg from "../../assets/images/computerimage.png";
import * as alertify from 'alertifyjs';
import AWS from 'aws-sdk'
import { ProgressSpinner } from 'primereact/progressspinner';




export default function InquiryConfirmAmount() {

    const { bidId } = useParams();
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [bidStatus, setBidStatus] = useState("");
    const [emailDescription, setEmailDescription] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [inquiryNumber, setInquiryNumber] = useState("");
    const [inquiryClosingDate, setInquiryClosingDate] = useState("");
    const [inquiryContainer, setInquiryContainer] = useState([]);
    const [inquiryBidDetail, setInquiryBidDetail] = useState([]);
    const [inquiryBidStatus, setInquiryBidStatus] = useState("INVITED");
    const [submitDetail, setSubmitDetail] = useState(0)
    const [inquiryId, setInquiryId] = useState("")
    const [customerStatus, setCustomerStatus] = useState(null);
    const [updateCustomerStatus, setUpdateCustomerStatus] = useState("");
    const [dateOfEnquiry, setDateOfEnquiry] = useState("");
    const [isPageLoaded, setPageLoaded] = useState(false);




    // Submit bid
    const submitBid = async () => {

        alertify.confirm('Confirmation', 'Do you want to Submit this Bid?', async function () {

            const original = await DataStore.query(InquiryBid, bidId);

            if (updateCustomerStatus === "") {
                toast.error("Please Select Status");
                return;
            }

            await DataStore.save(
                InquiryBid.copyOf(original, (updated) => {
                    updated.customerStatus = updateCustomerStatus;
                    updated.customerStatusUpdatedOn = dateOfEnquiry;
                })
            );
            toast.success("Status Submitted")
            setSubmitDetail(1)
        }
            , function () { });

    };
    console.log(submitBid)

    const BindList = async () => {

        var currentDate = moment()
        setDateOfEnquiry(moment(currentDate).format("YYYY-MM-DD"))

        const InquiryBidDetails = await DataStore.query(InquiryBid, bidId);
        setInquiryBidDetail(InquiryBidDetails);
        setInquiryBidStatus(InquiryBidDetails.bidStatus);
        setInquiryId(InquiryBidDetails.inquiryId);
        setCustomerStatus(InquiryBidDetails.customerStatus);


        const InquiryDetails = await DataStore.query(Inquiry, InquiryBidDetails.inquiryId);

        setInquiryNumber(InquiryDetails.inquiryNo);
        setInquiryClosingDate(InquiryDetails.inquiryClosingDate);

        const InquiryId = InquiryBidDetails.inquiryId;

        const InquiryBidContainerDetail = await DataStore.query(InquiryBidContainerDetails,
            (c) => c.inquiryBid.eq(InquiryBidDetails.id));

        // console.log("hich",InquiryBidContainerDetail);
        const InquiryContainerArray = []



        const InquiryContainersDetail = await DataStore.query(InquiryContainers,
            (c) => c.inquiryId.eq(InquiryId));

        if (InquiryContainersDetail.length > 0) {
            for (var i = 0; i < InquiryContainersDetail.length; i++) {
                const containerTypeDetail = await DataStore.query(ContainerType, InquiryContainersDetail[i].containerTypeId);
                const unitDetail = await DataStore.query(Units, InquiryContainersDetail[i].unitI);
                var inquirySignDetails = await DataStore.query(Units, InquiryContainersDetail[i].dimentionId)

                {
                    var data = [];
                    data = {
                        "count": InquiryContainersDetail[i].count,
                        "containerId": InquiryContainersDetail[i].id,
                        "containerTypeId": containerTypeDetail.name,
                        "size": (InquiryContainersDetail[i].length) ? InquiryContainersDetail[i].length + '*' + InquiryContainersDetail[i].width + '*' + InquiryContainersDetail[i].height + " " + inquirySignDetails.sign : '-',
                        "totalWeight": InquiryContainersDetail[i].totalWeight,
                        "unit_name": unitDetail.name,
                        "unit_sign": unitDetail.sign,
                        "amount": InquiryBidContainerDetail[i].amount,
                        "index": i
                    }
                    InquiryContainerArray.push(data);
                }
                setInquiryContainer(InquiryContainerArray)
                setPageLoaded(true)


            }
        }


    }

    const handleChange = (event) => {
        setUpdateCustomerStatus(event.target.value)

    }

    useEffect(() => {
        BindList();
    }, [inquiryId]);

    return (

        <div className="pt-12">

            <div className='flex  justify-center mb-4'>
                <img className="w-48" src={logoLanding} alt="" />
            </div>
            {

                isPageLoaded === true ?
                    <div>
                        {
                            submitDetail == 1 ?
                                <div className='flex justify-center mb-4 '>
                                    <div className='border flex    justify-center mb-4 items-center shadow shadow-lg' style={{ height: "50vh", width: "70vw" }}>

                                        <div className=' h-full items-center flex justify-center '>
                                            <p className='text-5xl '> Bid Submit Succesfully.</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    {

                                        customerStatus != null ?

                                            <div className='flex justify-center mb-4 '>
                                                <div className='border flex    justify-center mb-4 items-center shadow shadow-lg' style={{ height: "50vh", width: "70vw" }}>

                                                    <div className=' h-full items-center flex justify-center '>
                                                        <p className='text-5xl '> You have already submitted Bid details.  </p>
                                                    </div>
                                                </div>
                                            </div>

                                            :



                                            <div
                                                className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                                                <div className="col-span-4 col-start-4 lg:col-start-4 xl:col-start-4 2xl:col-start-3">
                                                    <div className="px-5 py-5 bg-white rounded-lg shadow-lg dark:bg-[#252423]">
                                                        <div className='flex justify-end'>

                                                        </div>
                                                        <div className='grid grid-cols-2 gap-5 w-full  '>
                                                            <div className='flex justify-between col-span-2'>
                                                                <div className='block gap-3'>
                                                                    <label className='font-light mb-1 text-[15px] text-[#667085]'>Inquiry Number</label>
                                                                    <h4 className='font-medium mb-1 text-[16px] text-[#101828]'>{inquiryNumber}</h4>
                                                                </div>
                                                                <div className='block  gap-3'>
                                                                    <label className='font-light mb-1 text-[15px] text-[#667085]'>Closing Date</label>
                                                                    <h4 className='font-medium mb-1 text-[16px] text-[#101828]'>{moment(inquiryClosingDate).format('MM/DD/YYYY')}</h4>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        {/* <div className='border-b text-[18px] text-[#344054] font-semibold pb-2 mb-5 mt-5'>Inquiry Info</div> */}

                                                        <div className='mt-5'>
                                                            <div className='border px-3 py-2 shadow-md  rounded-t-lg'>
                                                                <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>
                                                                    Container Details</h3>
                                                            </div>
                                                            <div className='border shadow-md  '>
                                                                <div className='p-3 bg-[#fff] dark:bg-[#252423]  dark:border-[#252423]  flex gap-3'>
                                                                    <div className='w-40'>
                                                                        <div className='justify-start flex'>Count</div>
                                                                    </div>
                                                                    <div className='w-40'>
                                                                        <label className='justify-start flex ' >Type</label>
                                                                    </div>
                                                                    <div className='w-40'>
                                                                        <label className='justify-start flex'>Size</label>
                                                                    </div>
                                                                    <div className='w-40'>
                                                                        <label className='justify-center flex'>Weight</label>
                                                                    </div>
                                                                    <div className='w-40'>
                                                                        <label className='justify-center flex'>Amount</label>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    inquiryContainer.map((elm, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex  gap-3 items-center'>
                                                                                    <div className='w-40 text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white justify-start'>{elm.count}</div>
                                                                                    <div className='w-40'>
                                                                                        <label className="text-[#344054] justify-start dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">{elm.containerTypeId}</label>
                                                                                    </div>
                                                                                    <div className='w-60'>
                                                                                        <label className="text-[#344054] justify-center dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">{elm.size}</label>
                                                                                    </div>
                                                                                    <div className='w-52'>
                                                                                        <label className="text-[#344054] justify-center dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">{elm.totalWeight} {elm.unit_sign}</label>
                                                                                    </div>
                                                                                    <div className='w-52'>
                                                                                        <label className="text-[#344054] justify-center dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">{elm.amount}</label>
                                                                                    </div>

                                                                                </div>


                                                                            </div>


                                                                        )
                                                                    })

                                                                }

                                                            </div>
                                                        </div>

                                                        <div className='mt-5'>
                                                            <div className='border px-3 py-2 shadow-md  rounded-t-lg'>
                                                                <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>
                                                                    Remark</h3>
                                                            </div>
                                                            <div className='border shadow-md  '>
                                                                <div className='p-3 bg-[#fff] dark:bg-[#252423]  dark:border-[#252423]   gap-3'>

                                                                    <div className='flex justify-end items-center  gap-3'>
                                                                        <div className='w-full'>
                                                                            <textarea name="postContent" placeholder='Write Your Remark' rows={5} cols={60} className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-1.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] " />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className='mt-5'>

                                                            <div className='border shadow-md  '>
                                                                <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  w-full dark:border-[#252423]  '>


                                                                    <div className='flex gap-3'>
                                                                        <div className=' flex  items-center '>
                                                                            <label>
                                                                                <input type="radio" name='radio' value={CustomerBidStatus.ACCEPTED} onChange={handleChange} />
                                                                                <span className='ml-2'>Accept</span>
                                                                            </label>

                                                                        </div>
                                                                        <div className=' flex  items-center '  >
                                                                            <label>
                                                                                <input type="radio" name='radio' value={CustomerBidStatus.REJECTED} onChange={handleChange} />
                                                                                <span className='ml-2'>Reject</span>
                                                                            </label>
                                                                        </div>
                                                                        <div className=' flex items-center '  >
                                                                            <label>
                                                                                <input type="radio" name='radio' value={CustomerBidStatus.REQUESTFORREVISION} onChange={handleChange} />
                                                                                <span className='ml-2'>Request for revision</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div className='mt-5 text-center'>
                                                            <Link onClick={() => submitBid()} className="bg-[#029046] text-white rounded-lg px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block">Submit</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                        }
                    </div> : <div className='text-center py-8' ><ProgressSpinner /></div>



            }







        </div>
    )

}