export const USER_TYPES = {
    HR: "H",
    Employee: "E",
}

export const USER_TYPES_NAMES = {
    H: "HR",
    E: "Employee",
}

export const PARAMETERS_TYPES = {
    Days: "D",
    Percentage : "P",
    Amount : "A",
    Number : "N",
}

export const PARAMETERS_TYPES_NAMES = {
    D: "Days",
    P : "%",
    A : "$",
    N : "Number",
}

export const INQUIRY_INFORMATION_FIELDS = {
    Service: "S",
    Origin : "O",
    Destination : "D",
    Cargo : "C",
    Movement_Type : "M",
    Product : "P",
    Container_Details : "CD",
    Other_Information : "OI",
}

export const INQUIRY_INFORMATION_FIELD_NAMES = {
    S: "Service",
    O : "Origin",
    D : "Destination",
    C : "Cargo",
    M : "Movement Type",
    P : "Product",
    CD : "Container Details",
    OI : "Other Information",
}

export const INQUIRY_REQUESTED_INFORMATION_FIELDS = {
    Carrier: "C",
    Port_of_origin: "P",
    Destination: "D",
    Twenty_FT: "T",
    Fourty_FT: "F",
    TT: "TT",
    Free_Time: "FT",
}

export const INQUIRY_REQUESTED_INFORMATION_FIELD_NAMES = {
    C: "Carrier",
    P: "Port of Origin",
    D: "Destination",
    T: "20 FT",
    F: "40 FT",
    TT: "T/T",
    FT: "Free Time",
}

export const Google_API_KEY = 'AIzaSyDmHcZjsvYmfI5cJ6ucGsdKx1NmLu2DcJQ'
