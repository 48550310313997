import React, { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Link, useParams } from "react-router-dom";
import BasicInfoBackGroung from "../../../assets/images/basicInfobg.png"
import DestinationImg from "../../../assets/images/destination__img.png"
import { ValidateEmployeeRole } from "../../../components/helper/validateRole";
import EmpLogin from "../../../setup/auth/emp.component";
import moment from 'moment';
import { InquiryServices, Inquiry, InquiryContainers, ContainerType, InquiryCargoType, Units, Products, InquiryPort } from '../../../models';
import { GetAWSObjectURL } from '../../../components/helper/aws';
import { toast } from 'react-toastify';


export default function InquiryView(props) {


    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [inquiryOriginPort, setInquiryOriginPort] = useState('');
    const { inquiryId } = useParams();
    const [inquiryDestinationPort, setInquiryDestinationPort] = useState('');
    const [inquiryCargoType, setInquiryCargoType] = useState('');
    const [inquiryCargoProduct, setCargoProductDetails] = useState('');
    const [inquirySailingRange, setInquirySailingRange] = useState('');
    const [inquiryContractDuration, setInquiryContractDuration] = useState('');
    const [inquiryContainerDetails, setInquiryContainerDetails] = useState([]);
    const [inquiryTermsAndConditions, setInquiryTermsAndConditions] = useState('');
    const [inquiryClosingDate, setInquiryClosingDate] = useState('');
    const [inquiryNumber, setInquiryNumber] = useState('');
    const [inquiryServiceData, setInquiryServiceData] = useState([]);
    const [inquiryProductDetails, setInquiryProductDetails] = useState('');
    const [internationalRef, setInternationalRef] = useState('');
    const [cargoReadyDate, setcargoReadyDate] = useState('');


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {
       
        var inquiryDetails = await DataStore.query(Inquiry, inquiryId);
        var portOriginPortDetails = await DataStore.query(InquiryPort, inquiryDetails.inquiryOriginPort)
        if (portOriginPortDetails)
            setInquiryOriginPort(portOriginPortDetails.name)

        var portDestinationPortDetails = await DataStore.query(InquiryPort, inquiryDetails.inquiryDestinationPort)
        if (portDestinationPortDetails)
            setInquiryDestinationPort(portDestinationPortDetails.name)

        // Cargo Type name
        var cargoTypeDetails = await DataStore.query(InquiryCargoType, inquiryDetails.inquiryCargoTypeId)
        if (cargoTypeDetails)
            setInquiryCargoType(cargoTypeDetails.name)

        //Cargo Product Name
        var cargoProductDetails = await DataStore.query(Products, inquiryDetails.productId)
        if (cargoProductDetails)
            setCargoProductDetails(cargoProductDetails.name)



        setInquirySailingRange(inquiryDetails.sailingRateDate)
        setInquiryContractDuration(inquiryDetails.contractDurationDate)
        setInquiryTermsAndConditions(inquiryDetails.termsConditions)
        setInquiryClosingDate(inquiryDetails.inquiryClosingDate)
        setInquiryNumber(inquiryDetails.inquiryNo)
        setInquiryProductDetails(inquiryDetails.productDetails);
        setInternationalRef(inquiryDetails.internationalRef);
        setcargoReadyDate(inquiryDetails.inquiryCargoReadyDate)


        var inquiryContainers = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId))




        let inquiryContainerArray = []

        for (var i = 0; i < inquiryContainers.length; i++) {
            var inquiryTypeDetails = await DataStore.query(ContainerType, inquiryContainers[i].containerTypeId)
            var inquiryUnitDetails = await DataStore.query(Units, inquiryContainers[i].unitI)
            var inquirySignDetails = await DataStore.query(Units, inquiryContainers[i].dimentionId)


            if (inquiryTypeDetails && inquiryUnitDetails) {

                var data = [];
                data = {
                    "Count": inquiryContainers[i].count,
                    "Type": inquiryTypeDetails.name,
                    "Size": (inquiryContainers[i].length) ? inquiryContainers[i].length + '*' + inquiryContainers[i].width + '*' + inquiryContainers[i].height + " " + inquirySignDetails.sign : '-',
                    "TotalWeight": inquiryContainers[i].totalWeight,
                    "unitI": inquiryUnitDetails.name,
                }
                inquiryContainerArray.push(data);
            }
        }

        setInquiryContainerDetails(inquiryContainerArray)

        let inquiryServiceArray = []
        var inquiryServices = await DataStore.query(InquiryServices);

        var selectedServiceId = inquiryDetails.inquiryServiceId;


        for (var i = 0; i < inquiryServices.length; i++) {
            var serviceImage = await GetAWSObjectURL(inquiryServices[i].iconName).catch(error => toast.error(error));
            var isSelectedService = selectedServiceId === inquiryServices[i].id ? 'checked' : '';
            var data = [];
            data = {
                "Name": inquiryServices[i].name,
                "Image": serviceImage,
                "IsSelectedService": isSelectedService
            }
            inquiryServiceArray.push(data);
        }
        setInquiryServiceData(inquiryServiceArray);
    }

    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">

            <div style={{ width: "48vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">

                <div>

                    <img src={BasicInfoBackGroung} alt="" style={{ width: "50vw" }} />
                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                        <div className="flex justify-end px-6 py-2">
                            <div className="text-md"></div>
                            <div><Link onClick={() => props.closePopUp()} className="closebtn text-2xl leading-none removeClass text-[#01813F]">&times;</Link></div>
                        </div>

                        <div className="flex">
                            <div className="p-5">
                                <p className="font-thin text-sm text-[#01813F]">Basic Bid Information</p>
                                <p className="font-semibold text-[#01813F]">#{inquiryNumber}</p>
                            </div>
                            <div className="p-5">
                                <p className="font-thin text-sm text-[#01813F]">Enquiry Closing Date</p>
                                <p className="font-semibold text-[#01813F]">{inquiryClosingDate ? moment(inquiryClosingDate).format('MM/DD/YYYY') : '-'}</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="p-4 height-custom">
                    <div className="mt-5 mb-2">
                        <div className="font-medium mb-2 flex justify-between">
                            <p className='text-[#101828] text-[16px]'>Service</p>
                            <p className='text-[#101828] text-[16px] 2xl:w-72 xl:w-64' >Origin And Destination</p>
                        </div>

                        <div className="grid grid-cols-8">

                            <div className="col-span-5">
                                <div className="flex gap-2 service-checkbox overflow-x-auto items-center">

                                    {
                                        (inquiryServiceData.length > 0)
                                            ?

                                            inquiryServiceData.map(item => (
                                                <div className="relative ">
                                                    <input className=" absolute right-2 top-2 w-4 h-4 focus:bg-blue focus:ring-opacity-25 border border-[#C8CBD0] rounded-full" type="checkbox" checked={item.IsSelectedService} />
                                                    <label className="inline-flex items-center each flex hover:shadow-lg hover:bg-white p-2 rounded-md border-[#C8CBD0]  border mb-3  justify-center cursor-pointer  w-full">
                                                        <div className="left mr-2">
                                                            <img src={item.Image} />
                                                            {/* <i className="icon ship_icon"></i> */}
                                                        </div>

                                                    </label>
                                                </div>
                                            )) : <div className='border w-36'><div className='text-sm font-thin text-center mt-11'>No Service</div></div>
                                    }
                                </div>
                            </div>

                            <div className="col-span-3">

                                <div className="fromto px-5 py-4 border  rounded-md border-[#E4E7EC] flex gap-4">
                                    <img src={DestinationImg} />
                                    <div className="flex flex-col">
                                        <div className="flex gap-0 mb-5"><i className="icon locaion_green_small mr-2"></i>
                                            <p className="text-[14px] text-[#344054]">{inquiryOriginPort ? inquiryOriginPort : "-"}</p>
                                        </div>

                                        <div className="flex gap-0 mt-8"><i className="icon locaion_green_small mr-2"></i>
                                            <p className="text-[14px] text-[#344054]">{inquiryDestinationPort ? inquiryDestinationPort : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="grid grid-cols-3 divide-x gap-4 border border-inherit h-300 w-full text-center">
                        <div className="p-5 border-b">
                            <p className="font-normal text-sm text-[#667085]">Cargo</p>
                            <p className="font-medium text-sm text-[#344054]">{inquiryCargoType ? inquiryCargoType : "-"}</p>
                        </div>
                        <div className="p-5 border-b">
                            <p className="font-normal text-sm text-[#667085]">Cargo Product</p>
                            <p className="font-medium text-sm text-[#344054]">{inquiryCargoProduct ? inquiryCargoProduct : "-"}</p>
                        </div>
                        <div className="p-5 border-b">
                            <p className="font-normal text-sm text-[#667085]">Product Details</p>
                            <p className="font-medium text-sm text-[#344054]">{inquiryProductDetails ? inquiryProductDetails : "-"}</p>
                        </div>

                        <div className="p-5 border-b">
                            <p className="font-normal text-sm text-[#667085]">Cargo Ready Date</p>
                            <p className="font-medium text-sm text-[#344054]">{moment(cargoReadyDate).format('MM/DD/YYYY') === "01/01/1970" ? "-" : moment(cargoReadyDate).format('MM/DD/YYYY')}</p>
                        </div>

                        <div className="p-5 border-b">
                            <p className="font-normal text-sm text-[#667085]">Internal Reference</p>
                            <p className="font-medium text-sm text-[#344054]">{internationalRef ? internationalRef : "-"}</p>
                        </div>

                        <div className="pt-5 border-b">
                            <p className="font-normal  text-sm text-[#667085]">Sailing Range/Contract Duration</p>
                            <p className="font-medium text-sm text-[#344054]">{inquirySailingRange ? moment(inquirySailingRange).format('MM/DD/YYYY') : null} - {inquiryContractDuration ? moment(inquiryContractDuration).format('MM/DD/YYYY') : null}</p>
                        </div>


                    </div>

                    <div className="mt-5">
                        <div className="font-medium mb-1 text-[#101828]">
                            {inquiryContainerDetails.length ? "Container Details" : null}</div>
                        {
                            inquiryContainerDetails.map(ietm => (
                                <div className=" grid grid-cols-5 gap-3 h-300 w-full text-center mb-2">
                                    <div className="p-5 border border-[#E4E7EC] rounded-md">
                                        <p className="font-normal  text-sm text-[#667085]">Count</p>
                                        <p className="font-medium text-sm text-[#344054]">{ietm.Count}</p>
                                    </div>
                                    <div className="p-5 border border-[#E4E7EC] rounded-md">
                                        <p className="font-normal  text-sm text-[#667085]">Type</p>
                                        <p className="font-medium text-sm text-[#344054]">{ietm.Type}</p>
                                    </div>
                                    <div className="p-5 border border-[#E4E7EC] rounded-md">
                                        <p className="font-normal  text-sm text-[#667085]">Size</p>
                                        <p className="font-medium text-sm text-[#344054]">{ietm.Size}</p>
                                    </div>
                                    <div className="p-5 border border-[#E4E7EC] rounded-md">
                                        <p className="font-normal  text-sm text-[#667085]">Total Weight</p>
                                        <p className="font-medium text-sm text-[#344054]">{ietm.TotalWeight ? ietm.TotalWeight : "-"}</p>
                                    </div>
                                    <div className="p-5 border border-[#E4E7EC] rounded-md">
                                        <p className="font-normal  text-sm text-[#667085]">Unit</p>
                                        <p className="font-medium text-sm text-[#344054]">{ietm.unitI}</p>
                                    </div>
                                </div>
                            ))
                        }

                    </div>

                    <div className="mt-5 ">
                        <div className="font-medium mb-1 text-[#101828]">Terms & Conditions</div>
                        <textarea readOnly className="rounded-md font-medium mb-10 text-sm p-2 w-full border border-[#C6CBD2] text-[#101828]" rows={4} value={inquiryTermsAndConditions}></textarea>
                    </div>
                </div>

                <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                    <div className="flex justify-end gap-3">
                        <div><Link onClick={() => props.closePopUp()} className="bg-white 
                     dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                        >Cancel</Link></div>

                    </div>
                </div>


            </div>
            <div id="selector"></div>
        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}