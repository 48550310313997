import { DataStore } from '@aws-amplify/datastore';
import { FreightForwarder, InquiryBidRule, FreightForwarderQuestion, InquiryBid, BidStatus, Units, InquiryServices, Inquiry, InquiryContainers, Country, ContainerType, InquiryCargoType, InquiryBidContainerDetails } from '../../../../models';
import React, { useEffect, useState } from 'react';
import { json, Link, useParams } from "react-router-dom";
import "../../../../proConnect.css";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import { ConvertResponseForSelect } from '../../../../components/helper/commonfunctions';
import { toast } from 'react-toastify';
import { GetAWSObjectURL } from '../../../../components/helper/aws';
import RewardIcon from '../../../../assets/svg/connectpro/RewardIcon.svg';
import Route from '../../../../assets/svg/connectpro/Route.svg';
import moment from "moment";
import uniqid from 'uniqid';
import InquiryView from "../inquiryview.component";
import { reactLocalStorage } from 'reactjs-localstorage';
import { InquiryBidsComponent } from '../inquiry.invitebids.component';
import InquiryRole from '../inquiryrole.component';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { ConfirmBid } from '../confirmbid.component';
import { CustomerQuotationPopup } from '../customerquotation.component';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph, loginRequest, graphConfig } from "../../../../authConfig";


export default function BidDashboard() {

    //Validate User Logged In

    const [frightForworders, setFrightForworders] = useState([]);
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [isInvitePopUp, setInvitePopUp] = useState(0);
    const { inquiryId } = useParams();
    const [totalBidsInvited, setTotalBidsInvited] = useState('0');
    const [inquiryBidInvitee, setInquiryBidInvitee] = useState([]);
    const [declinedBidsInvited, setDeclinedBidsInvited] = useState('');
    const [confirmedBidsInvited, setConfirmedBidsInvited] = useState('');
    const [notResponsedBidsInvited, setNotResponsedBidsInvited] = useState('');
    const [responsedBidsInvited, setResponsedBidsInvited] = useState('');
    const [inquiryBidList, setInquiryBidList] = useState([]);
    const [attachmentId, setAttachmentId] = useState();
    const [checkedRequestDetails, setCheckedRequestDetails] = useState([]);

    // For Basic information popup
    const [inquiryOriginPort, setInquiryOriginPort] = useState('');
    const [inquiryDestinationPort, setInquiryDestinationPort] = useState('');
    const [inquiryCargoType, setInquiryCargoType] = useState('');
    const [inquirySailingRange, setInquirySailingRange] = useState('');
    const [inquiryContractDuration, setInquiryContractDuration] = useState('');
    const [inquiryContainerDetails, setInquiryContainerDetails] = useState([]);
    const [inquiryTermsAndConditions, setInquiryTermsAndConditions] = useState('');
    const [inquiryClosingDate, setInquiryClosingDate] = useState('');
    const [inquiryNumber, setInquiryNumber] = useState('');
    const [inquiryServiceData, setInquiryServiceData] = useState([]);
    const [firstTimeRolePopup, setFirstTimeRolePopup] = useState("");

    const [freightForwarderQuestion, setFreightForwarderQuestion] = useState([]);
    const [inputWeightage, setInputWeightage] = useState("");

    const [allFreightForwarderQuestions, setAllFreightForwarderQuestions] = useState([]);

    const [inquiryProductDetails, setInquiryProductDetails] = useState('');
    const [cargoReadyDate, setCargoReadyDate] = useState('');
    const [internationalRef, setInternationalRef] = useState('');

    const [totalRule, setTotalRule] = useState('0');
    const [confirmPopup, setConfirmPopup] = useState("");
    const [customerQuotationPopup, setCustomerQuotationPopup] = useState("");
    const [selectedBidId, setSelectedBidId] = useState("");

    //Pop Up
    const [isViewPopUp, setViewPopUp] = useState(0);


    //Validate User Logged In
    const isMicrosoftAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [checkConfirmStatus, setCheckConfirmStatus] = useState("");


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const handleChangeRequest = async (event) => {
        var data = [...checkedRequestDetails];
        if (event.target.checked && !data.includes(event.target.value)) {
            data.push(event.target.value)
        } else {
            var index = data.indexOf(event.target.value);
            data.splice(index, 1);
        }
        setCheckedRequestDetails(data);
    };

    var requestData = [];

    allFreightForwarderQuestions.map((item) => {

        let data = {
            "Text": "C",
            "Value": <div className=''> <input type="checkbox" onChange={handleChangeRequest} value="C" id="requestDataC" className="relative top-0.5 h-4 w-4 border-[#C6CBD2] rounded-lg" />
                <label htmlFor='requestDataC' className="ml-1 text-md font-normal text-[#101828]">{item.question}</label>
            </div>
        }

        requestData.push(data);
    })

    const handleDeleteClick = async (e, id) => {

        e.preventDefault();
        const objDeleteQuestion = await DataStore.query(InquiryBid, id);
        alertify.confirm('Confirmation', 'Do you want to delete this Bid?', async function () {
            //Delete Existing InterpreterRequest for this Question

            DataStore.delete(objDeleteQuestion);

            BindList();
            toast.error("Deleted");
        }
            , function () { });

    };

    const handleMailClick = async (bidId, email, freightForwarderName, subject) => {

        alertify.confirm('Confirmation', 'Do you want to Resend Bid Invitation Mail?', async function () {


            var inquiryContainerData = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId));

            let mailTableData = [];
            for (var i = 0; i < inquiryContainerData.length; i++) {
                var containerTypeData = await DataStore.query(ContainerType, (c) => c.id.eq(inquiryContainerData[i].containerTypeId))
                var containerUnitData = await DataStore.query(Units, (c) => c.id.eq(inquiryContainerData[i].unitI))
                var inquirySignDetails = await DataStore.query(Units, inquiryContainerData[i].dimentionId)


                let data = {
                    count: inquiryContainerData[i].count,
                    type: containerTypeData[0].name,
                    size: (inquiryContainerData[i].length) ? inquiryContainerData[i].length + '*' + inquiryContainerData[i].width + '*' + inquiryContainerData[i].height + " " + inquirySignDetails.sign : '-',
                    totalWeight: inquiryContainerData[i].totalWeight + ' ' + containerUnitData[0].name
                }

                mailTableData.push(data);
            }


            var htmlTmp1 = '<p><span style="font-size:18px">Dear ' + freightForwarderName + ',</span></p><p>Please provide your quote the earliest Door to Port By Air.</p><table ><tbody><tr></tr>';

            mailTableData.map((item, i) => {
                htmlTmp1 += `<tr>
                    <td style="text-align:left">Box ${i + 1}</td>
                    <td style="text-align:left">-</td>
                    <td style="text-align:left">${item.size}</td>
                    <td style="text-align:left">-</td>
                    <td style="text-align:left">${item.totalWeight}</td>
                    </tr>`;
            })



            htmlTmp1 += '</table ><div style="line-height: 5px;margin-top: 5%;"><span style="font-family:Calibri">TOTAL NET WT : 76.00 KGS</span><p>TOTAL GROSS WT : 90.00 KGS</p></div><p>Commodity – Battery</p><div><p style="font-weight:bold;text-decoration:underline;line-height: 0;margin-top: 6%;">Collection Address :</p><p style="margin-right: 44%"> Sarah International Flat no A70 Sr No 61/62/63, Oxford Village,Phase-III, BL-A5, Wanowarie, Pune-411040 Maharashtra. India  Contact Person - Mr Kutbuddin.S Mobile # 9822068176 sarahinternational2017@gmail.com </p></div><p style="font-size:11pt; font-family: Cambria;color: #1f497d;">ALL RATES MENTIONED ABOVE EXCLUDE INSURANCE, DUTY AND VAT, IF ANY, AT ACTUALS</p><p>Please <a href="https://proconnect.edbrix.info/inquirybid/' + bidId + '">click here</a> to process further.</p><p>Thanking You</p><p>' + reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')
                + '</p><div style="color:#da0000;font-family:Calibri;">PROCONNECT SUPPLY CHAIN LOGISTICS LLC</div><div>Plot No. S 30902, Jebel Ali Free Zone South P.O. Box 262674, Dubai, U.A.E.</div><div>Mobile: +971 0508983221</div><div>Toll free number: 600575755</div></tbody >';

            sendEmailToFrightForward(htmlTmp1, email, subject);
            BindList();
            toast.success("Email Successfully Send");
        }
            , function () { });

    };

    async function sendEmailToFrightForward(emailTemplate, freightForwarderEmail, subject) {

        if (isMicrosoftAuthenticated) {
            var customQueryURL = graphConfig.graphMeSendMessages;
            var requestBody = {
                "message": {
                    "subject": subject,
                    "body": {
                        "contentType": "html",
                        "content": emailTemplate
                    },
                    "toRecipients": [
                        {
                            "emailAddress": {
                                "address": freightForwarderEmail
                            }
                        }
                    ],
                }
            };
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                callMsGraph(response.accessToken, customQueryURL, "POST", requestBody).then(async (currentResponse) => {
                });
            });
        }
    }

    async function BindList() {

        var inquiryDetails = await DataStore.query(Inquiry, inquiryId);
        setInquiryDestinationPort(inquiryDetails.inquiryDestinationPort)

        setInquirySailingRange(inquiryDetails.sailingRateDate)
        setInquiryContractDuration(inquiryDetails.contractDurationDate)
        setInquiryTermsAndConditions(inquiryDetails.termsConditions)
        setInquiryClosingDate(inquiryDetails.inquiryClosingDate)
        setInquiryNumber(inquiryDetails.inquiryNo)
        setInquiryProductDetails(inquiryDetails.productDetails);
        setCargoReadyDate(inquiryDetails.inquiryCargoReadyDate);
        setInternationalRef(inquiryDetails.internationalRef);

        //get freight forwarder data
        setAllFreightForwarderQuestions(await DataStore.query(FreightForwarderQuestion));

        // Cargo Type name
        var cargoTypeDetails = await DataStore.query(InquiryCargoType, inquiryDetails.inquiryCargoTypeId)
        if (cargoTypeDetails)
            setInquiryCargoType(cargoTypeDetails.name)

        // Origin Port name
        var originName = '-';
        var portOriginPortDetails = await DataStore.query(Country, inquiryDetails.inquiryOriginPort)
        if (portOriginPortDetails) {
            originName = portOriginPortDetails.name;
            setInquiryOriginPort(portOriginPortDetails.name)
        }

        // Destination Port name
        var destinationName = '-';
        var portDestinationPortDetails = await DataStore.query(Country, inquiryDetails.inquiryDestinationPort)
        if (portDestinationPortDetails) {
            destinationName = portDestinationPortDetails.name;
            setInquiryDestinationPort(portDestinationPortDetails.name)
        }

        var inquiryContainers = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId))

        let inquiryContainerArray = []


        for (var i = 0; i < inquiryContainers.length; i++) {
            var inquiryTypeDetails = await DataStore.query(ContainerType, inquiryContainers[i].containerTypeId)

            if (inquiryTypeDetails) {

                var data = [];
                data = {
                    "Count": inquiryContainers[i].count,
                    "Type": inquiryTypeDetails.name,
                    "Size": inquiryContainers[i].size,
                    "TotalWeight": inquiryContainers[i].totalWeight,
                    "Cargo": '-',
                }
                inquiryContainerArray.push(data);
            }
        }

        //bid rule count
        var weights = await DataStore.query(InquiryBidRule, (c) => c.inquiryId.eq(inquiryId));
        setTotalRule(weights.length);

        setInquiryContainerDetails(inquiryContainerArray)

        let inquiryServiceArray = []
        var inquiryServices = await DataStore.query(InquiryServices);

        var selectedServiceId = inquiryDetails.inquiryServiceId;


        for (var i = 0; i < inquiryServices.length; i++) {
            var serviceImage = await GetAWSObjectURL(inquiryServices[i].iconName).catch(error => toast.error(error));
            var isSelectedService = selectedServiceId === inquiryServices[i].id ? 'checked' : '';
            var data = [];
            data = {
                "Name": inquiryServices[i].name,
                "Image": serviceImage,
                "IsSelectedService": isSelectedService
            }
            inquiryServiceArray.push(data);
        }

        setInquiryServiceData(inquiryServiceArray);

        var frightForwordersData = await DataStore.query(FreightForwarder);
        var myFrightForwaderArray = []; myFrightForwaderArray = ConvertResponseForSelect(frightForwordersData);
        setFrightForworders(myFrightForwaderArray);

        var bidInvitees = await DataStore.query(InquiryBid, (c) => c.inquiryId.eq(inquiryId));

        var inquiryDeclinedCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.DECLINED)
        ]));

        var inquiryConfirmedCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.CONFIRMED)
        ]));

        var notResponsedBidCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.INVITED)
        ]));

        let inquiryBidInviteeData = []

        for (let i = 0; i < bidInvitees.length; i++) {

            var frightForwordersDetails = await DataStore.query(FreightForwarder, bidInvitees[i].freightForwarderId);
            var profilePic = '';

            var inquiryContainerDetailCount = await DataStore.query(InquiryBidContainerDetails, (c) => c.inquiryBid.eq(bidInvitees[i].id))

            let inquiryContainerAmount = 0;

            var counter = inquiryContainerDetailCount.length;
            for (let j = 0; j < counter; j++) {
                let inquiryAmount = inquiryContainerDetailCount[j].amount;
                inquiryContainerAmount = inquiryContainerAmount + inquiryAmount / counter;
            }

            if (frightForwordersDetails)
                profilePic = await GetAWSObjectURL(frightForwordersDetails.logo).catch(error => toast.error(error));

            var sortorder = 0;
            if (bidInvitees[i].isApproved == true)
                sortorder = 1;
            else if (bidInvitees[i].bidStatus == BidStatus.CONFIRMED)
                sortorder = 2;
            else if (bidInvitees[i].bidStatus == BidStatus.DECLINED)
                sortorder = 3;
            else
                sortorder = 4;

            if (frightForwordersDetails) {
                let data = {
                    "MailSubject": bidInvitees[i].emailSubject,
                    "InviteBidId": bidInvitees[i].id,
                    "InviteDate": bidInvitees[i].createdAt,
                    "InviteUpdatedDate": bidInvitees[i].updatedAt,
                    "FrightForwardName": frightForwordersDetails.name,
                    "FrightForwardEmail": frightForwordersDetails.email,
                    "AvgCount": inquiryContainerAmount.toFixed(2),
                    "FrightForwardProfilePic": profilePic,
                    "isConfirmed": bidInvitees[i].isApproved,
                    "sortOrder": sortorder,
                    "isFrightForwardConfirmed": bidInvitees[i].bidStatus,
                }
                inquiryBidInviteeData.push(data);
            }
        }

        let sortArray = inquiryBidInviteeData.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))

        for (var i = 0; i < inquiryBidList.length; i++) {
            if (inquiryBidList[i].isConfirmed === true) {
                setCheckConfirmStatus(true);
            }

        }


        setInquiryBidList(sortArray)
        setInquiryBidInvitee(bidInvitees);
        setTotalBidsInvited(bidInvitees.length);
        setDeclinedBidsInvited(inquiryDeclinedCount.length ? inquiryDeclinedCount.length : 0);
        setConfirmedBidsInvited(inquiryConfirmedCount.length ? inquiryConfirmedCount.length : 0);
        setNotResponsedBidsInvited(notResponsedBidCount.length);
        setResponsedBidsInvited(inquiryDeclinedCount.length + inquiryConfirmedCount.length);
        setAttachmentId(uniqid());
    }


    useEffect(() => {
        onLoad();
        BindList();
    }, [inputWeightage, freightForwarderQuestion, firstTimeRolePopup, customerQuotationPopup, inquiryBidList]);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4 inquies">
                            <Link to="/employee/inquiries" className="text-[#344054] text-[14px] dark:text-white py-2 px-3 rounded-md border border-[#D0D5DD] dark:bg-[#2A2E32] dark:border-[#1d2123]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Inquiry ID {reactLocalStorage.get('inquiryNo')}</div>
                        </div>
                    </div>

                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link onClick={() => {
                                    reactLocalStorage.set('isInquiryEdit', true);
                                }} to={"/employee/inquiry/" + inquiryId} className="">Inquiry</Link></li>
                                <li><Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="active">Bids</Link></li>
                                <li><Link to={"/employee/inquiry/quotation/dashboard/" + inquiryId} className="">Quotation</Link></li>
                                <li><Link to={"/employee/inquiry/timeline/dashboard/" + inquiryId} className="">Timeline</Link></li>
                                <li><Link to={"/employee/inquiry/mails/dashboard/" + inquiryId} className="">Mails</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">
                            <div className='flex justify-end right-side gap-2'>
                                <div className="mt-5 lg:mt-0">
                                    <Link onClick={() => setViewPopUp(1)} to="#" className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2] dark:bg-[#2A2E32] dark:border-[#1d2123]">
                                        <i className="pi pi-info-circle m-1 "></i>
                                        Basic Information
                                    </Link>
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <Link onClick={() => setFirstTimeRolePopup(1)} to="#" className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2] dark:bg-[#2A2E32] dark:border-[#1d2123]">
                                        Rule ({totalRule})
                                    </Link>
                                </div>
                                <div className="mt-5 lg:mt-0">
                                    <Link to={"/employee/inquiry/bid/comparebids/" + inquiryId} className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2] dark:bg-[#2A2E32] dark:border-[#1d2123]">
                                        Compare Bids
                                    </Link>
                                </div>
                                <div>
                                    <Link to="#" onClick={() => setInvitePopUp(1)} className="bg-[#029046] flex text-[#fff] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2] dark:border-[#029046]">
                                        Invite Bids ({totalBidsInvited})
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* code here */}
                    <div className={"block w-full"}>
                        <div className='inquiesbids mt-5'>
                            <div className='mt-10 flex-row grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-6 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-10 bids_feture'>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] invited-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Total Bids Invited</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{totalBidsInvited}</p>
                                    </div>
                                </div>

                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] responded-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Responded</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{responsedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] unresponsive-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Unresponsive</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{notResponsedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] declined-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Declined</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{declinedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] confirmed-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Confirmed Bids</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{confirmedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] status-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4 mb-3'>Status</h6>
                                        <p className='text-[#1B55AF] mt-1 px-3 py-2 rounded-full text-[12px] inline-flex items-center justify-center gap-1 bg-[#E8EEF7]'><div className="w-2 h-2 bg-[#1B55AF] rounded-full"></div>Open</p>
                                    </div>
                                </div>

                            </div>
                        </div>



                        {
                            // Fright Forward List
                            inquiryBidList.map(item => (
                                //Set service array for checkbox
                                <div className="bg-white dark:bg-[#2A2E32] mt-3 py-2 rounded-lg px-3 boxshadow grid grid-cols-1 divide-y">
                                    <div className='flex justify-between my-1'>
                                        <div className='flex'>
                                            {/* <Avatar alt="" src={item.FrightForwardProfilePic} /> */}
                                            <img className=' w-14 h-14 rounded-full object-cover ' src={item.FrightForwardProfilePic} alt='Logo' />
                                            <div>
                                                <p className='font-extrabold text-lg mb-1 dark:text-white ml-1'>{item.FrightForwardName}</p>
                                                <div className='flex gap-x-4 ml-1'>
                                                    <span className='flex '><i className="pi pi-calendar pt-1 pr-1"></i>Bid Invite Date :<p className='font-semibold ml-1'>{moment(item.InviteDate).format('MM/DD/YYYY')}</p></span>
                                                    {/* <span className='flex '><i className="pi pi-calendar pt-1 pr-1"></i>Bid Submitted Date:<p className='font-semibold'>-</p></span> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex gap-x-14'>
                                            <span><p className='text-sm'>Avg. Price Per Container</p><p className='font-semibold'>{item.AvgCount != 0.00 ? '$' + item.AvgCount : '-'}</p></span>
                                            <span><p className='text-sm'>Avg. Freight Per Container</p><span className='flex'><p className='font-semibold'>-</p></span></span>
                                            <div className='p-4 bg-[#EDF7EE]' style={{ height: '60px', width: '60px', border: '1px solid #EDF7EE', borderRadius: '50%' }}>
                                                <span className='flex gap-x-1 font-semibold'><img src={RewardIcon} alt='RewardIcon' />1</span>
                                            </div>

                                        </div>

                                    </div>

                                    <div className='flex justify-between my-1 py-5'>
                                        <span><p className='text-sm'>Demurrage Free Days</p><p className='font-semibold'>2 Days</p></span>
                                        <span><p className='text-sm'>Detention Free Days</p><p className='font-semibold'>2 Days</p></span>
                                        <span><p className='text-sm'>Combined Free Days</p><p className='font-semibold'>2 Days</p></span>
                                        <span><p className='text-sm'>Transit Days</p><p className='font-semibold'>5 Days</p></span>
                                        <span><p className='text-sm'>Schedule</p><p className='font-semibold'>N/A</p></span>
                                        <span><p className='text-sm'>Valid Till</p><p className='font-semibold'>22/10/2022</p></span>
                                        <span><p className='text-sm'>Transhipment</p><p className='font-semibold'>N/A</p></span>
                                        <span className='flex gap-x-2'><img src={Route} alt="Route" /><span><p className='text-sm'>Route</p><p className='font-semibold'>Direct</p></span></span>

                                    </div>

                                    <div className='flex justify-between py-3'>
                                        <span className='flex '><i className="pi pi-stopwatch pt-1 pr-1"></i>Last Updated Date:<p className='font-semibold'>{moment(item.InviteUpdatedDate).format('MM/DD/YYYY')}</p></span>

                                        <div className='flex gap-x-6'>
                                            <div className='flex justify-between gap-x-2 mt-1'>
                                                <p className='flex font-medium text-xs text-[#01813F] p-1' >
                                                    <Link className={item.isFrightForwardConfirmed != "INVITED" ? 'hidden' : null} onClick={(e) => handleMailClick(item.InviteBidId, item.FrightForwardEmail, item.FrightForwardName, item.MailSubject)} to="#">   <i className='pi pi-envelope mr-1' ></i>  Mail</Link>
                                                </p>
                                                <p className='flex font-medium text-xs text-[#01813F] p-1'>
                                                    <i className='pi pi-trash mr-1'></i>
                                                    <Link onClick={(e) => handleDeleteClick(e, item.InviteBidId)}>Delete</Link>
                                                </p>

                                                <p className={'flex font-medium text-xs text-[#01813F] p-1  ' + (checkConfirmStatus === true ? 'pointer-events-none opacity-50' : null)}>
                                                    <Link className={item.isFrightForwardConfirmed != "CONFIRMED" ? 'hidden' : null} onClick={() => {
                                                        setConfirmPopup(1)
                                                        setSelectedBidId(item.InviteBidId)

                                                    }} to="#">{item.isConfirmed == true ? 'Confirmed' : 'Confirm'}</Link>
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                        <div id="selector" className={isInvitePopUp === 1 ? 'overlay' : ''}></div>

                        {
                            isInvitePopUp === 1 ?
                                <InquiryBidsComponent
                                    closePopUp={() => setInvitePopUp(0)}
                                    bindList={() => BindList()}
                                /> : null
                        }

                    </div>

                </div>
                <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>

                {
                    isViewPopUp === 1 ?
                        <div>
                            <InquiryView
                                closePopUp={() => setViewPopUp(0)}
                            />
                        </div> : null
                }

            </div>

            <div id="selector"></div>

            <div id="selector" className={firstTimeRolePopup === 1 ? 'overlay' : ''}></div>
            {
                firstTimeRolePopup === 1 ?
                    <div>
                        <InquiryRole
                            closeRolePopUp={() => setFirstTimeRolePopup(0)}
                            totalcount={totalRule}

                        />
                    </div> : null

            }

            {/* ##################### Confirm Popup ###################################### */}

            <div id="selector" className={confirmPopup === 1 ? 'overlay' : ''}></div>
            {
                confirmPopup === 1 ?
                    <div><ConfirmBid
                        closepopup={() => setConfirmPopup(0)}
                        bidsId={selectedBidId}
                        openPopup={function openSecondPopup() {
                            setConfirmPopup(0)
                            setCustomerQuotationPopup(1)
                        }
                        }
                    />
                    </div> : null

            }

            <div id="selector" className={customerQuotationPopup === 1 ? 'overlay' : ''}></div>
            {
                customerQuotationPopup === 1 ?
                    <div><CustomerQuotationPopup closeQuotationPopup={() => { setCustomerQuotationPopup(0) }}
                        bidsId={selectedBidId}
                    /></div> : null
            }

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}