import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { DataStore } from '@aws-amplify/datastore';
import { Employee, Roles } from '../../models';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import { USER_TYPES } from '../../components/helper/enum';
import { ValidateEmployeeRole } from '../../components/helper/validateRole';
import EmpDashboard from '../../pages/employee/landing.component';
import { GetAWSObjectURL } from '../../components/helper/aws';

import logowhite from '../../assets/images/logo-white.png';
import ValidateEmail from '../../components/common/ValidateEmail';
import DefaultProfilePic from '../../assets/images/user.png'

export default function EmpLogin(props) {
    let navigateTo = useNavigate();

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);


    async function ValidateEmpLogin(event) {
        event.preventDefault();
        if (email === "") { toast.error('Enter email.'); return; }
        else if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }
        else if (password === "") { toast.error('Enter password.'); return; }

        try {
            //reactLocalStorage.clear();
            setPleaseWaitButton(true);

            //Validate Employee in table
            let getEmployee = await DataStore.query(Employee, (c) => c.email.eq(email));

            setPleaseWaitButton(false);
            if (getEmployee && getEmployee.length > 0) {
                reactLocalStorage.set('loggedUserRole', USER_TYPES.Employee);
                reactLocalStorage.set('loggedEmployeeId', getEmployee[0].id);
                reactLocalStorage.set('loggedEmployeeFirstName', getEmployee[0].firstName);
                reactLocalStorage.set('loggedEmployeeLastName', getEmployee[0].lastName);
                reactLocalStorage.set('loggedEmployeeProfilePic', DefaultProfilePic);

                //Get Role
                let getEmployeeRole = await DataStore.query(Roles, (c) => c.id.eq(getEmployee[0].role_id));
                reactLocalStorage.set('loggedEmployeeRoleId', getEmployee[0].role_id);
                reactLocalStorage.set('loggedEmployeeRoleName', getEmployeeRole[0].name);

                //Profile Pic Path
                if (getEmployee[0].profilePic) {
                    let getImagePath = await GetAWSObjectURL(getEmployee[0].profilePic);
                    reactLocalStorage.set('loggedEmployeeProfilePic', getImagePath);
                }

                navigateTo('/employee/landing');
                navigateTo(0);
            }
            else {
                toast.error("Invalid Email or Password.");
            }

        } catch (e) {
            setPleaseWaitButton(false);
            toast.error(e.message);
        }

    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        isAuthenticated ? (
            <EmpDashboard />
        ) : (
            <div className='login-bg'>
                <div
                    className="min-h-screen flex-row  grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:grid-cols-1 gap-0">
                    <div className="flex flex-wrap flex-row justify-center auto-rows-max">
                        <div className="max-w-md 2xl:max-w-lg w-full p-2">
                            <form onSubmit={ValidateEmpLogin} autoComplete="off">
                                <div className="mb-4 md:mb-4 2xl:mb-4 lg:mb-4 xl:mb-4 xl:mt-8">
                                    <img className="2xl:h-14 h-14" src={logowhite} alt="" />
                                </div>
                                <div className='mt-3 p-8 bg-[#EDEBDE] border border-[#60B866] rounded-lg'>
                                    <div className="mb-4">
                                        <h2 className="text-[26px] font-semibold text-[#101828] dark:text-white">Login</h2>
                                    </div>



                                    {
                                        props.sessionExpired === 1 ?
                                            <div className="mb-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                                <span className="text-sm text-red-600">Session expired. Please login again.</span>
                                            </div>
                                            : ''
                                    }

                                    <div className="relative mb-4">
                                        <label htmlFor="required-email" className="text-[#344054] text-[14px] font-medium">
                                            Email
                                            <span className="text-red-500 required-dot">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            onChange={(e) => setEmail(e.target.value)} value={email} type="email" id="required-email"
                                            className="text-[16px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-2 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                        />
                                    </div>
                                    <div className="relative mb-3">
                                        <label htmlFor="required-password" className="text-[#344054] text-[14px] font-medium">
                                            Password
                                            <span className="text-red-500 required-dot">
                                                *
                                            </span>
                                        </label>
                                        <input onChange={(e) => setPassword(e.target.value)} value={password} type={showPassword ? "text" : "password"} id="required-password"
                                            className="text-[16px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-2 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                        />
                                        <Link onClick={() => setShowPassword(!showPassword)} > <i className="pi pi-eye-slash eyes_style"></i></Link>
                                    </div>
                                    <div className="flex items-center justify-between align-center mb-8 mt-2">
                                        <div className="flex flex-row items-center">

                                        </div>
                                        {/* <div>
                                            <Link className="text-[15px] font-normal text-[#029046] hover:underline" >Forgot password?</Link>
                                        </div> */}
                                    </div>
                                    <div className="flex w-full mb-0">
                                        <div className="flex w-full mb-0">
                                            <button onClick={ValidateEmpLogin} type="submit" className="py-2 px-5 bg-[#029046] hover:bg-[#19AA5F] focus:ring-green-500 focus:ring-offset-green-800 text-white w-full transition ease-in duration-200 text-center text-base font-normal shadow-md focus:outline-none focus:ring-1 focus:ring-offset-2  rounded-lg ">
                                                {showPleaseWaitButton ? 'Please wait...' : 'Sign In'}
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                    <div className="flex-row flex flex-wrap align-center justify-center content-center">

                        <div className=" py-10 px-16">
                            <div className="auto-rows-max flex-row">

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        ))
}