import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import EmpLogin from "../../../../setup/auth/emp.component";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
// import React, { useState } from 'react';
// import { Link } from "react-router-dom";

export default function EmployeeDashboardDetailedFreightForwarder() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    const yearList = [
        {
            'Freight_Forwarder': 'SLR Shipping Services',
            'Bids_Invited': '100',
            'FF_Responded': '75',
            'FF_No_Response': '15',
            'FF_Declined': '10',
            'FF_Confirmed_Bidder': '10',
            'FF_Executed': '10',
            'Invite2Response_Avg_Time': '5',
            'FF_Conversion': '10 %',
            'Conversion_LP_Var': '1 %'
        },
        {
            'Freight_Forwarder': 'Al Mas Cargo LLC',
            'Bids_Invited': '100',
            'FF_Responded': '75',
            'FF_No_Response': '15',
            'FF_Declined': '10',
            'FF_Confirmed_Bidder': '10',
            'FF_Executed': '10',
            'Invite2Response_Avg_Time': '5',
            'FF_Conversion': '10 %',
            'Conversion_LP_Var': '1 %'
        },
        {
            'Freight_Forwarder': 'ALSI For Marine Services LLC',
            'Bids_Invited': '100',
            'FF_Responded': '75',
            'FF_No_Response': '15',
            'FF_Declined': '10',
            'FF_Confirmed_Bidder': '10',
            'FF_Executed': '10',
            'Invite2Response_Avg_Time': '5',
            'FF_Conversion': '10 %',
            'Conversion_LP_Var': '1 %'
        },

    ];


    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className='pl-16'>
                <div className="dashboard-main-wrapper dashboardproconnect">
                    <div className="p-5 pb-0">
                        <div className="sm:flex items-center space-x-8">
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/dashboard" className="">Summary</Link></li>
                                    <li><Link className='active'>Detailed</Link></li>
                                    <li><Link to="#">Drilldown</Link></li>
                                    <li><Link to="#">GeoSpatial</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:flex justify-between my-6 pl-5">
                    <div className="tabs_two">
                        <ul className="flex">
                            <li><Link to={"/employee/dashboard/detailed"} className="">Year</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/month"} className="">Month</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/services"} className="">Services</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/customers"} className="">Customers</Link></li>
                            <li><Link className="active">Freight Forwarders</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/salesexecutive"} className="">Sales Executive</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/tradeline"} className="">Trade Line</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/margins"} className="">Margins</Link></li>
                            <li><Link to={"/employee/dashboard/detailed/cargotype"} className="">Cargo Type</Link></li>

                        </ul>
                    </div>
                </div>

                <div className="m-5 bg-white rounded-lg mt-2 pt-5 px-1 dark:bg-[#2A2E32] mt-5  border border-[#E4E7EC] dark:border-[#344054] dark:text-white  mb-10 pb-10 ">

                    <div className='flex gap-4 justify-between items=center mr-2 px-4'>
                        <div className='text-lg font-normal dark:text-white'>Freight Forwarders</div>
                        <div className='flex gap-4 flex-right'>
                            <Link to='' className='flex justify-end w-20 flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-print'></i> Print</Link>
                            <Link to='' className='flex justify-end w-34  flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-download'></i> Download</Link>
                        </div>
                    </div>

                    <div>
                        <div className=" rounded-lg mt-5">
                            <DataTable paginator value={yearList} responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="Freight_Forwarder" header="Freight Forwarder" filter></Column>
                                <Column field="Bids_Invited" header="Bids Invited" sortable={true}></Column>
                                <Column field="FF_Responded" header="FF - Responded" sortable={true}></Column>
                                <Column field="FF_No_Response" header="FF - No Response" sortable={true}></Column>
                                <Column field="FF_Declined" header="FF - Declined" sortable={true}></Column>
                                <Column field="FF_Confirmed_Bidder" header="FF - Confirmed Bidder" sortable={true}></Column>
                                <Column field="FF_Executed" header="FF - Executed" sortable={true}></Column>
                                <Column field='Invite2Response_Avg_Time' header="Invite2Response - Avg Time (days)" sortable={true}></Column>
                                <Column field='FF_Conversion' header="FF - % Conversion" sortable={true}></Column>
                                <Column field='Conversion_LP_Var' header="%Conversion-LP Var" sortable={true}></Column>

                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}