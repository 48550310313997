import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
// import React, { useState } from 'react';
// import { Link } from "react-router-dom";
import Select from 'react-select';
import ReactEChart from "echarts-for-react";

export default function EmployeeDashboardDrillDown() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const option = {
        series: [{
            type: "tree",
            data: [{
                name: "Quotation Accepted",
                children: [{
                    name: "Air Freight",
                },
                {
                    name: "Sea Freight - FCL",
                    children: [{
                        name: "Port of Origin 1"
                    },
                    {
                        name: "Port of Origin 2",
                        children: [{
                            name: "Port of Destination 1"
                        },
                        {
                            name: "Port of Destination 2"
                        },
                        {
                            name: "Port of Destination 3"
                        },
                        {
                            name: "Port of Destination 4"
                        },
                        {
                            name: "Port of Destination 5",
                            children: [{
                                name: "Freight Forwarder 1"
                            },
                            {
                                name: "Freight Forwarder 2"
                            },
                            {
                                name: "Freight Forwarder 3"
                            },
                            {
                                name: "Freight Forwarder 4"
                            },
                            {
                                name: "Freight Forwarder 5"
                            }

                            ]
                        },
                        {
                            name: "Port of Destination 6"
                        },
                        ]
                    },
                    {
                        name: "Port of Origin 3"
                    }
                    ]
                },
                {
                    name: "Sea Freight - LCL",
                },
                {
                    name: "Land Freight",
                },
                {
                    name: "Warehousing"
                }
                ]
            }],
            label: {
                position: "left"
            },
            initialTreeDepth: 0,
            Symbol:'image://http://example.website/a/b.png',
            lineStyle: {
                // color: "#D7211D",
                width: 10.5
            }
        }]
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="dashboard-main-wrapper dashboardproconnect pl-16" x-data="{tab: 1}">
                <div className="p-5 pb-0">
                    <div className="flex justify-between sm:flex items-center space-x-8">
                        <div className='flex gap-4'>
                            <div className="text-2xl font-semibold dark:text-white">Dashboard</div>
                            <div className="tabs_two">
                                <ul className="flex">
                                    <li><Link to="/employee/dashboard" >Summary</Link></li>
                                    <li><Link to="/employee/dashboard/detailed">Detailed</Link></li>
                                    <li><Link to="/employee/dashboard/drilldown" className="active">Drilldown</Link></li>
                                    <li><Link to="/employee/dashboard/geospatial">GeoSpatial</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className='flex gap-4'>
                            <div className='block'>
                                <label className='text-[12px] font-normal text-[#344054]'>Financial Year</label>
                                <Select placeholder='Financial Year' className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select" />
                            </div>
                            <div className='block'>
                                <label className='text-[12px] font-normal text-[#344054]'>Services</label>
                                <Select placeholder='Services' className="mt-1 my-react-select-container"
                                    classNamePrefix="my-react-select" />
                            </div>
                        </div>
                    </div>



                    <div className='mt-10 flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1  gap-3 mb-10 overallstatus'>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4 items-center'>
                                <i className='icon monitor_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Total </h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>1000 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiresopen_icon'></i>

                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Inquires - Dropped</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>10 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiresaccpt_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Quotation - Accepted</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>700 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiesdecline_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Quotation - Declined</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>10 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquiesclose_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Quotation - Closed</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>800 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon inquieslifeline_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Avg. Inquiry Lifecycle</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>800 </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon revenue_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Revenue</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>$700 M </h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow">
                            <div className='flex gap-4'>
                                <i className='icon income_icon'></i>
                                <div>
                                    <h6 className='text-[#344054] text-[16px] xl:text-[16px] lg:text-[16px] sm:text-[13px] 2xl:text-[16px] font-medium text-left dark:text-white leading-8'>Gross Margin</h6>
                                    <div className="flex gap-2 items-center">
                                        <h5 className='text-[#101828] text-[16px] xl:text-[16px] lg:text-[14px] sm:text-[13px] font-semibold text-left dark:text-white'>$70 M</h5>
                                        <div className='arrowicon text-[#86909C] text-[12px] font-light '>
                                            LP <span className="text-[#00B42A]">
                                                <i className="icon arrow_up_green mr-3 ml-2"> </i> 11.02%</span> Increased
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>


                    <div className='mt-6 bg-white dark:bg-[#2A2E32] py-4 rounded-lg px-4 boxshadow drilldown'>

                        <div className='flex justify-end'>
                            <div className='flex gap-4'>
                                <div className='block'>
                                    <label className='text-[12px] font-normal text-[#344054]'>
                                        Select Metric</label>
                                    <Select placeholder='Quotation - Accepted' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                                <div className='block'>
                                    <label className='text-[12px] font-normal text-[#344054]'>Level 1</label>
                                    <Select placeholder='Services' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                                <div className='block'>
                                    <label className='text-[12px] font-normal text-[#344054]'>Level 2</label>
                                    <Select placeholder='Port of Origin' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                                <div className='block'>
                                    <label className='text-[12px] font-normal text-[#344054]'>Level 3</label>
                                    <Select placeholder='Port of Destination' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                                <div className='block'>
                                    <label className='text-[12px] font-normal text-[#344054]'>Level 4</label>
                                    <Select placeholder='Freight Forwarder' className="mt-1 my-react-select-container"
                                        classNamePrefix="my-react-select" />
                                </div>
                            </div>
                        </div>

                        <div className="card h-60 mb-2">
                            <ReactEChart option={option} />
                        </div>
                    </div>












                </div>
            </div>

        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}