import { reactLocalStorage } from 'reactjs-localstorage';
import { USER_TYPES } from './enum';


function ValidateEmployeeRole() {
    if (reactLocalStorage.get('loggedUserRole') === USER_TYPES.Employee) {
        return true;
    }
    else {
        return false;
    }
}

export { ValidateEmployeeRole };