import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import imageLogo from '../../assets/svg/small-logo.svg';


import EmpLogo from '../../assets/images/Employee_1x.png';
import RoleLogo from '../../assets/images/Role_1x.png';
import FinancialYearLogo from '../../assets/images/Financial_Year_1x.png';
import CustomerLogo from '../../assets/images/customer1x.png';
import CountryLogo from '../../assets/images/Countries_1x.png';
import RegionLogo from '../../assets/images/Regions_1x.png';
import FrightForwardLogo from '../../assets/images/Company 1x.png';
import SalesPerson from '../../assets/images/customerGroup1x.png';

export default function Settings() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [openTab, setOpenTab] = useState(1)
    const [searchText, setSearchedText] = useState("")

    //Master List
    const masterList = [
        // { key: 1, title: "Employees", navigateTo: "/employee/settings/employee", icon: "icon settings_icon" },
        { key: 1, title: "Employees", navigateTo: "/employee/settings/employee", icon: EmpLogo },
        { key: 2, title: "Roles", navigateTo: "/employee/settings/role", icon: RoleLogo },
        { key: 3, title: "Financial Years", navigateTo: "/employee/settings/financialyear", icon: FinancialYearLogo },
        { key: 4, title: "Customers", navigateTo: "/employee/settings/customers", icon: CustomerLogo },
        { key: 5, title: "Fright Forward", navigateTo: "/employee/settings/frightforward", icon: FrightForwardLogo },
        { key: 6, title: "Sales Person", navigateTo: "/employee/settings/salesperson", icon: SalesPerson },
        { key: 7, title: "Country", navigateTo: "/employee/settings/country", icon: CountryLogo },
        { key: 7, title: "States", navigateTo: "/employee/settings/states", icon: RegionLogo },

    ];

    //Inquiry List
    const inquiryList = [
        { key: 1, title: "Services", navigateTo: "/employee/settings/services", icon: imageLogo },
        { key: 2, title: "Inquiry Port", navigateTo: "/employee/settings/inquiryports", icon: RegionLogo },
        { key: 3, title: "Cargo Type", navigateTo: "/employee/settings/cargotypes", icon: imageLogo },
        { key: 4, title: "Containers", navigateTo: "/employee/settings/container", icon: imageLogo },
        { key: 5, title: "Products", navigateTo: "/employee/settings/products", icon: imageLogo },
        { key: 6, title: "Movement", navigateTo: "/employee/settings/movement", icon: imageLogo },
        { key: 7, title: "Stuffings", navigateTo: "/employee/settings/stuffings", icon: imageLogo },
        { key: 8, title: "Currency", navigateTo: "/employee/settings/currency", icon: imageLogo },
        { key: 9, title: "Freight Forwarder Question", navigateTo: "/employee/settings/freightforwarderquestion", icon: imageLogo },
        { key: 10, title: "Units", navigateTo: "/employee/settings/units", icon: imageLogo },
        { key: 11, title: "Inco Terms", navigateTo: "/employee/settings/incoterms", icon: imageLogo },
    ];

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }





    useEffect(() => {
        onLoad();
        const selectedTab = localStorage.getItem('selectedTab')
        if (selectedTab) { setOpenTab(parseInt(selectedTab)) }
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="pl-16 dashboard-main-wrapper setting-wrapper" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="items-center space-x-8 sm:flex">
                        <div className="text-2xl font-semibold dark:text-white">Settings</div>
                    </div>
                    <div className="justify-between my-6 lg:flex">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link onClick={() => { localStorage.setItem("selectedTab", "1"); setOpenTab(1) }} className={openTab == 1 ? "active" : ""}>Masters</Link></li>
                                <li><Link onClick={() => { localStorage.setItem("selectedTab", "2"); setOpenTab(2) }} className={openTab == 2 ? "active" : ""}>Inquiry</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className="flex grid flex-row flex-wrap flex-1 gap-4 mb-5 align-center 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 setting-wrapper"
                    >
                        {
                            openTab === 1 &&
                            masterList.map(v => (
                                <Models key={v.title} value={v.title} navigateTo={v.navigateTo} highlight={searchText} icons={v.icon} tabValue={1} />
                            ))

                        }
                        {
                            openTab === 2 &&
                            inquiryList.map(v => (
                                <Models key={v.title} value={v.title} navigateTo={v.navigateTo} highlight={searchText} icons={v.icon} tabValue={1} />
                            ))

                        }

                    </div>
                </div>
            </div>
            <div id="selector"></div>


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}

const Models = ({ highlight, value, navigateTo, icons, tabValue }) => {
    return <div className="bg-[#fff] dark:bg-[#2A2E32] p-5 px-6 shadowisetting rounded-lg ">
        <Link onClick={() => { localStorage.setItem('tab', tabValue); }} to={navigateTo}>
            <div className="flex items-center justify-between">
                <h3 className="text-[16px] text-[#101828] dark:text-white font-medium">{getHighlightedText(value, highlight)}</h3>
                <img src={icons} alt="" style={{ height: '40px', width: '38px' }} />
            </div>
        </Link>
    </div>
    //return <Link to={navigateTo} className="col-span-2 -w-full py-10 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[25px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md" >{getHighlightedText(value, higlight)}</Link>;
};

function getHighlightedText(text, highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    var parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part.toLowerCase() === highlight.toLowerCase() ? (
                <b style={{ backgroundColor: "#029046" }}>{part}</b>
            ) : (
                part
            )}
        </React.Fragment>
    ));
}