
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ConvertResponseForSelect } from '../../../../../components/helper/commonfunctions'
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { State,Country } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import Select from 'react-select'


export default function EmployeeCountryCreate() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editStateId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [stateId, setStateId] = useState("");
    let navigateTo = useNavigate();
    const [state, setState] = useState("");
    const [countryList,setCountryList] = useState([]);
    const [countryName,setCountryName] = useState("");
    const [countryIds,setCountryId] = useState("");

    //Create Record
    const saveRole = async () => {
        if (state === "") {
            toast.error("Please enter Role.");
            return;
        }

        setPleaseWaitButton(true);
        if (isEditMode) {
            const original = await DataStore.query(State, stateId);
            await DataStore.save(
                Country.copyOf(original, (updated) => {
                    updated.name = capitalizeFirst(state);
                    updated.countryId =countryIds;
                })
            );
            toast.success("Updated");
        } else {
            await DataStore.save(new State({ name: capitalizeFirst(state),countryId:countryIds }));
            toast.success("Added");
        }

        setPleaseWaitButton(false);
        navigateTo("/employee/settings/states");
    };

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editStateId != null) {
                setStateId(editStateId);
                const original = await DataStore.query(State, editStateId);
                if (original != null) {
                    const Country_original = await DataStore.query(Country, original.countryId);
                    setCountryName({ label: Country_original.name, value: Country_original.id })
                    setCountryId(Country_original.id);
                    setState(original.name);
                    setEditMode(true);
                   
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/employee/settings/states");
                }
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    
    async function BindList() {
        var CountryList = await DataStore.query(Country, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myCountryArray = []; myCountryArray = ConvertResponseForSelect(CountryList);
        setCountryList(myCountryArray)
    }
   

    useEffect(() => {
        onLoad();
        BindList();
        console.log(countryIds);
    }, []);

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />
                <div className="pl-16">
                    <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white dark:bg-[#2A2E32] rounded-md shadow ">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} State
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                        
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                                <div className="relative mb-4 ">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium"
                                                    >
                                                        State
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                    value={(state)}
                                                    onChange={(e) => setState(e.target.value)}
                                                    type="text"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter State"
                                                />
                                                </div>

                                                <div className="relative mb-4">
                                                    <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                                        Country <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                            * </label></label>
                                                    <Select placeholder='Select' options={countryList} value={countryName} onChange={e => {
                                                        setCountryName(e)
                                                        setCountryId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                            </div>
                                        {/* </div> */}
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link
                                                to="/employee/settings/states"
                                                className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#4A5056] dark:text-white"
                                            >
                                                Cancel
                                            </Link>
                                            <button
                                                onClick={() => saveRole()}
                                                className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                            >
                                                {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}
