import { React, useEffect, useState, useRef } from 'react';
import logoLanding from '../../assets/images/pro_connect_logo_red.png';
import { Link, useParams } from "react-router-dom";
import { InquiryBid, BidStatus, Inquiry, FreightForwarderQuestion, InquiryBidRequestedDetails, InquiryContainers, ContainerType, InquiryBidContainerDetails, InquiryServices, InquiryPort, MovementType, Products, Units, InquiryCargoType } from '../../models';
import { DataStore } from '@aws-amplify/datastore';
import { INQUIRY_INFORMATION_FIELD_NAMES } from "../../components/helper/enum";
import AWS from 'aws-sdk'
import { GetAWSObjectURL } from '../../components/helper/aws';
import moment from "moment";
import * as alertify from 'alertifyjs';
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';



export default function InquiryAmount() {

    const { inquiryBidId } = useParams();
    const [bidStatus, setBidStatus] = useState("");
    const [emailDescription, setEmailDescription] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [inquiryNumber, setInquiryNumber] = useState("");
    const [inquiryClosingDate, setInquiryClosingDate] = useState("");
    const [questions, setQuestions] = useState([]);
    const [inquiryContainer, setInquiryContainer] = useState([]);
    const [inquiryBidDetail, setInquiryBidDetail] = useState([]);
    const [inquiryBidStatus, setInquiryBidStatus] = useState("INVITED");
    const [docUploadedFileList, setDocUploadedFileList] = useState([]);
    const [submitDetail, setSubmitDetail] = useState(0)
    const [inquiryId, setInquiryId] = useState("")
    const [attachmentId, setAttachMentId] = useState("");
    const [Count, setCount] = useState(0);
    const [inquiryInformationField, setInquiryInformationField] = useState([])
    const requestorDetailRef = useRef(null);
    const containerDetailersRef = useRef(null);
    const [isPageLoaded, setPageLoaded] = useState(false);




    // Submit bid
    const submitBid = async () => {

        alertify.confirm('Confirmation', 'Do you want to Submit this Bid?', async function () {
            const original = await DataStore.query(InquiryBid, inquiryBidId);
            if (bidStatus === "") {
                toast.error("Please Select Status");
                return;
            }

            if (bidStatus === "CONFIRMED") {

                if (requestorDetailRef.current.value === "") {
                    toast.error("please Enter Requested Details")
                    return;
                } else if (containerDetailersRef.current.value === "") {
                    toast.error("Please Enter Amount")
                    return;
                } else {

                    console.log("save");

                    for (var i = 0; i < questions.length; i++) {

                        await DataStore.save(new InquiryBidRequestedDetails({
                            "inquiryBid": inquiryBidId,
                            "freightForwarderQuestionId": questions[i]["questionId"],
                            "response": questions[i]["values"]
                        }));
                    }
                    console.log(inquiryContainer);
                    for (var i = 0; i < inquiryContainer.length; i++) {

                        await DataStore.save(new InquiryBidContainerDetails({
                            "inquiryBid": inquiryBidId,
                            "containerId": inquiryContainer[i].containerId,
                            "amount": parseInt(inquiryContainer[i]["amount"])
                        }));
                    }
                }
            }
            await DataStore.save(
                InquiryBid.copyOf(original, (updated) => {
                    updated.bidStatus = bidStatus;
                    updated.bidDate = moment().format('YYYY-MM-DD');

                })
            );
            toast.success("Bid Submitted")
            setSubmitDetail(1)
        }
            , function () { });

    };

    const BindList = async () => {

        const InquiryBidDetails = await DataStore.query(InquiryBid, inquiryBidId);

        if (InquiryBidDetails) {

            setEmailDescription(InquiryBidDetails.emailDescription);
            setEmailSubject(InquiryBidDetails.emailSubject);
            setInquiryBidDetail(InquiryBidDetails);
            setInquiryBidStatus(InquiryBidDetails.bidStatus);
            setInquiryId(InquiryBidDetails.inquiryId);
            setAttachMentId(InquiryBidDetails.attachmentId);


            const questionsId = InquiryBidDetails.requesterInformation;

            const newSS = JSON.parse(JSON.stringify(questionsId));


            const InquiryDetails = await DataStore.query(Inquiry, InquiryBidDetails.inquiryId);
            var questionCount = 0;
            if (questionsId)
                questionCount = Object.keys(questionsId).length;

            let questionArray = []

            for (var i = 0; i < questionCount; i++) {
                const requestInformation = await DataStore.query(FreightForwarderQuestion, questionsId[i]);

                if (requestInformation) {

                    var data = [];
                    data = {
                        "question": requestInformation.question,
                        "questionId": requestInformation.id,
                        "values": "",
                        "Index": i
                    }
                    questionArray.push(data);
                }
                setQuestions(questionArray)
            }

            setInquiryNumber(InquiryDetails.inquiryNo);
            setInquiryClosingDate(InquiryDetails.inquiryClosingDate);

            const InquiryId = InquiryBidDetails.inquiryId;

            const InquiryContainerDetails = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(InquiryId));

            const InquiryContainerArray = []



            for (var i = 0; i < InquiryContainerDetails.length; i++) {
                const containerTypeDetail = await DataStore.query(ContainerType, InquiryContainerDetails[i].containerTypeId);
                var inquirySignDetails = await DataStore.query(Units, InquiryContainerDetails[i].dimentionId)

                {
                    var data = [];
                    data = {
                        "count": InquiryContainerDetails[i].count,
                        "containerId": InquiryContainerDetails[i].id,
                        "containerTypeId": containerTypeDetail.name,
                        "size": (InquiryContainerDetails[i].length) ? InquiryContainerDetails[i].length + '*' + InquiryContainerDetails[i].width + '*' + InquiryContainerDetails[i].height + " " + inquirySignDetails.sign : '-',
                        "totalWeight": InquiryContainerDetails[i].totalWeight,
                        "amount": 0,
                        "index": i
                    }
                    InquiryContainerArray.push(data);
                }
                setInquiryContainer(InquiryContainerArray)
            }

            const inquiryInformationData = inquiryBidDetail.inquiryInformation;

            let newInquiryInformationData = 0;
            if (!newInquiryInformationData) {
                newInquiryInformationData = Object.keys(inquiryInformationData).length;

            }
            let inquiryInformationFieldNameArray = [];

            for (var i = 0; i < newInquiryInformationData; i++) {
                var title = '-';
                var value = '-';
                if (inquiryInformationData[i] == "O") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.O;

                    const original = await DataStore.query(InquiryPort, InquiryDetails.inquiryOriginPort);
                    value = original.name
                } else if (inquiryInformationData[i] == "D") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.D
                    const original = await DataStore.query(InquiryPort, InquiryDetails.inquiryDestinationPort);
                    value = original.name
                } else if (inquiryInformationData[i] == "S") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.S
                    const original = await DataStore.query(InquiryServices, InquiryDetails.inquiryServiceId);
                    value = original.name
                } else if (inquiryInformationData[i] == "C") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.C
                    const original = await DataStore.query(InquiryCargoType, InquiryDetails.inquiryCargoTypeId);
                    value = original ? original.name : ""
                } else if (inquiryInformationData[i] == "M") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.M
                    const original = await DataStore.query(MovementType, InquiryDetails.movementTypeId);
                    value = original ? original.name : ""
                } else if (inquiryInformationData[i] == "P") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.P
                    const original = await DataStore.query(Products, InquiryDetails.productId);
                    value = original ? original.name : ""
                } else if (inquiryInformationData[i] == "CD") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.CD
                } else if (inquiryInformationData[i] == "OI") {
                    title = INQUIRY_INFORMATION_FIELD_NAMES.OI
                }


                var data = [];
                data = {
                    "title": title,
                    "index": i + 1,
                    "value": value
                }
                inquiryInformationFieldNameArray.push(data);
            }
            console.log("inquiry", inquiryInformationFieldNameArray);
            setInquiryInformationField(inquiryInformationFieldNameArray);
            setPageLoaded(true)
        }


    }

    const handleChange = (event) => {
        setBidStatus(event.target.value)

    }

    const changeValue = (v, I) => {
        const array = [...questions];
        array[I]["values"] = v
        setQuestions(array)
    }

    const amountChange = (v, I) => {
        const value = (v);
        const array = [...inquiryContainer];
        array[I]["amount"] = value
        setInquiryContainer(array)
        sumValue()
    }

    let total = 0;
    const sumValue = () => {
        inquiryContainer.forEach((item) => (

            total += parseFloat(item.amount)
        ))
        setCount(total.toFixed(2))
    }

    let finalFilesArray = [];
    const getNotesAttachment = async () => {
        const fileName = 'public/inquiry/' + inquiryId + '/bids/' + attachmentId + '/';
        let filesData = [];
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
            signatureVersion: 'v4',
            region: process.env.REACT_APP_AWS_Region,
            secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey
        });
        const params = {
            Bucket: process.env.REACT_APP_AWS_Bucket,
            Delimiter: '/',
            Prefix: fileName
        };


        s3.listObjects(params, async function (err, data) {
            if (err) {
                //return 'There was an error viewing your album: ' + err.message
            } else {
                //   //return data.Contents;
                data.Contents.forEach(async function (obj, index) {
                    if (obj.Key !== '') {
                        const filename = obj.Key;
                        let basename = filename.split('/');
                        let filePath = await GetAWSObjectURL(filename)
                        basename = basename[basename.length - 1]
                        finalFilesArray.push({ filename: basename, path: filePath })
                    }

                })
            }
        })

        setDocUploadedFileList(finalFilesArray)
        setDocUploadedFileList(finalFilesArray)
    }




    useEffect(() => {
        BindList();
        getNotesAttachment()
    }, [inquiryId]);

    return (

        <div className="pt-12">



            <div className='flex  justify-center mb-4'>
                <img className="w-48" src={logoLanding} alt="" />
            </div>
            {
                isPageLoaded === true ?

                    <div>

                        {
                            submitDetail == 1 ?
                                <div className='flex justify-center mb-4 '>
                                    <div className='border flex    justify-center mb-4 items-center shadow shadow-lg' style={{ height: "50vh", width: "70vw" }}>

                                        <div className=' h-full items-center flex justify-center '>
                                            <p className='text-5xl '> Bid Submit Succesfully.</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    {

                                        inquiryBidStatus != "INVITED" ?
                                            <div className='flex justify-center mb-4 '>
                                                <div className='border flex    justify-center mb-4 items-center shadow shadow-lg' style={{ height: "50vh", width: "70vw" }}>

                                                    <div className=' h-full items-center flex justify-center '>
                                                        <p className='text-5xl '> You have already submitted Bid details.  </p>
                                                    </div>
                                                </div>
                                            </div>

                                            :


                                            <div
                                                className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                                                <div className="col-span-4 col-start-4 lg:col-start-4 xl:col-start-4 2xl:col-start-3">
                                                    <div className="px-5 py-5 bg-white rounded-lg shadow-lg dark:bg-[#252423]">
                                                        <div className='flex justify-end'>

                                                        </div>
                                                        <div className='grid grid-cols-2 gap-5 w-full  '>
                                                            <div className='flex justify-between  col-span-2'>
                                                                <div className='block gap-3'>
                                                                    <label className='font-light mb-1 text-[15px] text-[#667085]'>Inquiry Number</label>
                                                                    <h4 className='font-medium mb-1 text-[16px] text-[#101828]'>{inquiryNumber}</h4>
                                                                </div>
                                                                <div className='block  gap-3'>
                                                                    <label className='font-light mb-1 text-[15px] text-[#667085]'>Closing Date</label>
                                                                    <h4 className='font-medium mb-1 text-[16px] text-[#101828]'>{moment(inquiryClosingDate).format('MM/DD/YYYY')}</h4>
                                                                </div>
                                                            </div>

                                                            <div className='block col-span-2'>
                                                                <label className='font-light mb-1 text-[15px] text-[#667085]'>Subject</label>
                                                                <h4 className='font-medium mb-1 text-[16px] text-[#101828]'>{emailSubject}</h4>
                                                            </div>
                                                            <div className='block col-span-2'>
                                                                <label className='font-light mb-1 text-[15px] text-[#667085]'>Description</label>
                                                                <h4 className='font-medium  mb-5 text-[16px] text-[#101828]'>{emailDescription}</h4>
                                                            </div>
                                                        </div>

                                                        {
                                                            inquiryInformationField != "" ?

                                                                <div className='mt-3'>
                                                                    <div className='border px-3 py-2 shadow-md  rounded-t-lg'>
                                                                        <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Inquiry Info</h3>
                                                                    </div>
                                                                    <div className='border shadow-md  '>
                                                                        <div className='p-3 bg-[#fff] grid grid-cols-3 dark:bg-[#252423]  dark:border-[#252423]  flex gap-3'>
                                                                            <div className='w-32'>Sr.No.</div>
                                                                            <div className='w-40'>
                                                                                <label>Title</label>
                                                                            </div>
                                                                            <div className=''>
                                                                                <label>Information</label>
                                                                            </div>
                                                                        </div>
                                                                        {

                                                                            inquiryInformationField.map((elm) => {
                                                                                return (
                                                                                    <div>
                                                                                        <div className='p-3 grid grid-cols-3 bg-[#F9FAFB] dark:bg-[#252423] items-center dark:border-[#252423]  flex  gap-3'>
                                                                                            <div className='w-32 text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white'>{elm.index}</div>
                                                                                            <div className='w-40'>
                                                                                                <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex  items-center font-medium dark:text-white">{elm.title}</label>
                                                                                            </div>
                                                                                            <div className=' '>
                                                                                                <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium  items-center font-medium dark:text-white">{elm.value}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })





                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                null

                                                        }
                                                        {
                                                            questions != "" ?
                                                                <div className='mt-5'>
                                                                    <div className='border px-3 py-2 shadow-md  rounded-t-lg'>
                                                                        <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>
                                                                            Requested Details</h3>
                                                                    </div>
                                                                    <div className=' shadow-md  '>

                                                                        {questions.map((e) => {
                                                                            return (


                                                                                <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423] col-span-2  flex  gap-3 items-center'>
                                                                                    <div className='w-56'>
                                                                                        <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">
                                                                                            {e.question}
                                                                                        </label>
                                                                                    </div>

                                                                                    <div className='w-full'>
                                                                                        <input type="text" id="" ref={requestorDetailRef} name="containerDetailers" onChange={(event) => changeValue(event.target.value, e.Index)} className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] " />
                                                                                    </div>

                                                                                </div>


                                                                            )
                                                                        })

                                                                        }
                                                                    </div>
                                                                </div>

                                                                : null
                                                        }

                                                        <div className='mt-5'>
                                                            <div className='border px-3 py-2 shadow-md  rounded-t-lg'>
                                                                <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>
                                                                    Container Details</h3>
                                                            </div>
                                                            <div className='border shadow-md  '>
                                                                <div className='p-3 bg-[#fff] dark:bg-[#252423]  dark:border-[#252423]  flex gap-3 justify-center text-center'>
                                                                    <div className='w-32'>Count</div>
                                                                    <div className='w-32'>
                                                                        <label>Type</label>
                                                                    </div>
                                                                    <div className='w-32 mr-5'>
                                                                        <label>Size</label>
                                                                    </div>
                                                                    <div className='w-32 mr-12'>
                                                                        <label>Weight</label>
                                                                    </div>
                                                                    <div className='w-40'>
                                                                        <label>Amount</label>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    inquiryContainer.map((elm) => {
                                                                        return (
                                                                            <div >
                                                                                <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex  gap-3 items-center'>
                                                                                    <div className='w-32 text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white justify-center'>{elm.count}</div>
                                                                                    <div className='w-40'>
                                                                                        <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">{elm.containerTypeId}</label>
                                                                                    </div>
                                                                                    <div className='w-40'>
                                                                                        <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">{elm.size}</label>
                                                                                    </div>
                                                                                    <div className='w-40 text-center'>
                                                                                        <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white justify-center">{elm.totalWeight}</label>
                                                                                    </div>
                                                                                    <div className="flex flex-wrap  mb-4 w-56 rounded-lg relative">
                                                                                        <div className="flex -mr-px">
                                                                                            <span className="flex items-center leading-normal bg-white rounded rounded-md border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">$</span>
                                                                                        </div>

                                                                                        <input ref={containerDetailersRef} onChange={(e) => amountChange(e.target.value, elm.index)} type="number" className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light px-3 relative rounded-md " placeholder="0.00" />

                                                                                    </div>

                                                                                </div>
                                                                            </div>



                                                                        )
                                                                    })

                                                                }
                                                                <div className='flex justify-end items-center p-3 gap-3'>
                                                                    <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center dark:text-white">Total</label>
                                                                    <div className='w-38'>

                                                                        <input type="Number" value={Count} onChange={(e) => e.target.value} id="" className="text-[14px] rounded-lg   border border-[#D0D5DD]  w-28 py-1.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] " />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            docUploadedFileList == 0 ? "" :
                                                                <div>
                                                                    <div className='mt-5'>
                                                                        <div className='border px-3 py-2 shadow-md  rounded-t-lg'>
                                                                            <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>
                                                                                Attachments</h3>
                                                                        </div>
                                                                        <div className='border shadow-md  '>
                                                                            <div className='p-3 grid grid-cols-2 gap-4 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex  gap-3 items-center'>

                                                                                {
                                                                                    docUploadedFileList.map((elm) => {
                                                                                        return (
                                                                                            <div className='className=p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex  gap-3 items-center'>
                                                                                                <div className='w-8 text-center'>
                                                                                                    <i className="pi pi-file"></i>
                                                                                                </div>
                                                                                                <div className='w-40' >

                                                                                                    <h3 onClick={() => window.location.href = elm.path} className='cursor-pointer text-[#6390ea] dark:text-[#ffff] text-[14px] font-medium '>
                                                                                                        {elm.filename}</h3>
                                                                                                </div>
                                                                                            </div>


                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        }
                                                        <div className='mt-5'>

                                                            <div className='border shadow-md  '>
                                                                <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex  gap-3 items-center'>


                                                                    <div className='grid grid-cols-2 gap-4 place-content-evenly'>
                                                                        <div className='flex '>
                                                                            <input type="radio" name='radio' value={BidStatus.CONFIRMED} onChange={handleChange} />
                                                                            <p className='ml-3 cursor-pointer'  >Accept</p>
                                                                        </div>
                                                                        <div className='flex '  >
                                                                            <input type="radio" name='radio' value={BidStatus.DECLINED} onChange={handleChange} />
                                                                            <p className='ml-3 cursor-pointer' >Decline</p>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div className='mt-5 text-center'>
                                                            <Link onClick={() => submitBid()} className="bg-[#029046] text-white rounded-lg px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block">Submit</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>

                        }

                    </div> :
                    <div className='text-center py-8' ><ProgressSpinner /></div>

            }
        </div>
    )

}