import React from 'react'
import LeftSideBar from '../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../components/common/employee/topnav.component';
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Column } from 'primereact/column';



export const CreateReport = () => {

    const reportList = [
        
                {
                    'Inquiry ID': '112233', 'Inquiry Date': '12/23/2022', 'Services': 'Port Service', 'Inquiry Source': 'Website', 'Sales Person': 'Naveen', 'Customer Name': 'Naveen', 'Cargo Type': 'Container', 'Origin Port': 'India', 'Origin Country': 'India',
                    'Destination Country': 'Sri Lanka', 'Destination Port': 'Sri Lanka', 'Bids Invited': '15', 'Bids Received': '10','Quote Submitted Date':'12/25/2022','Customer Response Date ':'12/25/2022','confirm Freight Forword':'Freight Forword','status':"open",'Incuiry Lifecycle Time':'-','Revenue($)':'$xxM','GM($)':'$XXm'
                },
                {
                    'Inquiry ID': '112233', 'Inquiry Date': '12/23/2022', 'Services': 'Port Service', 'Inquiry Source': 'Website', 'Sales Person': 'Naveen', 'Customer Name': 'Naveen', 'Cargo Type': 'Container', 'Origin Port': 'India', 'Origin Country': 'India',
                    'Destination Country': 'Sri Lanka', 'Destination Port': 'Sri Lanka', 'Bids Invited': '15', 'Bids Received': '10','Quote Submitted Date':'12/25/2022','Customer Response Date ':'12/25/2022','confirm Freight Forword':'Freight Forword','status':"open",'Incuiry Lifecycle Time':'-','Revenue($)':'$xxM','GM($)':'$XXm'
                },
                {
                    'Inquiry ID': '112233', 'Inquiry Date': '12/23/2022', 'Services': 'Port Service', 'Inquiry Source': 'Website', 'Sales Person': 'Naveen', 'Customer Name': 'Naveen', 'Cargo Type': 'Container', 'Origin Port': 'India', 'Origin Country': 'India',
                    'Destination Country': 'Sri Lanka', 'Destination Port': 'Sri Lanka', 'Bids Invited': '15', 'Bids Received': '10','Quote Submitted Date':'12/25/2022','Customer Response Date ':'12/25/2022','confirm Freight Forword':'Freight Forword','status':"open",'Incuiry Lifecycle Time':'-','Revenue($)':'$xxM','GM($)':'$XXm'
                },
        
        
    ]

    

  return (
    <div className="pt-16">
    <TopNavBar />
    <LeftSideBar />
    <div className=" target-main-wrapper dashboard-main-wrapper dashboard-new pl-16" x-data="{tab: 1}">
                <div className="p-5 justify-between pb-0">
                    <div className="sm:flex justify-between  items-center space-x-8 mb-5">
                       <div className=' flex items-center ' >
                        <Link to="/employee/report" className="text-[#344054] text-[14px] dark:text-white py-2 px-3 rounded-md border bg-[white] border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                        <div className="text-2xl ml-3 font-semibold dark:text-white">Report</div>
                        </div>
                        <div className="justify-end">

                        <div className="relative">
                                <input  type="text" id="required-search" className=" rounded-lg border border-[#D0D5DD] focus:border-transparent w-250 py-2.5 pr-5 pl-9 bg-white text-gray-700 placeholder-gray-500 text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 dark:bg-[#2A2E32] dark:text-[#FFFFFF] dark:border-[#4E5668] dark:placeholder-[#ffff]" placeholder="Search..." />
                                <button type="submit" className="absolute left-3 top-5 translate-y-[-50%] dark ">
                                    <i className="icon search_icon "></i>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="bg-white rounded-lg mt-2 p-3 px-3 dark:bg-[#2A2E32] mt-5  border border-[#E4E7EC] dark:border-[#344054] dark:text-white  mb-10 pb-10 ">
                        <div className='flex justify-between'> Report

                        <div className='flex gap-4 justify-between items=center mr-2 px-4'>
                        <div className='flex gap-4 flex-right'>
                            <Link to='' className='flex justify-end w-20 flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-print'></i> Print</Link>
                            <Link to='' className='flex justify-end w-34  flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-lg  px-3 py-1.5 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                <i className='pi pi-download'></i> Download</Link>
                        </div>
                    </div>

                        </div>

                 <div className=" rounded-lg mt-5">
                            <DataTable paginator value={reportList} responsiveLayout="scroll"
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10, 20, 50]}
                            >
                                <Column field="Inquiry ID" header="Inquiry ID" filter></Column>
                                <Column field="Inquiry Date" header="Inquiry Date" sortable={true}></Column>
                                <Column field="Services" header="Services" sortable={true}></Column>
                                <Column field="Inquiry Source" header="Inquiry Source" sortable={true}></Column>
                                <Column field="Sales Person" header="Sales Person" sortable={true}></Column>
                                <Column field="Customer Name" header="Customer Name" sortable={true}></Column>
                                <Column field="Cargo Type" header="Cargo Type" sortable={true}></Column>
                                <Column field='Origin Port' header="Origin Port" sortable={true}></Column>
                                <Column field='Origin Country' header="Origin Country" sortable={true}></Column>
                                <Column field='Destination Country' header="Destination Country" sortable={true}></Column>
                                <Column field='Destination Port' header="Destination Port" sortable={true}></Column>
                                <Column field='Bids Invited' header="Bids Invited" sortable={true}></Column>
                                <Column field='Bids Received' header="Bids Received" filter></Column>
                                <Column field='Quote Submitted Date' header="Quote Submitted Date" filter></Column>
                                <Column field='Customer Response Date ' header="Customer Response Date" filter></Column>
                                <Column field='confirm Freight Forword' header="confirm Freight Forword" filter></Column>
                                <Column field='status' header="status" filter></Column>
                                <Column field='Incuiry Lifecycle Time' header="Incuiry Lifecycle Time" filter></Column>
                                
                                <Column field='Revenue($)' header="Revenue($)" filter></Column>
                                <Column field='GM($)' header="GM($)" filter></Column>



                                
                                 
                            </DataTable>
                        </div>

                        </div>
                        </div>
                        </div>


   

    <div id="selector"></div>


</div>
  )
}
