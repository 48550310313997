import { DataStore } from '@aws-amplify/datastore';
import { FreightForwarder, InquiryBid, Inquiry, Customer, Units, ContainerType, InquiryContainers, InquiryBidContainerDetails } from '../../../models';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import "../../../proConnect.css";
import Bussiness from "../../../assets/svg/connectpro/business-people-casual-meeting 1.png"
import { Menu } from "@headlessui/react";
import cargo_icon from "../../../assets/svg/connectpro/cragodetail_icon.svg"
import attach_icon from "../../../assets/svg/connectpro/attach_icon.svg"
import { toast } from 'react-toastify';
import UserImg from "../../../assets/images/user5.png";
import FreightForwardIcon from "../../../assets/svg/connectpro/freightforwordicon.svg";
import { reactLocalStorage } from 'reactjs-localstorage';
import { callMsGraph, loginRequest, graphConfig } from "../../../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import moment from 'moment';


export const CustomerQuotationPopup = (props) => {


    //Validate User Logged In
    const { inquiryId } = useParams();

    const [inquiryContainerDetails, setInquiryContainerDetails] = useState([]);
    const isMicrosoftAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const [subject, setSubject] = useState("");
    const [dateOfEnquiry, setDateOfEnquiry] = useState("");


    async function BindList() {

        var inquiryContainers = await DataStore.query(Inquiry, (c) => c.id.eq(inquiryId))

        var customerData = await DataStore.query(Customer, (c) => c.id.eq(inquiryContainers[0].customerId));

        var freightForwarderArray = await DataStore.query(FreightForwarder, (c) => c.id.eq(props.FreightForwardId));

        let data = [
            {
                customerName: customerData[0].firstName + ' ' + customerData[0].lastName,
                customerEmail: customerData[0].email,
                freightForwarderName: freightForwarderArray[0].name
            }
        ];

        setInquiryContainerDetails(data)
    }



    //Compare Count 
    async function saveConfirmBid() {

        var inquiryContainerData = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId));

        var InquiryBidContainerDetail = await DataStore.query(InquiryBidContainerDetails, (c) => c.inquiryBid.eq(props.bidsId));

        let mailTableData = [];
        for (var i = 0; i < inquiryContainerData.length; i++) {
            var containerTypeData = await DataStore.query(ContainerType, (c) => c.id.eq(inquiryContainerData[i].containerTypeId))
            var containerUnitData = await DataStore.query(Units, (c) => c.id.eq(inquiryContainerData[i].unitI))
            var inquirySignDetails = await DataStore.query(Units, inquiryContainerData[i].dimentionId)

            let data = {
                count: inquiryContainerData[i].count,
                type: containerTypeData[0].name,
                size: (inquiryContainerData[i].length) ? inquiryContainerData[i].length + '*' + inquiryContainerData[i].width + '*' + inquiryContainerData[i].height + " " + inquirySignDetails.sign : '-',
                totalWeight: inquiryContainerData[i].totalWeight + ' ' + containerUnitData[0].name,
                amount: "$" + InquiryBidContainerDetail[i].amount,
            }
            mailTableData.push(data);
        }

        const original = await DataStore.query(InquiryBid, props.bidsId);

        await DataStore.save(
            InquiryBid.copyOf(original, (updated) => {
                updated.isApproved = true;
                updated.approvedDat = moment().format("YYYY-MM-DD") + 'T' + moment().format("HH:mm:ss") + '.999Z';
            })
        ).then((response) => {

            var htmlTmp1 = '<p style="text-align:center"><span style="font-size:22px">Bid</span></p><p><span style="font-size:18px">Hello ' + inquiryContainerDetails[0].customerName + ' ,</span></p><p>&nbsp;</p><p>Please find below inquiry.</p><p>&nbsp;</p><h3>Container Details</h3><table  border="1" cellpadding="1" cellspacing="1" style="width:500px"><tbody><tr><td style="border-bottom:1px solid #000000; text-align:left;">Sr No.</td><td style="border-bottom:1px solid #000000; text-align:left;">Count</td><td style="border-bottom:1px solid #000000; text-align:left">Type</td><td style="border-bottom:1px solid #000000; text-align:left">Size</td><td style="border-bottom:1px solid #000000; text-align:left">Weight</td><td style="border-bottom:1px solid #000000; text-align:left">Price</td></tr>';

            mailTableData.map((item, i) => {
                htmlTmp1 += `<tr>
                    <td style="text-align:left">${i + 1}</td>
                    <td style="text-align:left">${item.count}</td>
                    <td style="text-align:left">${item.type}</td>
                    <td style="text-align:left">${item.size}</td>
                    <td style="text-align:left">${item.totalWeight}</td>
                    <td style="text-align:left">${item.amount}</td>
                    </tr>`;
            })

            htmlTmp1 += '</tbody ></table ><p>&nbsp;</p><p>&nbsp;</p><p>Please <a href="https://proconnect.edbrix.info/confirmbid/' + response.id + '">click here</a> to process further.</p><p>Thanks</p><p>ProConnect Team</p>';

            sendEmailToCustomer(htmlTmp1, inquiryContainerDetails[0].customerEmail, subject);

        });

        props.closeQuotationPopup()
        toast.success('Bid confirmed.');
        setDateOfEnquiry(moment(original.approvedDat).toDate())

    }


    async function sendEmailToCustomer(emailTemplate, customerEmail, subject) {

        if (isMicrosoftAuthenticated) {
            var customQueryURL = graphConfig.graphMeSendMessages;
            var requestBody = {
                "message": {
                    "subject": subject,
                    "body": {
                        "contentType": "html",
                        "content": emailTemplate
                    },
                    "toRecipients": [
                        {
                            "emailAddress": {
                                "address": customerEmail
                            }
                        }
                    ],
                }
            };
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                callMsGraph(response.accessToken, customQueryURL, "POST", requestBody).then(async (currentResponse) => {
                });
            });
        }
    }


    useEffect(() => {
        BindList();
    }, []);

    return (
        <div>
            <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                <div >
                    <img src={Bussiness} alt="" style={{ width: "50vw" }} />
                    <div className="absolute right-0 left-0 top-0 w-full text-white">
                        <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                            <div className="text-md"><h6 className='text-[white]'>Bids</h6></div>
                            <div><Link onClick={() => props.closeQuotationPopup()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                        </div>

                        <div className="px-6 mt-8">
                            <span>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div className="flex items-center align-center">
                                        <Menu.Button className='flex items-center'>
                                        </Menu.Button>
                                    </div>
                                </Menu>
                                <div className="text-sm text-[white]">Preview</div>
                                <div className="text-2xl">Customer Quotation
                                </div>
                            </span>
                        </div>

                    </div>

                    <div className="py-2 px-5  height-custtom">
                        <div className='flex'>
                            <div >
                                <img src={UserImg} />
                            </div>
                            {
                                (inquiryContainerDetails.length > 0) ?
                                    <div className='ml-3'>
                                        <h2 className='text-[#101828] text-[20px]' >
                                            {inquiryContainerDetails[0].customerName}
                                        </h2>
                                        <div className='flex justify-center'>
                                            <img className='mb-1' src={FreightForwardIcon} />
                                            <p className='text-[#101828] text-[14px]'>{inquiryContainerDetails[0].freightForwarderName}</p>
                                        </div>
                                    </div> : 'mull'
                            }
                        </div>

                        <div className="mb-5 mt-5">
                            <label>Subject</label>
                            <br />
                            <input onChange={(event) => setSubject(event.target.value)} className='text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]   ' type="text" />
                        </div>

                        <h3 className="mb-2">Description</h3>
                        <p cols="76" row="30" className="border rounded-md h-40 p-2 border-[#C6CBD2] mb-2" >
                            Dear Requestor
                            Thank you so much for your enquiry, please find below our quote.
                            Rates calculated, based on the cargo details mentioned in your enquiry
                        </p>

                        <div className=" rounded-lg border mt-5">
                            <div className="p-3 shadow bg-[#4DB17D] rounded-t-lg ">
                                <p className="text-[#FFFFFF] text-[16px]" >Cargo Details</p>
                            </div>
                            <div className=" mt-3  flex justify-between items-center divide-x">
                                <div className="  p-5">
                                    <img className="mb-3" src={cargo_icon} />
                                    <p className="text-[#01813F] text-[12px] text-center" >View Details</p>
                                </div>
                                <div className="  p-5">
                                    <p className="mb-2 text-[#667085] font-sm text-center text-[14px]">Weight</p>
                                    <p className="text-[#344054] font-medium text-center ">90kg</p>
                                </div>
                                <div className="  p-5 text-center">
                                    <p className=" mb-2 text-[14px]" >Box 1</p>
                                    <p className="text-sm">36kg</p>
                                    <p className="text-xs flex">70cm x 36cm x 39cm</p>
                                </div>
                                <div className="  p-5 text-center">
                                    <p className="mb-2 text-[14px]">Box 2</p>
                                    <p className="text-sm">36kg</p>
                                    <p className="text-xs flex">70cm x 36cm x 39cm</p>
                                </div>
                                <div className="  p-5 text-center">
                                    <p className="mb-2 text-[14px]">Box 3</p>
                                    <p className="text-sm">36kg</p>
                                    <p className="text-xs flex">70cm x 36cm x 39cm</p>
                                </div>
                            </div>
                        </div>
                        <div className="pb-3  mb-2">


                            <div className="border rounded-lg mt-5">
                                <div className="p-3 shadow bg-[#4DB17D] rounded-t-lg ">
                                    <p className="text-[#FFFFFF] text-[16px]" >Rate</p>
                                </div>
                                <div className="p-3 font-medium">BELOW CHARGES FROM DOOR TO DOOR – DG SURCHARGES INCLUDED</div>
                                <div class="flex flex-col p-3">
                                    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div class=" inline-block min-w-full sm:px-6 lg:px-8">
                                            <div class="overflow-hidden">
                                                <table class="min-w-full border text-center">

                                                    <tbody>
                                                        <tr class="border-b">
                                                            <td rowSpan="2" class="px-3 text-[14px] text-[#101828] font-medium py-2 whitespace-nowrap text-sm font-medium text-gray-900 border-r">DOD</td>
                                                            <td colSpan="2" class="px-3 text-[14px] text-[#101828] font-medium  py-2 whitespace-nowrap border-r">
                                                                Net Air Freight
                                                            </td>
                                                            <td rowSpan="2" class="px-3 text-[14px] text-[#101828] font-medium px-3 py-2 whitespace-nowrap border-r">
                                                                Transit time
                                                            </td>
                                                            <td rowSpan="2" class=" text-[14px] text-[#101828] font-medium   px-3 py-2 whitespace-nowrap">
                                                                Validity
                                                            </td>
                                                        </tr>
                                                        <tr class="bg-white border-b">
                                                            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900 border-r">AirLine</td>
                                                            <td class="text-[14px] text-[#101828] font-medium px-3 py-2 whitespace-nowrap border-r">
                                                                AED
                                                            </td>
                                                        </tr>
                                                        <tr class="bg-white border-b">
                                                            <td class="px-3 py-2 whitespace-nowrap text-sm font-medium text-gray-900 border-r">POD</td>
                                                            <td colspan="2" class="text-[14px] text-[#101828] font-medium px-3 py-2 whitespace-nowrap text-center border-r">
                                                                Net Air Freight
                                                            </td>
                                                            <td class="text-[14px] text-[#101828] font-medium px-3 py-2 whitespace-nowrap">
                                                                Transit time
                                                            </td>
                                                            <td class="text-[14px] text-[#101828] font-medium px-3 py-2 whitespace-nowrap">
                                                                Validity
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="p-2 text-[14px]">Other charges if needed: at cost. Such as warehouse/inspection/packing etc.</p>
                                <p className="p-2 text-[14px]"> SUBJECT TO SPACE CONFIRMATION AT THE TIME OF BOOKING </p>
                                <p className="p-2 pb-5 text-[14px]"> ALL RATES MENTIONED ABOVE EXCLUDE INSURANCE, DUTY AND VAT, IF ANY, AT ACTUALS</p>
                            </div>
                        </div>
                        <div className="pt-1 pb-2 displaynone">
                            <button className="border text-[#1B55AF] flex bg-[white] rounded-md p-2  border-[#1B55AF] pr-3  "><input type="file" className="cursore-pointer" /><img src={attach_icon} className="mr-3 img ml-2 cursor-pointer top-0.5" />Add Attachment</button>
                        </div>
                        <div>
                            <p className=' mt-3'>Kindly check and confirm to proceed with booking.</p>
                            <div className='flex p-3 gap-x-8'>

                                <div className="field-radiobutton">
                                    <input type="radio" name="field" id="" />
                                    <label htmlFor="city1" className='font-medium text-sm ml-2 text-[#101828]'>Confirm</label>
                                </div>
                                <div className="field-radiobutton">
                                    <input type="radio" name="field" id="" className='"appearance-none checked:bg-red-500' />
                                    <label htmlFor="city2" className=' text-sm ml-2 text-[#101828]'>Decline</label>
                                </div>
                                <div className="field-radiobutton">
                                    <input type="radio" name="field" id="" />
                                    <label htmlFor="city3" className=' text-sm ml-2 text-[#101828]'>Need Revision</label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className=' mt-3'>Choose the Reason</p>
                            <div className='flex p-3 gap-x-8'>

                                <div className="field-radiobutton">
                                    <input type="checkbox" name="" id="" />
                                    <label htmlFor="city1" className='font-medium text-sm ml-2 text-[#101828]'>Price</label>
                                </div>
                                <div className="field-radiobutton">
                                    <input type="checkbox" name="" id="" className='"appearance-none checked:bg-red-500' />
                                    <label htmlFor="city2" className=' text-sm ml-2 text-[#101828]'>Service</label>
                                </div>
                                <div className="field-radiobutton">
                                    <input type="checkbox" name="" id="" />
                                    <label htmlFor="city3" className=' text-sm ml-2 text-[#101828]'>Fright Forwarder</label>
                                </div>
                                <div className="field-radiobutton">
                                    <input type="checkbox" name="" id="" />
                                    <label htmlFor="city3" className=' text-sm ml-2 text-[#101828]'>Transit Time</label>
                                </div>
                                <div className="field-radiobutton">
                                    <input type="checkbox" name="" id="" />
                                    <label htmlFor="city3" className=' text-sm ml-2 text-[#101828]'>Others</label>
                                </div>
                            </div>
                        </div>
                        <h3 className="mb-2 text-[#344054] dark-[black]">Add Comments </h3>
                        <textarea cols="76" row="30" className="border rounded-lg h-40 p-2 border-[#C6CBD2] mb-2" placeholder="Pls add your comments"></textarea>
                        <div className="border-t-2 border-[#E4E7EC] mt-3 p-2  ">
                            <h4 className="font-normal mt-2">Thank You</h4>
                            <h2 className="text-md font-medium mb-2">{reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')}</h2>
                            <h2 className="font-medium">ProConnect Supply Chain Logistics</h2>
                            <p className="font-normal pb-4">Plot No. S 30902 Jebel Ali Free Zone South
                                P.O.BOX 262674 , Dubai, U.A.E
                                Mobile No: 123567890</p>
                        </div>
                    </div>
                    <div className="lg:relative left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                        <div className="flex justify-end gap-3">
                            <div><Link onClick={() => props.closeQuotationPopup()} className="bg-white 
                     dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                            >Cancel</Link></div>
                            <div><Link
                                onClick={() => saveConfirmBid()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">save</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
