import React, { useEffect, useState } from "react";
import logoLanding from '../../assets/images/logo-landing.png';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import EmpLogin from "../../setup/auth/emp.component";
import { Link, useNavigate, useParams } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import { DataStore } from "aws-amplify";
import { Inquiry, InquiryStatus } from '../../models';
import { toast } from 'react-toastify';


export default function EmpLanding() {
  const [isAuthenticated, userHasAuthenticated] = useState(true);

  const [totalInquiryCount, setTotalInquiryCount] = useState();
  const [openInquiryCount, setOpenInquiryCount] = useState();

  const navigateTo = useNavigate();


  const searchString = async (e) => {

    var wage = document.getElementById("required-search");

    var InquiryId = '';
    wage.addEventListener("keypress", function (e) {
      if (e.key === "Enter") {  //checks whether the pressed key is "Enter"
        // const { inquirySearchTxt } = useParams();
        reactLocalStorage.set('inquirySearchTxt', e.target.value);
        InquiryId = e.target.value;
        console.log("InquiryIdInquiryId :", InquiryId)

        loadInquiry();

        async function loadInquiry() {
          var searchedInquiry = await DataStore.query(Inquiry, (c) => c.inquiryNo.eq(e.target.value));

          console.log("searchedInquiry :", searchedInquiry)
          //check entered inquiry id present or not in inquiry table
          if (searchedInquiry.length > 0) {
            reactLocalStorage.set('inquiryNo', searchedInquiry[0].inquiryNo);

            navigateTo('/employee/inquiry/' + searchedInquiry[0].id);
          }
          else {
            toast.error('Enter Valid Id.');
          }
        }
      }
    });
  }

  async function onLoad() {
    try {
      if (!ValidateEmployeeRole()) {
        userHasAuthenticated(false);
      }
    } catch (e) {
      userHasAuthenticated(false);
    }
  }

  async function BindList() {
    //get total inquiry count
    var totalInquiryCount = await DataStore.query(Inquiry);
    setTotalInquiryCount(totalInquiryCount.length);

    // get open inquiry count
    var openInquiry = await DataStore.query(Inquiry,
      (c) => c.and(c => [
        c.inquiryStatus.eq(InquiryStatus.OPEN),
      ]),
    );

    setOpenInquiryCount(openInquiry.length);
  }

  useEffect(() => {
    onLoad();
    BindList();
  }, []);

  return isAuthenticated ? (
    <div className=" landing">
      <div className="container sm:container md:container xl:container 2xl:container mx-auto pl-4 pr-4 sm:pl-0 sm:pr-0">
        <div className="grid flex-row min-h-screen gap-5 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1">
          <div className="flex flex-row flex-wrap justify-center col-span-7 auto-rows-max">
            <div className="w-full p-3 pt-10">
              <div className="mb-10">
                <img className="w-60" src={logoLanding} alt="" />
              </div>
              <div className="mb-8 welcome-text">
                <h4 className="text-[22px] xl:text-[24px] 2xl:text-[25px] text-[#fff] leading-9  font-medium w-[500px] dark:text-white">
                  ProConnect Inquiry
                </h4>
                <p className="mt-3 text-[#fff] font-light">ProConnect Inquiry Automation Application, a comprehensive solution to enhance the customer experience, increase process efficiency and improve organizational profitability.</p>
              </div>


              <div className="searchbox">
                <div className="relative">
                  <input type="text" id="required-search" className="text-[16px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-3 px-4 pl-9 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-2 placeholder:text-[#98A2B3] placeholder:text-[15px] dark:placeholder:text-[#333]"
                    onChange={
                      (e) => {
                        searchString(e);
                      }}

                    placeholder="Search by Inquiry ID" />
                  <button type="submit" className="absolute left-3 top-1/2 translate-y-[-50%] ">
                    <i className="icon search_icon "></i></button></div>
              </div>

              <div className="mt-20 landing-feature">
                <span className="text-[#fff] text-[16px] py-2 px-3 w-auto keyfact">Key Facts FY  2022 - 23</span>

                <div
                  className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 mt-4 align-center 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">

                  <div className='py-4 px-3 rounded-xl box relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] totalinquires'>
                    <div className='mt-2'>
                      <h6 className='text-[#4DB17D] text-[14px] dark:text-[#98A2B3] font-normal leading-4'>Total Inquires</h6>
                      <p className='text-[#E2D3BD] text-[26px] font-bold dark:text-[#98A2B3]'>{totalInquiryCount}</p>
                      <div className="text-[12px] text-[#CEC6BA] font-light down-up">LY
                        <span className="text-[#4FB155] mx-1">11.2%<i className="icon greenup_icon"></i></span>Increased

                      </div>
                    </div>
                  </div>

                  <div className='py-4 px-3 rounded-xl box relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] openinquires'>
                    <div className='mt-2'>
                      <h6 className='text-[#4DB17D] text-[14px] dark:text-[#98A2B3] font-normal leading-4'>
                        Inquires - Open</h6>
                      <p className='text-[#E2D3BD] text-[26px] font-bold dark:text-[#98A2B3]'>{openInquiryCount}</p>
                      <div className="text-[12px] text-[#CEC6BA] font-light down-up">LY
                        <span className="text-[#4FB155] mx-1">11.2%<i className="icon greenup_icon"></i></span>Increased

                      </div>
                    </div>
                  </div>
                  <div className='py-4 px-3 rounded-xl box relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] quotation_icon'>
                    <div className='mt-2'>
                      <h6 className='text-[#4DB17D] text-[14px] dark:text-[#98A2B3] font-normal leading-4'>
                        Quotation - Executed</h6>
                      <p className='text-[#E2D3BD] text-[26px] font-bold dark:text-[#98A2B3]'>100</p>
                      <div className="text-[12px] text-[#CEC6BA] font-light down-up">LY
                        <span className="text-[#4FB155] mx-1">11.2%<i className="icon greenup_icon"></i></span>Increased

                      </div>
                    </div>
                  </div>
                  <div className='py-4 px-3 rounded-xl box relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] declined_icon_landing'>
                    <div className='mt-2'>
                      <h6 className='text-[#4DB17D] text-[14px] dark:text-[#98A2B3] font-normal leading-4'>
                        Quotation - Declined</h6>
                      <p className='text-[#E2D3BD] text-[26px] font-bold dark:text-[#98A2B3]'>100</p>
                      <div className="text-[12px] text-[#CEC6BA] font-light down-up">LY
                        <span className="text-[#4FB155] mx-1">11.2%<i className="icon greenup_icon"></i></span>Increased

                      </div>
                    </div>
                  </div>
                  <div className='py-4 px-3 rounded-xl box relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] quotationclosed'>
                    <div className='mt-2'>
                      <h6 className='text-[#4DB17D] text-[14px] dark:text-[#98A2B3] font-normal leading-4'>
                        Quotation - Closed</h6>
                      <p className='text-[#E2D3BD] text-[26px] font-bold dark:text-[#98A2B3]'>100</p>
                      <div className="text-[12px] text-[#CEC6BA] font-light down-up">LY
                        <span className="text-[#4FB155] mx-1">11.2%<i className="icon greenup_icon"></i></span>Increased

                      </div>
                    </div>
                  </div>

                  <div className='py-4 px-3 rounded-xl box relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] lifecycle_icon'>
                    <div className='mt-2'>
                      <h6 className='text-[#4DB17D] text-[14px] dark:text-[#98A2B3] font-normal leading-4'>
                        Avg. Inquiry Lifecycle</h6>
                      <p className='text-[#E2D3BD] text-[26px] font-bold dark:text-[#98A2B3]'>100</p>
                      <div className="text-[12px] text-[#CEC6BA] font-light down-up">LY
                        <span className="text-[#4FB155] mx-1">11.2%<i className="icon greenup_icon"></i></span>Increased

                      </div>
                    </div>
                  </div>


                </div>
              </div>

              <div>


              </div>
            </div>
          </div>
          <div className="col-span-1 pt-5"></div>
          <div className="col-span-4 pt-5">

            <div className="grid flex-row gap-5 mt-36 mb-5 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 landing_right_side">
              <div className="card">
                <div className="content">
                  <div className="front">
                    <div className="pt-10 profile h-60 box">
                      <div className="flex justify-center">
                        <i className="icon explore_icon"></i>
                      </div>
                      <h2 className="text-[20px] font-medium text-center shadow-2xl text-white mt-5">Explore</h2>
                    </div>

                  </div>
                  <div className="back from-bottom text-center p-5 bg-[#9D4FB1] border-[#7E378F]">
                    <h2 className="text-[20px] font-medium text-center shadow-2xl text-white">Explore</h2>


                    <p className="des">
                      Track your inquires in real time, visualize and investigate key trends and discover insights
                    </p>
                    <Link to='/employee/dashboard' className="bg-[#fff] rounded-md text-[14px] py-2 px-3">View More <i className="pi pi-angle-right relative top-0.5"></i></Link>

                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="front">
                    <div className="pt-10 profile h-60 box">
                      <div className="flex justify-center">
                        <i className="icon inquires_icon"></i>
                      </div>
                      <h2 className="text-[22px] font-medium text-center shadow-2xl text-white mt-5">Inquires</h2>
                    </div>

                  </div>
                  <div className="back from-bottom text-center p-5 bg-[#16ABFF] border-[#148ACC]">
                    <h2 className="text-[22px] font-medium text-center shadow-2xl text-white">Inquires</h2>


                    <p className="des">
                      Manager inquires, invite and evaluate bids, set rule engine, and create customer quotes
                    </p>
                    <Link to='/employee/inquiries' className="bg-[#fff] rounded-md text-[14px] py-2 px-3">View More <i className="pi pi-angle-right relative top-0.5"></i></Link>

                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="front">
                    <div className="pt-10 profile h-60 box">
                      <div className="flex justify-center">
                        <i className="icon mailbox_icon"></i>
                      </div>
                      <h2 className="text-[22px] font-medium text-center shadow-2xl text-white mt-5">Mailbox</h2>
                    </div>

                  </div>
                  <div className="back from-bottom text-center p-5 bg-[#FD3464] border-[#D6204B]">
                    <h2 className="text-[22px] font-medium text-center shadow-2xl text-white">Mailbox</h2>


                    <p className="des">
                      Seamless communication with internal and external stakeholders
                    </p>
                    <Link to='/employee/mailBox' className="bg-[#fff] rounded-md text-[14px] py-2 px-3">View More <i className="pi pi-angle-right relative top-0.5"></i></Link>

                  </div>
                </div>
              </div>
              <div className="card">
                <div className="content">
                  <div className="front">
                    <div className="pt-10 profile h-60 box">
                      <div className="flex justify-center">
                        <i className="icon report_icon"></i>
                      </div>
                      <h2 className="text-[22px] font-medium text-center shadow-2xl text-white mt-5">Reports</h2>
                    </div>

                  </div>
                  <div className="back from-bottom text-center p-5 bg-[#FF9838] border-[#E27C1D]">
                    <h2 className="text-[22px] font-medium text-center shadow-2xl text-white">Reports</h2>


                    <p className="des">
                      Seamless communication with internal and external stakeholders
                    </p>
                    <Link to='' className="bg-[#fff] rounded-md text-[14px] py-2 px-3">View More <i className="pi pi-angle-right relative top-0.5"></i></Link>

                  </div>
                </div>
              </div>



            </div>


          </div>
        </div>
      </div>
    </div>
  ) : (
    <EmpLogin sessionExpired={1} />
  );
}
