import { DataStore } from '@aws-amplify/datastore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import "../../../proConnect.css";
import ConfirmImg from "../../../assets/images/confirmImg.png";
import { CustomerQuotationPopup } from './customerquotation.component';
import CompareBids from './bid/comparebids.component';
import { InquiryBid } from '../../../models';

// import { ConfirmBid } from '../confirmbid.component';

export const ConfirmBid = (props) => {
       //Validate User Logged In

       const [margin, setMargin] = useState("")
       const [Remark, setRemark] = useState("")

       const { inquiryId } = useParams();


     const addmargin=async()=>{
        const original = await DataStore.query(InquiryBid,props.bidsId);

        await DataStore.save(
            InquiryBid.copyOf(original, (updated) => {
                updated.confirmMargin = margin;
                updated.confirmRemarks = Remark;
            })
        );

        // props.closePopup()
    }

    useEffect(() => {
    // console.log("bidid"+props.bidsId);
    }, [])
    
  return (
    <div>
    <div style={{ width: "40vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="displayPopupCont" className="sidenav">
                        <div className="popup_display_img"><img src={ConfirmImg} className="mx-auto mt-8" alt="" /></div>
                        {/* <div className="text-[#101828] text-xl  text-center mb-2 font-medium font-22 dark:text-white">Quote accepted by customer!</div> */}
                        <div className=" text-center text-[20px] text-[#101828] dark:text-white">Thank you for confirming the Bid!</div>
                        <div className=''>
                            <div className="py-2 px-5 flex grid-cols-4  justify-center  ">
                                <div className='w-2/3   justify-center '>
                                    <div >
                                        <label>Margin</label>
                                        <br />
                                        <div className='relative'>
                                        <input className='text-[14px] text-left paddingclass rounded-lg flex-1   border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]' onChange={(e) => setMargin(e.target.value)} value={margin} type="text" />
                                        <span class="absolute h-full  right-0  border-l-2 rounded-lg pl-3 top-1 pr-3 text-gray-500"><i className="pi pi-percentage" style={{'margin-top': '10px','padding':'5px'}}></i></span>
                                       </div>
                                    </div>
                                    <div className='  w-full  '>
                                        <div className=' w-full'>
                                            <h3 className="mt-3 mb-2">Remarks</h3>
                                            <textarea cols="76" row="30" className="h-40 text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] " onChange={(e) => setRemark(e.target.value)} value={Remark} ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:relative  left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                            <div className="flex justify-end gap-3">
                                <div><Link onClick={() => props.closepopup()} className="bg-white 
                     dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                >Cancel</Link></div>
                                <div><Link onClick={() => {props.openPopup(); addmargin()}} 
                                    className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Add Comment</Link>
                                </div>
                            </div>

                        </div>
                    </div>

            
    </div>


      
  )
}
