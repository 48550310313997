import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from "@mui/material";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { themeAction } from '../../../store/actions/theme';
import AppLogo from '../AppLogo'
import { reactLocalStorage } from 'reactjs-localstorage';


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function TopNav() {
    var myNavigation = useNavigate();
    const [IsShowUserParametersPopUp, setShowUserParametersPopUp] = useState(0);

    const currentThemeValue = useSelector((state) => state.currentTheme.value);

    const dispatch = useDispatch();

    async function logOut() {
        reactLocalStorage.remove(["loggedUserRole"])
        reactLocalStorage.remove(["loggedEmployeeId"])
        reactLocalStorage.remove(["loggedEmployeeFirstName"])
        reactLocalStorage.remove(["loggedEmployeeLastName"])
        reactLocalStorage.remove(["loggedEmployeeProfilePic"])
        reactLocalStorage.remove(["loggedEmployeeRoleId"])
        reactLocalStorage.remove(["loggedEmployeeRoleName"])
        reactLocalStorage.remove(["selectedYearEmployee"])

        myNavigation('/employee');
        myNavigation(0);
    }


    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="w-full text-gray-700 bg-white shadow-md fixed top-0 z-10 headar-wrapper dark:bg-[#232528] dark:shadow-md">
                <div x-data="{ open: false }" className="flex flex-col px-2 mx-auto md:items-center md:justify-between md:flex-row">
                    <div className="flex flex-row items-center justify-between p-2 px-2">
                        <Link to="/employee/dashboard" className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">
                            <AppLogo />
                        </Link>
                        <button className="rounded-lg md:hidden focus:outline-none focus:shadow-outline">
                            <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                                <path x-show="!open" fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                                <path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <nav className="flex-col flex-grow hidden pb-4 md:flex md:items-center md:pb-0 md:justify-end md:flex-row h-16">
                        <div className="items-center justify-center flex-1 md:flex md:justify-end">
                            <div className="notification">
                                <div className="relative px-3 py-2 mt-2 mr-3 text-sm font-semibold text-gray-900 notBtn md:flex md:mt-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none" >
                                    <i className="icon notification_icon "></i>
                                    <span className="w-5 h-5 rounded-full bg-[#029046] text-center text-[10px] text-[#fff] absolute right-0">08</span>

                                    <div className="box">
                                        <div className="p-3 display">
                                            <div className='flex justify-between'>
                                                <h6 className='text-[#101828] text-[15px] font-medium dark:text-white'> Your notifications</h6>
                                                <div className='text-[#01813F] text-[12px] font-medium'>Mark all as read</div>
                                            </div>
                                            <div className='flex items-center justify-between gap-3 mt-3 mb-3 notification-block'>
                                                <i className='mr-3 icon warn_icon'></i>
                                                <div>
                                                    <h6 className='text-[#101828] text-[12px] font-medium dark:text-white'>You have received a new change request ID:EMP2548</h6>
                                                    <p className='text-[#98A2B3] text-[12px] font-normal'>2 Mins ago</p>
                                                </div>
                                                <i className='icon close_icon_notification'></i>
                                            </div>
                                            <div className='flex items-center justify-between gap-3 mt-3 mb-3 notification-block'>
                                                <i className='mr-3 icon warn_icon'></i>
                                                <div>
                                                    <h6 className='text-[#101828] text-[12px] font-medium dark:text-white'>You have received a new change request ID:EMP2548</h6>
                                                    <p className='text-[#98A2B3] text-[12px] font-normal'>2 Mins ago</p>
                                                </div>
                                                <i className='icon close_icon_notification'></i>
                                            </div>
                                            <Link to='' className='mt-3 mb-2 flex w-full border border-[#E4E7EC] text-[#01813F] py-2 p-2 rounded-md justify-center text-[12px] font-normal dark:bg-[#44494E] dark:text-white dark:border-[#44494E]'>View More</Link>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link onClick={() => currentThemeValue === undefined ? dispatch(themeAction("D")) : currentThemeValue === "W" ? dispatch(themeAction("D")) : dispatch(themeAction("W"))} className="relative px-3 py-2 mt-2 text-sm font-semibold text-gray-900 md:flex md:mt-0 hover:text-gray-900 focus:text-gray-900 focus:outline-none" >
                                <i className="icon sun_icon mt-1.5 "></i>
                            </Link>
                        </div>

                        <div className="relative" x-data="{ open: false }">
                            <Link className="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm text-left bg-transparent rounded-lg md:w-auto md:inline md:mt-0 md:ml-4 focus:outline-none">

                                <span className="flex flex-wrap flex-grow">
                                    <div className="mr-3">
                                        <div className="font-bold text-md dark:text-white">{reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')}</div>
                                        <div className="text-xs font-extralight dark:text-white">{reactLocalStorage.get('loggedEmployeeRoleName')}</div>
                                    </div>
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div className="flex items-center align-center">
                                            <Menu.Button className='flex items-center'>
                                                <Avatar alt="" src={reactLocalStorage.get('loggedEmployeeProfilePic')} />

                                                <svg fill="currentColor" viewBox="0 0 20 20" className="inline w-5 h-5 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 dark:text-white"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </Menu.Button>
                                        </div>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 w-56 mt-3 origin-top-right bg-white divide-y divide-gray-100 dark:divide-[#333] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-[#232528]">
                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={"/employee/profile"}
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100 text-gray-900 dark:bg-[#333231] dark:text-[#F8F8F8]"
                                                                        : "text-gray-700 dark:bg-[#232528] dark:text-[#F8F8F8]",
                                                                    "block px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                <i className="mr-6 icon-size user_icon"></i> Profile
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={"/employee/changePassword"}
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100 text-gray-900 dark:bg-[#333231] dark:text-[#F8F8F8]"
                                                                        : "text-gray-700 dark:bg-[#232528] dark:text-[#F8F8F8]",
                                                                    "block px-4 py-2 text-sm"
                                                                )}
                                                            >
                                                                <i className="mr-6 icon-size lock_icon"></i> Change Password
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </div>


                                                <div className="py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                onClick={logOut}
                                                                className={classNames(
                                                                    active
                                                                        ? "bg-gray-100 text-gray-900 dark:bg-[#333231] dark:text-[#F8F8F8]"
                                                                        : "text-gray-700 dark:text-[#F8F8F8] dark:bg-[#232528]",
                                                                    "block px-4 py-2 text-sm cursor-pointer"
                                                                )}
                                                            >
                                                                <i className="mr-6 icon-size logout_icon_small"></i> Logout
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </span>
                            </Link>
                        </div>
                    </nav>
                </div >
            </div >
          
        </div>

    )

}