import { DataStore } from '@aws-amplify/datastore';
import { FreightForwarder, InquiryBid, BidStatus, Inquiry, Country, InquiryServices, InquiryCargoType, InquiryContainers, ContainerType } from '../../../../models';
import TimeLineLastLogo from "../../../../assets/images/logisticsLogo.png"
import React, { useEffect, useState } from 'react';
import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import { Link, useParams } from "react-router-dom";
import EmpLogin from "../../../../setup/auth/emp.component";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';
import InquiryView from "../inquiryview.component";
import moment from "moment";
import { reactLocalStorage } from 'reactjs-localstorage';


export default function TimelineDashboard() {

    const { inquiryId } = useParams();
    const [isViewPopUp, setViewPopUp] = useState(0);
    const [frightForworders, setFrightForworders] = useState([]);
    //bids
    const [totalBidsInvited, setTotalBidsInvited] = useState('');
    const [declinedBidsInvited, setDeclinedBidsInvited] = useState(0);
    const [confirmedBidsInvited, setConfirmedBidsInvited] = useState(0);
    const [notResponsedBidsInvited, setNotResponsedBidsInvited] = useState('');
    const [responsedBidsInvited, setResponsedBidsInvited] = useState(0);

    //date 
    const [inquiryDate,setInquiryDate]=useState('');

    function showDays(startDate, endDate) {
        startDate = moment(startDate).format("DD/MM/YYYY");
        endDate = moment(endDate).format("DD/MM/YYYY");
        startDate = moment(startDate, "DD/MM/YYYY");
        endDate = moment(endDate, "DD/MM/YYYY");
        return (endDate.diff(startDate, 'days') + 1);
    }

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {
        //fetch current inquiry data from inquiry table
        var currentIdData=await DataStore.query(Inquiry,inquiryId);
        var inquiryDate=moment(currentIdData.inquiryDate).format("DD/MM/YYYY");
        setInquiryDate(inquiryDate);

        var bidInvitees = await DataStore.query(InquiryBid, (c) => c.inquiryId.eq(c));

        var inquiryDeclinedCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.DECLINED)
        ]));

        var notResponsedBidCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.INVITED)
        ]));


        var inquiryConfirmedCount = await DataStore.query(InquiryBid, (c) => c.and(c => [
            c.inquiryId.eq(inquiryId),
            c.bidStatus.eq(BidStatus.CONFIRMED)
        ]));


        let inquiryBidInviteeData = []
        let inquiryBidApprovedData = []

        for (let i = 0; i < inquiryConfirmedCount.length; i++) {

            var frightForwordersDetails = await DataStore.query(FreightForwarder, inquiryConfirmedCount[i].freightForwarderId);

            let data = {
                "InviteDate": inquiryConfirmedCount[i].createdAt,
                "InviteUpdatedDate": inquiryConfirmedCount[i].bidDate,
                // "InviteResponseDate": inquiryConfirmedCount[i].updatedAt,
                "FrightForwardName": frightForwordersDetails ? frightForwordersDetails.name : '-',
                "isApproved": inquiryConfirmedCount[i].isApproved,
                'bgColor': '#F4FBF5'
            }
            inquiryBidInviteeData.push(data);

            if (inquiryConfirmedCount[i].isApproved) {
                let dataApprove = {
                    "isConfirmed":true,
                    "InviteDate": inquiryConfirmedCount[i].createdAt,
                    "InviteUpdatedDate": inquiryConfirmedCount[i].updatedAt,
                    "FrightForwardName": frightForwordersDetails ? frightForwordersDetails.name+' - Bid Confirmed' : '-',
                    "isApproved": inquiryConfirmedCount[i].isApproved,
                    'bgColor': 'yellow'
                }

                inquiryBidApprovedData.push(
                    dataApprove,
                    {
                        "InviteDate": '123',
                        "InviteUpdatedDate": '123',
                        "FrightForwardName": 'Customer Quote',
                        "isApproved": '23',
                        'bgColor': '#4FB155',
                        'sendToCustomer': true
                    }
                );
            }
        }
        
        let finalData = [
            ...inquiryBidInviteeData,
            ...inquiryBidApprovedData
        ];


        setFrightForworders(finalData);

        setTotalBidsInvited(bidInvitees.length);
        setDeclinedBidsInvited(inquiryDeclinedCount.length);
        setConfirmedBidsInvited(inquiryConfirmedCount.length);
        setNotResponsedBidsInvited(notResponsedBidCount.length);

        setResponsedBidsInvited(inquiryDeclinedCount.length + inquiryConfirmedCount.length);
    }
    useEffect(() => {
        onLoad();
        BindList();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper pl-16 inquies" x-data="{tab: 1}">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4 inquies">
                            <Link to="/employee/inquiries" className="text-[#344054] text-[14px] dark:text-white py-2 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6"></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Inquiry ID {reactLocalStorage.get('inquiryNo')}</div>
                        </div>
                    </div>

                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link onClick={() => {
                                    reactLocalStorage.set('isInquiryEdit', true);
                                }}
                                    to={"/employee/inquiry/" + inquiryId} className="">Inquiry</Link></li>
                                <li><Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="">Bids</Link></li>
                                <li><Link to={"/employee/inquiry/quotation/dashboard/" + inquiryId} className="">Quotation</Link></li>
                                <li><Link to={"/employee/inquiry/timeline/dashboard/" + inquiryId} className="active">Timeline</Link></li>
                                <li><Link to={"/employee/inquiry/mails/dashboard/" + inquiryId} className="">Mails</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">
                            <Link onClick={() => setViewPopUp(1)} to="#" className="bg-white flex text-[#344054] text-[15px] dark:text-white py-2 px-3 rounded-md border border-[#C6CBD2]">
                                <i className="pi pi-info-circle m-1"></i>
                                Basic Information
                            </Link>
                        </div>
                    </div>

                    {/* code here */}
                    <div className={"block w-full"}>
                        <div className='inquiesbids mt-5'>

                            <div className='mt-10 flex-row grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-6 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-10 bids_feture'>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] invited-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Total Bids Invited</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{totalBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] responded-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Responded</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{responsedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] unresponsive-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Unresponsive</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{notResponsedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] declined-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>FFs Declined</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{declinedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] confirmed-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Confirmed Bids</h6>
                                        <p className='text-[#344054] text-[32px] dark:text-[#98A2B3]'>{confirmedBidsInvited}</p>
                                    </div>
                                </div>
                                <div className='bg-white py-5 px-5 rounded-xl boxShadow relative dark:border-[green] dark:text-white dark:bg-[#2A2E32] status-bg'>
                                    <div className='mt-2'>
                                        <h6 className='text-[#667085] text-[15px] dark:text-[#98A2B3] font-medium leading-4'>Status</h6>
                                        <p className='w-32 text-[#344054] text-[32px] dark:text-[#98A2B3] mt-3'>
                                            <p className="bg-[#E5F3EC] text-[#029046] px-3 py-1 rounded-full text-[12px] flex items-center gap-1 "> <div className='w-2 h-2 bg-[#029046] rounded-full'></div>{"Quote Accepted"}</p></p>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white dark:bg-[#2A2E32] mt-2 py-2 rounded-lg px-3 boxshadow">
                                <div>
                                    <p className='p-1'>Inquiry Cycle Time: 5 days</p>
                                </div>

                                <div className="mt-5">
                                    <div className='bg-[#4FB155] px-2 py-3 rounded-lg w-60 text-center ml-5'>
                                        <h6 className='text-[16px] text-[#fff] font-medium'>Receipt of Inquiry</h6>
                                        <div className='text-[12px] text-[#FEF0C7] flex gap-2 items-center justify-center'>
                                            <div className='h-2 w-2 rounded-full bg-[#FEF0C7]'>
                                            </div>{inquiryDate}</div>
                                    </div>
                                    <div className='w-60 text-center ml-5 mt-3'><i className='pi pi-angle-double-down text-[#029046] text-lg'></i></div>
                                    <div className="timeline">

                                        <div className="timeline-content border border-[#C6CBD2]">
                                            <div className="timeline-period"></div>
                                            <div className="px-3" >
                                                <h2 className=" text-[22px] text-[#667085] font-medium">ProConnect - Inquiry Entry</h2>

                                                <div className='mt-24'>
                                                    <Link to='' className=" py-2 px-5 text-[#344054] transition ease-in duration-200 text-[16px] text-center font-light  focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]" >System Entry</Link>

                                                    <h6 className=" mt-3 text-[16px] text-[#101828] font-medium">Inquiry Recorded</h6>
                                                    <p className="flex gap-2 text-[12px] text-[#344054] font-light items-center mt-1"><div className="h-3 w-3 rounded-full bg-[#4FB155]"></div>{inquiryDate}</p>
                                                </div>

                                            </div>
                                        </div>

                                        {frightForworders.map((item, i) => (
                                            <div className={`timeline-content bg-[${item.bgColor}] border border-[#60B866]`} key={i}>
                                                <div className="timeline-period"></div>
                                                <div className="px-3" >
                                                    <h2 className={`text-[22px] ${(item.sendToCustomer) ? 'text-[#fff]' : 'text-[#377B3B]'}  font-medium mb-2`}>{item.FrightForwardName}</h2>
                                                    <h6 className={`mt-3 text-[16px] ${(item.sendToCustomer) ? 'text-[#fff]' : 'text-[#016431]'} font-medium`}>
                                                        {(item.sendToCustomer) ? 'Quote to Customer' : 'Invited On'}</h6>
                                                    <p className={`flex gap-2 text-[12px] ${(item.sendToCustomer) ? 'text-[#fff]' : 'text-[#016431]'}  font-light items-center mt-1`}><div className={`h-3 w-3 rounded-full ${(item.sendToCustomer) ? 'bg-[#fff]' : 'bg-[#4FB155]'}`}></div>{moment(item.InviteDate).format("DD/MM/YYYY")}</p>

                                                    <div className='mt-10'>
                                                        <Link to='' className={`py-2 px-5 transition ease-in duration-200 text-[16px] text-center font-light  focus:outline-none leading-6  justify-center rounded-lg border ${(item.sendToCustomer) ? 'border-[#fff] text-[#fff]' : 'border-[#4FB155] text-[#4FB155]'} rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]`} >
                                                            {showDays(item.InvitedDate, item.InviteUpdatedDate)} Day</Link>

                                                        <h6 className={`mt-3 text-[16px] ${(item.sendToCustomer) ? 'text-[#fff]' : 'text-[#016431]'} font-medium`}>
                                                            {(item.isConfirmed)?'Bid confirmed on':'Response Received On'} </h6>

                                                        <p className={`flex gap-2 text-[12px] ${(item.sendToCustomer)? 'text-[#fff]':'text-[#016431]'} font-light items-center mt-1`}>
                                                            <div className={`h-3 w-3 rounded-full ${(item.sendToCustomer)?'bg-[#fff]':'bg-[#4FB155]'}`}></div>
                                                            {moment(item.InviteUpdatedDate).format("DD/MM/YYYY")}
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}

                                        {/* <div className="timeline-content bg-[#4FB155] border border-[#60B866]">
                                            <div className="timeline-period"></div>
                                            <div className="px-3" >
                                                <h2 className=" text-[22px] text-[#fff] font-medium mb-2">Customer Quote</h2>
                                                <h6 className=" mt-3 text-[16px] text-[#fff] font-medium">
                                                    Quote to Customer</h6>
                                                <p className="flex gap-2 text-[12px] text-[#fff] font-light items-center mt-1"><div className="h-3 w-3 rounded-full bg-[#fff]"></div>14/12/2022</p>

                                                <div className='mt-10'>
                                                    <Link to='' className=" py-2 px-5 text-[#fff] transition ease-in duration-200 text-[16px] text-center font-light  focus:outline-none leading-6  justify-center rounded-lg border border-[#FFFFFF] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]" >
                                                        2 Day</Link>

                                                    <h6 className=" mt-3 text-[16px] text-[#fff] font-medium">
                                                        Response Received On</h6>
                                                    <p className="flex gap-2 text-[12px] text-[#fff] font-light items-center mt-1"><div className="h-3 w-3 rounded-full bg-[#fff]"></div>12/12/2022</p>
                                                </div>

                                            </div>
                                        </div> */}

                                        {/* <div className="timeline-content bg-[#4FB155] border border-[#60B866]">
                                            <div className="timeline-period"></div>
                                            <div className="px-3" >
                                                <h2 className=" text-[22px] text-[#fff] font-medium mb-2">Details Forwarded to FMS</h2>
                                                <h6 className=" mt-1 text-[16px] text-[#fff] font-medium">
                                                    Quote to Customer</h6>
                                                <p className="flex gap-2 text-[12px] text-[#fff] font-light items-center mt-1"><div className="h-3 w-3 rounded-full bg-[#fff]"></div>14/12/2022</p>

                                                <div className='mt-2'>
                                                    <img src={TimeLineLastLogo} />
                                                </div>

                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="selector" className={isViewPopUp === 1 ? 'overlay' : ''}></div>

                {
                    isViewPopUp === 1 ?
                        <div>
                            <InquiryView
                                closePopUp={() => setViewPopUp(0)}
                            />
                        </div> : null
                }

            </div>
            <div id="selector"></div>

        </div >
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}