import { DataStore } from "@aws-amplify/datastore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { InquiryServices } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import defaultProfilePath from '../../../../../assets/images/defaultProfile.jpg'
import { UploadFileOnS3 } from '../../../../../components/helper/aws';
import { GetAWSObjectURL } from '../../../../../components/helper/aws';


export default function EmployeeCreateService() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editServiceId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [serviceId, setServiceId] = useState("");
    let navigateTo = useNavigate();

    //Save Fields
    const [service, setService] = useState("");


    const [logoPath, setlogoPath] = useState("");
    const [localPicturePath, setLocalPicturePath] = useState("");



    //select profile picture
    const browseProfilePic = () => {
        document.getElementById('fileProfilePic').click()
    }

    //change profile picture
    async function changeProfilePicture(e) {
        let file = e.target.files[0];
        setlogoPath(file)
        setLocalPicturePath(URL.createObjectURL(file))
    }

    const myProfileImageStyle = {
        borderRadius: "5%",
        width: '109px',
        height: '109px',
        marginTop: "5px"

    };

    //Create Record
    const saveService = async () => {
        if (service === "") {
            toast.error("Please enter Service.");
            return;
        }

        setPleaseWaitButton(true);
        if (isEditMode) {
            const original = await DataStore.query(InquiryServices, serviceId);
            await DataStore.save(
                InquiryServices.copyOf(original, (updated) => {
                    updated.name = capitalizeFirst(service);
                })
            );
            updateProfilePicture(original.id);
            navigateTo("/employee/settings/services");
            toast.success('Updated');
        } else {
            await DataStore.save(new InquiryServices({ name: capitalizeFirst(service) })).then((response) => {
                toast.success('Added');
                updateProfilePicture(response.id)
                setPleaseWaitButton(false);
                navigateTo("/employee/settings/services");
            })
        }
    };


    const updateProfilePicture = async (serviceId) => {

        const fileName = 'public/service/' + serviceId + "/" + logoPath.name;
        setLocalPicturePath(fileName)
        if (logoPath) {
            if (isEditMode) {
                UploadFileOnS3(logoPath, fileName).then(async () => {
                    const original = await DataStore.query(InquiryServices, serviceId);
                    await DataStore.save(
                        InquiryServices.copyOf(original, (updated) => {
                            updated.iconName = fileName;
                        })
                    );
                }
                )
            }
            else {
                UploadFileOnS3(logoPath, fileName).then(async () => {
                    const original = await DataStore.query(InquiryServices, serviceId);
                    await DataStore.save(
                        InquiryServices.copyOf(original, (updated) => {
                            updated.iconName = fileName;
                        })
                    )
                }).catch(error => toast.error(error));

            }
        }
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editServiceId != null) {
                setServiceId(editServiceId);
                const original = await DataStore.query(InquiryServices, editServiceId);
                if (original != null) {
                    if (original.iconName) {
                        let getlogoPath = await GetAWSObjectURL(original.iconName);
                        setLocalPicturePath(getlogoPath)
                    }
                    setService(original.name);
                    setEditMode(true);
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/employee/settings/services");
                }
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />
                <div className="pl-16">
                    <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white dark:bg-[#2A2E32] rounded-md shadow ">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Service
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-x-4">
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Service
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={(service)}
                                                    onChange={(e) => setService(e.target.value)}
                                                    type="text"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter Service"
                                                />
                                            </div>
                                            <div className="mt-2 col-span-2 text-center mb-5">

                                                <img className="mt-5  m-auto border border-[#029046] dark:border-[#000]" alt=""
                                                    src={localPicturePath ? localPicturePath : logoPath ? logoPath : defaultProfilePath} style={myProfileImageStyle}
                                                />
                                                <button
                                                    onClick={browseProfilePic}
                                                    className=" mt-2 py-1 px-3  text-[#029046] transition ease-in duration-200 text-[14px] text-center font-normal  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#029046] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF] "
                                                >
                                                    {localPicturePath ? "Update" : "Add"} Image
                                                    <input hidden id="fileProfilePic" type="file"
                                                        onChange={changeProfilePicture}
                                                    />
                                                </button>
                                            </div>

                                            {/* <div className="pt-5 displaynone">
                                                <button className="border text-[#1B55AF] flex bg-[white] rounded-md p-2  border-[#1B55AF] pr-2 "><input type="file" className="cursore-pointer" /><i className="pi pi-image mr-1 img  mt-1 cursor-pointer"></i>Add img</button>
                                            </div> */}
                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link
                                                to="/employee/settings/services"
                                                className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#4A5056] dark:text-white"
                                            >
                                                Cancel
                                            </Link>
                                            <button
                                                onClick={() => saveService()}
                                                className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                            >
                                                {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}
