import './App.css';
import './proConnect.css';
import 'primeicons/primeicons.css';


import RouteComponent from './setup/route.component';
import { ToastContainer} from 'react-toastify';
import { useSelector } from 'react-redux';
import 'primeicons/primeicons.css';

function App() {

  const currentThemeValue = useSelector((state) => state.currentTheme.value);
  const showCurrentTheme = currentThemeValue ==="D" ? "dark" : "light";

  return (
    <>
      <RouteComponent />
      <ToastContainer theme={showCurrentTheme} autoClose={1000}  />
    </>
  );
}

export default App;
