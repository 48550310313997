// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CustomerBidStatus = {
  "ACCEPTED": "ACCEPTED",
  "REJECTED": "REJECTED",
  "REQUESTFORREVISION": "REQUESTFORREVISION"
};

const BidStatus = {
  "INVITED": "INVITED",
  "CONFIRMED": "CONFIRMED",
  "DECLINED": "DECLINED"
};

const InquiryStatus = {
  "OPEN": "OPEN",
  "CLOSED": "CLOSED"
};

const InquirySource = {
  "WEBSITE": "WEBSITE"
};

const { Inco, InquiryBidContainerDetails, InquiryBidRequestedDetails, InquiryDocument, InquiryBidRule, InquiryBidFreightForwarderQuestion, FreightForwarderQuestion, InquiryDestinationServices, InquiryOriginServices, InquiryContainers, Currency, StuffingType, Units, MovementType, InquiryBid, Products, ContainerType, FreightForwarder, State, Country, Customer, SalesPerson, Inquiry, FinancialYear, InquiryServices, InquiryPort, InquiryCargoType, InquiryNotes, Roles, Employee } = initSchema(schema);

export {
  Inco,
  InquiryBidContainerDetails,
  InquiryBidRequestedDetails,
  InquiryDocument,
  InquiryBidRule,
  InquiryBidFreightForwarderQuestion,
  FreightForwarderQuestion,
  InquiryDestinationServices,
  InquiryOriginServices,
  InquiryContainers,
  Currency,
  StuffingType,
  Units,
  MovementType,
  InquiryBid,
  Products,
  ContainerType,
  FreightForwarder,
  State,
  Country,
  Customer,
  SalesPerson,
  Inquiry,
  FinancialYear,
  InquiryServices,
  InquiryPort,
  InquiryCargoType,
  InquiryNotes,
  Roles,
  Employee,
  CustomerBidStatus,
  BidStatus,
  InquiryStatus,
  InquirySource
};