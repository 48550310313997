// import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { FreightForwarder, Country } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import Select from 'react-select';
import { State } from '../../../../../models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect } from '../../../../../components/helper/commonfunctions';
import PhoneInput from "react-phone-input-2";
import defaultProfilePath from '../../../../../assets/images/defaultProfile.jpg'
import { UploadFileOnS3, GetAWSObjectURL } from '../../../../../components/helper/aws';




export default function EmployeeCreateFrightForward() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editFrightForwardId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [freightForwardId, setFreightForwardId] = useState("");
    let navigateTo = useNavigate();

    // lists
    const [states, setStates] = useState([]);

    //Country List
    const [countryId, setCountryId] = useState("");
    const [stateName, setStateName] = useState("");

    //
    const [countryName, setCountryName] = useState("");


    //Save Fields
    const [name, setName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [state, setState] = useState("");
    const [countrys, setCountrys] = useState("");
    const [stateId, setStateId] = useState("");
    const [logoPath, setlogoPath] = useState("");
    const [localPicturePath, setLocalPicturePath] = useState("");


    const goBack = () => {
        setPleaseWaitButton(false);
        navigateTo("/employee/settings/employee");
    }

    //Create Record
    const saveFreightForwarder = async () => {
        if (name === "") {
            toast.error("Please enter FreightForwarder.");
            return;
        } else if (address1 === '') { toast.error('Please enter Address 1.'); return; }
        else if (address2 === '') { toast.error('Please enter Address 2.'); return; }
        else if (city === '') { toast.error('Please enter City.'); return; }
        else if (zipCode === '') { toast.error('Please enter ZipCode.'); return; }
        else if (email === '') { toast.error('Please enter Email.'); return; }
        else if (phoneNumber === '') { toast.error('Please enter Phone Number.'); return; }
        else if (stateName === '') { toast.error('Please enter State.'); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {

            const original = await DataStore.query(FreightForwarder, freightForwardId);
            await DataStore.save(
                FreightForwarder.copyOf(original, (updated) => {
                    updated.name = capitalizeFirst(name);
                    updated.address1 = capitalizeFirst(address1);
                    updated.address2 = capitalizeFirst(address2);
                    updated.city = capitalizeFirst(city);
                    updated.email = capitalizeFirst(email);
                    updated.phoneNo = capitalizeFirst(phoneNumber);
                    updated.stateId = capitalizeFirst(stateId);
                    updated.zipCode = capitalizeFirst(zipCode);
                })
            );
            updateProfilePicture(original.id);
            toast.success('Updated');

        } else {
            //------------

            await DataStore.save(new FreightForwarder({
                name: capitalizeFirst(name),
                address1: capitalizeFirst(address1),
                address2: capitalizeFirst(address2),
                city: capitalizeFirst(city),
                email: capitalizeFirst(email),
                phoneNo: capitalizeFirst(phoneNumber),
                stateId: capitalizeFirst(stateId),
                zipCode: capitalizeFirst(zipCode),
                logo: "",

            })).then((response) => {
                toast.success('Added');
                updateProfilePicture(response.id)
                { goBack() }
            })
        }
        setPleaseWaitButton(false);
        navigateTo("/employee/settings/frightforward");
    };

    //select profile picture
    const browseProfilePic = () => {
        document.getElementById('fileProfilePic').click()
    }

    //change profile picture
    async function changeProfilePicture(e) {
        let file = e.target.files[0];
        setlogoPath(file)
        setLocalPicturePath(URL.createObjectURL(file))
    }

    const updateProfilePicture = async (freightForwardId) => {
        const fileName = 'public/service/' + freightForwardId + "/" + logoPath.name;
        setLocalPicturePath(fileName)
        if (logoPath) {
            if (isEditMode) {
                UploadFileOnS3(logoPath, fileName).then(async () => {
                    const original = await DataStore.query(FreightForwarder, freightForwardId);
                    await DataStore.save(
                        FreightForwarder.copyOf(original, (updated) => {
                            updated.logo = fileName;
                        })
                    );
                }
                )
            }
            else {
                UploadFileOnS3(logoPath, fileName).then(async (Response) => {
                    const original = await DataStore.query(FreightForwarder, freightForwardId);
                    await DataStore.save(
                        FreightForwarder.copyOf(original, (updated) => {
                            updated.logo = fileName;
                        })
                    )
                }).catch(error => toast.error(error));
            }
        }
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }

            //Edit existing record
            if (editFrightForwardId != null) {

                setFreightForwardId(editFrightForwardId);
                const original = await DataStore.query(FreightForwarder, editFrightForwardId);

                if (original != null) {
                    if (original.logo) {
                        let getlogoPath = await GetAWSObjectURL(original.logo);
                        setLocalPicturePath(getlogoPath)
                    }
                    const state_original = await DataStore.query(State, original.stateId);

                    setName(original.name);
                    setAddress1(original.address1);
                    setAddress2(original.address2);
                    setCity(original.city);
                    setZipCode(original.zipCode);
                    setEmail(original.email);
                    setPhoneNumber(original.phoneNo);
                    setState({ label: state_original.name, value: state_original.id });
                    setEditMode(true);
                    if (countryId) {
                        //
                    } else {
                        var statesResponses = await DataStore.query(State, original.stateId);
                        //console.log(statesResponses);
                        setStateName({ label: statesResponses.name, value: statesResponses.id });
                        setStateId(statesResponses.id);
                        var countryResponses = await DataStore.query(Country, statesResponses.countryId);
                        //console.log(statesResponses);
                        setCountryName({ label: countryResponses.name, value: countryResponses.id });
                        setCountryId(countryResponses.id);
                        //setCustomerId(original.id)
                    }
                }
                else {
                    toast.error('Invalid record.');
                    navigateTo("/employee/settings/frightforward");
                }
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {

        //Country List
        var countryResponses = await DataStore.query(Country, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myCountryArray = []; myCountryArray = ConvertResponseForSelect(countryResponses); setCountrys(myCountryArray);

        //State List
        var stateResponses = await DataStore.query(State, c => c.countryId.eq(countryId), Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myStateArray = []; myStateArray = ConvertResponseForSelect(stateResponses); setStates(myStateArray);

    }

    useEffect(() => {
        onLoad();
        BindList();
    }, [countryId]);

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />
                <div className="pl-16">
                    <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white dark:bg-[#2A2E32] rounded-md shadow ">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Freight Forwarder
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 gap-x-4">
                                            <div className="relative mb-4 col-span-2">
                                                <div className="">
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#344054] text-[14px] font-medium "
                                                    >
                                                        Name
                                                        <label
                                                            htmlFor=""
                                                            className="text-[#FF0000] text-[14px] font-medium"
                                                        >
                                                            *{" "}
                                                        </label>
                                                    </label>
                                                    <input
                                                        value={(name)}
                                                        onChange={(e) => setName(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                        placeholder="Enter Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Address 1
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={(address1)}
                                                    onChange={(e) => setAddress1(e.target.value)}
                                                    type="text"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter Address 1"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Address 2
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={(address2)}
                                                    onChange={(e) => setAddress2(e.target.value)}
                                                    type="text"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter Address 2"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    City
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={(city)}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    type="text"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter City"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Zip Code
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={(zipCode)}
                                                    onChange={(e) => setZipCode(e.target.value)}
                                                    type="number"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter ZipCode"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Email
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type="email"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter Email"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Phone Number
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>

                                                <PhoneInput
                                                    enableSearch
                                                    buttonStyle={{ background: 'transparent', borderWidth: 0 }}
                                                    inputStyle={{ borderWidth: 0, fontSize: 16, background: 'transparent' }}
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-1 mt-1 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                    country={'us'}
                                                    value={phoneNumber}
                                                    onChange={(phone) => setPhoneNumber("+" + phone)}
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Select Country
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <Select
                                                    value={countryName}
                                                    options={countrys}
                                                    onChange={e => {
                                                        setCountryName(e)
                                                        setCountryId(e.value)
                                                        console.log("selected country :", e)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Select State
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <Select
                                                    value={stateName}
                                                    options={states}
                                                    onChange={e => {
                                                        setStateName(e)
                                                        setStateId(e.value)
                                                        console.log("selected state :", e)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="mt-2 col-span-2 text-center mb-5">

                                                <img className="mt-5 m-auto border border-[#029046] dark:border-[#000]" alt=""
                                                    src={localPicturePath ? localPicturePath : logoPath ? logoPath : defaultProfilePath} style={myProfileImageStyle}
                                                />
                                                <button
                                                    onClick={browseProfilePic}
                                                    className=" mt-2 py-1 px-3  text-[#029046] transition ease-in duration-200 text-[14px] text-center font-normal  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#029046] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF] "
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Browse Logo"}
                                                    <input hidden id="fileProfilePic" type="file"
                                                        onChange={changeProfilePicture}
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link
                                                to="/employee/settings/frightforward"
                                                className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#4A5056] dark:text-white"
                                            >
                                                Cancel
                                            </Link>
                                            <button
                                                onClick={() => saveFreightForwarder()}
                                                className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                            >
                                                {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}

const myProfileImageStyle = {
    borderRadius: "50%",
    width: '100px',
    height: '100px',
    marginTop: "5px"

};