import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import awsExports from "./aws-exports";
import { Provider as ReduxProvider } from "react-redux";
import store  from './store/store'

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

Amplify.configure(awsExports);


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Authenticator.Provider>
    <React.StrictMode>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </MsalProvider> 
      </BrowserRouter>
    </React.StrictMode>
  </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
