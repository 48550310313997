import { ValidateEmployeeRole } from "../../../../components/helper/validateRole";
import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import EmpLogin from "../../../../setup/auth/emp.component";
import LeftSideBar from '../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../components/common/employee/topnav.component';import { MailBoxComponent } from "./components/MailBoxComponent";
;



export default function MailsDashboard() {

    const { inquiryId } = useParams();
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }





    useEffect(() => {
        onLoad();
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="dashboard-main-wrapper target-main-wrapper pl-16" x-data="{tab: 1}">
                <div className="pr-5 pl-5 pb-0 pt-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to="/employee/inquiries" className="bg-[#fff] text-[#344054] text-[14px] dark:text-white py-2 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Inquiry ID {reactLocalStorage.get('inquiryNo')}</div>
                        </div>

                    </div>

                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link onClick={() => {
                                    reactLocalStorage.set('isInquiryEdit', true);
                                }}
                                to={"/employee/inquiry/" + inquiryId} className="">Inquiry</Link></li>
                                <li><Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="">Bids</Link></li>
                                <li><Link to={"/employee/inquiry/quotation/dashboard/" + inquiryId} className="">Quotation</Link></li>
                                <li><Link to={"/employee/inquiry/timeline/dashboard/" + inquiryId} className="">Timeline</Link></li>
                                <li><Link to={"/employee/inquiry/mails/dashboard/" + inquiryId} className="active">Mails</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">
                        </div>
                    </div>

                </div>
                
            </div>
            <MailBoxComponent/>



            <div id="selector"></div>


        </div>
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}