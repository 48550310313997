export const msalConfig = {
    auth: {
      clientId: "e164cc20-77ad-4cac-9f98-5c1a28ced1e0",
      authority: "https://login.microsoftonline.com/29b7383c-8cdc-440e-a425-3652a5dcd106", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri:"https://www.proconnect.edbrix.info/employee/mailBox",
      // redirectUri:"http://localhost:3000/employee/mailBox",
    },
    cache: { 
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read","Mail.Read","Mail.Send"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
      graphMeAllMessages: "https://graph.microsoft.com/v1.0/me/messages?$select=id,createdDateTime,subject,bodyPreview,hasAttachments,from&top=25",
      graphMeInboxMessages: "https://graph.microsoft.com/v1.0/me/mailFolders/Inbox/messages?$select=id,createdDateTime,subject,bodyPreview,hasAttachments,from&top=25",
      graphMeSendItemsMessages: "https://graph.microsoft.com/v1.0/me/mailFolders/SentItems/messages?$select=id,createdDateTime,subject,bodyPreview,hasAttachments,from&top=25",
      graphMeSingleMessages: "https://graph.microsoft.com/v1.0/me/messages",
      graphMeSendMessages: "https://graph.microsoft.com/v1.0/me/sendMail"
  };


  export async function callMsGraph(accessToken,endPoint,method="GET",requestBody="") {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    let options= [];
    if(method==="GET")
    {
       options = {
          method: method,
          headers: headers
      };
    }
    else
    {
      options = {
          method: method,
          headers: headers,
          body: JSON.stringify(requestBody)
      };
    }

    return fetch(endPoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}