import React, { useState } from 'react'
import LeftSideBar from '../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../components/common/employee/topnav.component';
import { Link, useParams } from "react-router-dom";
import MyReportICON from '../../../assets/svg/MyReport.svg';
import Bussiness from "../../../assets/svg/connectpro/business-people-casual-meeting 1.png";
import Select from 'react-select';
import { Calendar } from 'primereact/calendar';




export const Report = () => {

    const [showCreatePopUp,setShowCreatePopUp] = useState("");

  return (
    <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <div className="pl-16 dashboard-main-wrapper setting-wrapper" x-data="{tab: 1}">
                <div className="p-5">
            <div className="flex  grid flex-row flex-wrap flex-1  gap-4 mb-5 mt-5 align-center 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 setting-wrapper" >
            <div className="bg-[#EEF8F4]  dark:bg-[#2A2E32] py-3 px-6 reportshadow rounded-lg mb-3">
            <Link onClick={() => {
                setShowCreatePopUp(1)
            }}>
                <div className="flex  items-center justify-center">
                    <img src={MyReportICON} alt="" className='w-16' />
                </div>
                <div className="flex items-center justify-center mt-5">
                    <h3 className="text-[20px] text-[#101828] dark:text-white font-medium">Standard Report </h3>
                </div>
            </Link>
        </div>
        </div>
        <div id="selector" className={showCreatePopUp === 1 ? 'overlay' : ''}></div>
            {
                showCreatePopUp === 1 ?
                    <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
                        <div >
                            <img src={Bussiness} alt="" style={{ width: "50vw" }} />
                            <div className="absolute right-0 left-0 top-0 w-full text-white">
                                <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                                    <div className="text-md"><h6 className='text-[white]'>Reports</h6></div>
                                    <div><Link onClick={() => setShowCreatePopUp(0)} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                                </div>

                                <div className="px-6 mt-8">
                                    <span>
                                        <div className="text-sm text-[white]">Filters</div>
                                        <div className="text-2xl">Standard Reports
                                        </div>
                                    </span>
                                </div>

                            </div>

                            <div className="py-2 px-5 mt-4  height-custom">

                                <div>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Time Period</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex  justify-evenly gap-3'>
                                    <div className='grow w-20'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex items-center font-medium dark:text-white">Start Year</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select "}
                                        className="my-react-select-container "
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                      <div className='grow w-20'>
                                                    <label className='text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex items-center font-medium dark:text-white'>Start Date  
                                                        </label>
                                                    <Calendar placeholder="Select  " className=""   id="icon" showIcon />
                                                </div>
                                    <div  className='grow w-20'>
                                    <label className='text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex items-center font-medium dark:text-white'> End Date    
                                                        </label>
                                                    <Calendar placeholder="Select  " className=""   id="icon" showIcon />
                                    </div>
                                    </div>

                                    </div>
                                    </div>

                                    <div className='mt-3'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Geography</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Origin Country</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    <div  className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex items-center font-medium dark:text-white">Destination Country</label>
                                     <Select
                                        
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                  
                                    <div  className='grow'>
                                    <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium w-auto flex items-center font-medium dark:text-white">Port of Origin</label>
                                     <Select
                                        
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container w-full col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>

                                    <div  className='grow'>
                                    <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium w-auto flex items-center font-medium dark:text-white">Destination Port</label>
                                     <Select
                                        
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container w-full col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    </div>
                                    

                                    </div>
                                    </div>
                                    
                                    <div className='mt-3'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Customers</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Customer Name</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                  

                                   
                                    </div>
                                    

                                    </div>
                                    </div>

                                    <div className='mt-3'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Sales Person</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Sales Person Name</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                  

                                   
                                    </div>
                                    

                                    </div>
                                    </div>

                                    <div className='mt-3'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Cargo Type</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Cargo Type</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                  

                                   
                                    </div>
                                    

                                    </div>
                                    </div>

                               

                                    <div className='mt-3'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Service Type</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Service Type</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    </div>
                                    </div>
                                    </div>

                                    <div className='mt-3'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Freight Forwarder</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Freight Forwarder</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    </div>
                                    </div>
                                    </div>

                                    <div className='mt-3 mb-20'>
                                 <div className='border p-3 shadow-md  rounded-t-lg  '>
                                  <h3 className='text-[#101828] dark:text-[#ffff] text-[18px] font-medium '>Status</h3>
                                  </div>
                                  <div className='border shadow-md  '>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    <div className='grow'>
                                  <label className="text-[#344054] dark:text-[#ffff] text-[13px] font-medium flex-wrap flex items-center font-medium dark:text-white">Status</label>
                                    <Select
                
                                        // options={financialYearDropdown}
                                        // onChange={e=>{setFinancialYearName(e)}}
                                        placeholder={"Select"}
                                        className="my-react-select-container col-span-2"
                                        classNamePrefix="my-react-select"
                                    />
                                    </div>
                                    </div>
                                    <div className='p-3 bg-[#F9FAFB] dark:bg-[#252423]  dark:border-[#252423]  flex justify-between gap-3'>
                                    </div>
                                    </div>
                                    </div>


                                  
                                </div>

                            </div>
                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                <div className="flex justify-end gap-3">
                          
                                    <div><Link onClick={() => setShowCreatePopUp(0)}  className="bg-[#fff] border border-[#C6CBD2] rounded-md text-sm  px-4 py-2"
                                    >Cancel</Link></div>
                                    <div><Link to="/employee/createreport" 
                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Generate Report</Link>
                                    </div>
                                </div>
                            </div>

                        </div>

                     : null
            }
        </div>
        </div>
    </div>
  )
}
