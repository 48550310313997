import React, { useEffect, useState } from 'react';
import { ValidateEmployeeRole } from "../../components/helper/validateRole";
import LeftSideBar from '../../components/common/employee/leftnav.component';
import TopNavBar from '../../components/common/employee/topnav.component';
import EmpLogin from "../../setup/auth/emp.component";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { MailBoxComponent } from './inquiry/mails/components/MailBoxComponent';

export default function MailBox() {

    const [isAuthenticated, userHasAuthenticated] = useState(true);
    
    
    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }

        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);
    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />
            <MailBoxComponent/>
        </div>

    ) : (
        <EmpLogin sessionExpired={1} />
    );
}



