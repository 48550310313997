
import React, { useEffect, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import ValidateEmail from "../../../../../components/common/ValidateEmail";
import { Google_API_KEY } from '../../../../../components/helper/enum';
import { getArea, getCity, getCountry, getState, getZipCode } from '../../../../../components/helper/getAutoCompleteAddress';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { Employee, Roles } from "../../../../../models";
import EmpLogin from "../../../../../setup/auth/emp.component";
import Select from 'react-select';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames } from '../../../../../components/helper/commonfunctions';
import defaultProfilePath from '../../../../../assets/images/defaultProfile.jpg'
import { UploadFileOnS3, GetAWSObjectURL } from '../../../../../components/helper/aws';


export default function CreateEmployee() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editEmployeeId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [employeeIDForEdit, setEmployeeIDForEdit] = useState("");
    let navigateTo = useNavigate();

    //Employee Info
    const [roleName, setRoleName] = useState("");
    const [roleId, setRoleId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [parentEmployeeName, setParentEmployeeName] = useState("");
    const [parentEmployeeId, setParentEmployeeId] = useState("");
    const [employeeId, setEmployeeId] = useState("");

    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [profilePicPath, setProfilePicPath] = useState("");
    const [updateProfilePicPath, setUpdateProfilePicPath] = useState("");
    const [localPicturePath, setLocalPicturePath] = useState("");

    //lists
    const [roles, setRoles] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    //Create New Employee
    const saveEmployee = async () => {
        if (roleId === '') { toast.error('Please select Role.'); return; }
        else if (firstName === '') { toast.error('Please enter First Name.'); return; }
        else if (lastName === '') { toast.error('Please enter Last Name.'); return; }
        else if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            const original = await DataStore.query(Employee, editEmployeeId);
            await DataStore.save(
                Employee.copyOf(original, (updated) => {
                    updated.role_id = roleId;
                    updated.firstName = firstName;
                    updated.lastName = lastName;
                    updated.email = email;
                    updated.parentEmployeeId = parentEmployeeId ? parentEmployeeId : "";
                    updated.address1 = addressOne ? addressOne : "";
                    updated.address2 = addressTwo ? addressTwo : "";
                    updated.phoneNo = phoneNo;
                    updated.city = city;
                    updated.zip = zipCode;
                    updated.state = state;
                    updated.country = country;
                    updated.employeeId = employeeId;
                })
            );
            toast.success('Updated');
            updateProfilePicture(original.id)
            if (profilePicPath === "") { goBack() }
        } else {
            const checkDuplicateEmail = await DataStore.query(Employee, (c) => c.email.eq(email));
            console.log(checkDuplicateEmail.length)
            if (checkDuplicateEmail.length == 1) {
                toast.error('Email already exists.');
                setPleaseWaitButton(false);
            } else {
                //Save Employee
                await DataStore.save(
                    new Employee({
                        "role_id": roleId,
                        "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "parentEmployeeId": parentEmployeeId,
                        "address1": addressOne,
                        "address2": addressTwo,
                        "phoneNo": phoneNo,
                        "city": city,
                        "zip": zipCode,
                        "state": state,
                        "country": country,
                        "employeeId": employeeId,
                    })
                ).then((response) => {
                    toast.success('Added');
                    updateProfilePicture(response.id)
                    { goBack() }
                })
                if (profilePicPath === "") { goBack() }
            }
        }
    }


    const updateProfilePicture = async (employeeId) => {

        const fileName = 'public/employee/' + employeeId + '/' + profilePicPath.name;
        setLocalPicturePath(fileName)
        if (profilePicPath) {
            if (isEditMode) {
                UploadFileOnS3(profilePicPath, fileName).then(async () => {
                    const original = await DataStore.query(Employee, employeeId);
                    await DataStore.save(
                        Employee.copyOf(original, (updated) => {
                            updated.profilePic = fileName;
                        })
                    );
                    { goBack() }
                }
                )
            }
            else {
                UploadFileOnS3(profilePicPath, fileName).then(async () => {
                    const original = await DataStore.query(Employee, employeeId);
                    await DataStore.save(
                        Employee.copyOf(original, (updated) => {
                            updated.profilePic = fileName;
                        })
                    )
                }).catch(error => toast.error(error));
            }
        }
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }


    async function BindExistingData() {

        // Edit existing record
        if (editEmployeeId != null) {
            setEmployeeIDForEdit(editEmployeeId);
            const original = await DataStore.query(Employee, editEmployeeId);
            if (original != null) {
                setEditMode(true);
                if (original.profilePic) {
                    let getlogoPath = await GetAWSObjectURL(original.profilePic);
                    setLocalPicturePath(getlogoPath)
                }
                setUpdateProfilePicPath(original.profilePic)
                var roleResponses = await DataStore.query(Roles, original.role_id);
                setRoleName({ label: roleResponses.name, value: roleResponses.id })
                setRoleId(roleResponses.id)

                setFirstName(original.firstName);
                setLastName(original.lastName);
                setEmail(original.email);
                if (original.parentEmployeeId !== "") {
                    var parentEmployeeResponses = await DataStore.query(Employee, (c) => c.id.eq(original.parentEmployeeId));
                    if (parentEmployeeResponses.length > 0) {
                        setParentEmployeeName({ label: parentEmployeeResponses[0].firstName + " " + parentEmployeeResponses[0].lastName, value: parentEmployeeResponses.id })
                        setParentEmployeeId(original.parentEmployeeId);
                    } else {

                        setParentEmployeeName({ label: "Select", value: "" })
                    }
                }
                setAddressOne(original.address1);
                setAddressTwo(original.address2);
                setPhoneNo(original.phoneNo);
                setCity(original.city)
                setZipCode(original.zip);
                setState(original.state);
                setCountry(original.country);
                setEmployeeId(original.employeeId)
            }
            else {
                toast.error('Invalid record.');
                goBack()
            }
        }
    }

    //Get Address
    const onPlaceSelected = (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = getCity(addressArray),
            area = getArea(addressArray),
            country = getCountry(addressArray),
            zipCode = getZipCode(addressArray),
            state = getState(addressArray);
        // Set these values in the state.
        setCountry(country)
        setState(state)
        setZipCode(zipCode)
        setCity(city)
        setAddressOne(address)
    };

    const browseProfilePic = () => {
        document.getElementById('fileProfilePic').click()
    }

    async function changeProfilePicture(e) {
        let file = e.target.files[0];
        setProfilePicPath(file)
        setUpdateProfilePicPath(file)
        setLocalPicturePath(URL.createObjectURL(file))
    }

    useEffect(() => {
        onLoad();

        async function BindList() {
            //Roles List
            var roleResponses = await DataStore.query(Roles, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            var myRoleArray = []; myRoleArray = ConvertResponseForSelect(roleResponses); setRoles(myRoleArray);

            //Employee List
            var employeeResponses = await DataStore.query(Employee, Predicates.ALL, {
                sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
            });
            var myEmployeeArray = []; myEmployeeArray = ConvertResponseForSelectTwoNames(employeeResponses); setEmployeeList(myEmployeeArray);
        }
        BindList()
        BindExistingData();

    }, []);

    const goBack = () => {
        setPleaseWaitButton(false);
        navigateTo("/employee/settings/employee");
    }

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />

                <div className="pl-16">
                    <div
                        className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Employee
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    First Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(firstName)} onChange={e => setFirstName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter First Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Last Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(lastName)} onChange={e => setLastName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter Last Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    Role<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label>
                                                </label>

                                                <Select
                                                    value={roleName}
                                                    options={roles}
                                                    onChange={e => {
                                                        setRoleName(e)
                                                        setRoleId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    Reporting Manager
                                                </label>

                                                <Select
                                                    value={parentEmployeeName}
                                                    options={employeeList}
                                                    onChange={e => {
                                                        setParentEmployeeName(e)
                                                        setParentEmployeeId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Email <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label> </label>
                                                <input disabled={isEditMode ? true : false} value={email} onChange={e => setEmail(e.target.value)} type="text"
                                                    className={isEditMode ? "disabled bg-[#e1e1e1] text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" : "text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"}
                                                    placeholder="Enter Email" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Phone No
                                                </label>
                                                <PhoneInput
                                                    enableSearch
                                                    buttonStyle={{ background: 'transparent', borderWidth: 0 }}
                                                    inputStyle={{ borderWidth: 0, fontSize: 16, background: 'transparent' }}
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-1 mt-1 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                    country={'us'}
                                                    value={phoneNo}
                                                    onChange={(phone) => setPhoneNo("+" + phone)}
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 1
                                                </label>
                                                <Autocomplete
                                                    defaultValue={addressOne}
                                                    apiKey={Google_API_KEY}
                                                    onPlaceSelected={(place) => onPlaceSelected(place)}
                                                    options={{
                                                        types: ["establishment"],
                                                        componentRestrictions: { country: ["fr", "ch", "be", "us", "in"] },
                                                    }}
                                                    className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 2
                                                </label>
                                                <input
                                                    value={addressTwo}
                                                    onChange={(e) => setAddressTwo(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Address 2"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    City
                                                </label>
                                                <input
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="City"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Zip Code
                                                </label>
                                                <input
                                                    value={zipCode}
                                                    onChange={(e) => setZipCode(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Zip Code"
                                                />
                                            </div>



                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Country
                                                </label>
                                                <CountryDropdown
                                                    value={country}
                                                    onChange={(val) => setCountry(val)}
                                                    className="rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    State
                                                </label>
                                                <RegionDropdown
                                                    country={country}
                                                    value={state}
                                                    onChange={(val) => setState(val)}
                                                    className="rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    defaultOptionLabel="Select State"
                                                />
                                            </div>
                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Employee Id</label>
                                                <input value={(employeeId)} onChange={e => setEmployeeId(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Employee Id" />
                                            </div>

                                            <div className="relative mb-4">
                                            </div>

                                            <div className="mt-2 col-span-2 text-center mb-5">

                                                <img className="mt-5 m-auto border border-[#029046] dark:border-[#000]" alt="" src={localPicturePath ? localPicturePath : profilePicPath ? profilePicPath : defaultProfilePath} style={myProfileImageStyle} />
                                                <button
                                                    onClick={browseProfilePic}
                                                    className=" mt-2 py-1 px-3  text-[#029046] transition ease-in duration-200 text-[14px] text-center font-normal  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#029046] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF] "
                                                >
                                                    {showPleaseWaitButton ? "Please wait..." : "Browse Picture"}
                                                    <input hidden id="fileProfilePic" type="file" onChange={changeProfilePicture} />
                                                </button>
                                            </div>

                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link to="/employee/settings/employee" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Cancel</Link>
                                            <button onClick={() => saveEmployee()} className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{showPleaseWaitButton ? 'Please wait...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}

const myProfileImageStyle = {
    borderRadius: "50%",
    width: '100px',
    height: '100px',
    marginTop: "5px"

};
