import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import attach_icon from "../../../assets/svg/connectpro/attach_icon.svg"
import { MultiSelect } from 'primereact/multiselect';
import { Menu } from "@headlessui/react";
import { toast } from 'react-toastify';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { FreightForwarder, InquiryBidRule, FreightForwarderQuestion, InquiryBid, BidStatus, Units, Inquiry, InquiryContainers, Country, ContainerType, InquiryCargoType, InquiryPort } from '../../../models';
import Bussiness from "../../../assets/svg/connectpro/business-people-casual-meeting 1.png"
import { INQUIRY_INFORMATION_FIELD_NAMES, INQUIRY_REQUESTED_INFORMATION_FIELD_NAMES } from '../../../components/helper/enum';
import close_icon from "../../../assets/svg/connectpro/close_icon.svg"
import add_icon from "../../../assets/svg/connectpro/add_icon.svg"
import { GetAWSObjectURL, UploadFileOnS3 } from '../../../components/helper/aws';
import { ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import { reactLocalStorage } from 'reactjs-localstorage';
import uniqid from 'uniqid';
import moment from 'moment';

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { callMsGraph, loginRequest, graphConfig } from "../../../authConfig";

export const InquiryBidsComponent = (props) => {

    const { inquiryId } = useParams();

    //Validate User Logged In
    const isMicrosoftAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const [requestedDetailsData, setRequestedDetailsData] = useState([]);
    const [isInvitePopUp, setInvitePopUp] = useState(0);
    const [isInviteRequestedDetails, setInviteRequestedDetails] = useState(0);
    const [inquiryInfoData, setInquiryInfoData] = useState([]);
    const [mailDescription, setMailDescription] = useState('');
    const [mailSubject, setMailSubject] = useState('');
    const [forworderName, setForwerdName] = useState([]);
    const [isInviteInformation, setInviteInformation] = useState(0);
    const [frightForworders, setFrightForworders] = useState([]);
    const [attachmentFiles, setAttachmentFiles] = useState([]);
    const [invitationInformationJSON, setInvitationInformationJSON] = useState([]);
    const [checkedInquiryInfo, setCheckedInquiryInfo] = useState([]);

    const [checkedRequestDetails, setCheckedRequestDetails] = useState([]);
    const [checkedInquiryDetails, setCheckedInquiryDetails] = useState([]);

    const [requestedDetailsJSON, setRequestedDetailsJSON] = useState([]);
    const [attachmentId, setAttachmentId] = useState();
    const [allFreightForwarderQuestions, setAllFreightForwarderQuestions] = useState([]);
    const [inquiryOriginPort, setInquiryOriginPort] = useState('');
    const [inquiryDestinationPort, setInquiryDestinationPort] = useState('');
    const [popupTitle, setPopupTitle] = useState('Bid Invitation');
    const [allInquiryInfo, setAllInquiryInfo] = useState([]);

    const [docList, setDocList] = useState([]);
    const [docFinalList, setDocFinalList] = useState([]);
    const [allFreightForwarders, setAllFreightForwarders] = useState([]);


    const handleChange = async (event) => {

    };

    const handleChangeRequest = async (event) => {

    };



    //upload attachments
    async function changeAttachment(e) {
        let file = e.target.files;

        console.log('fiile :', file)

        setDocList([])


        // setAttachmentFiles(file)

        if (file.length > 1) {
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": inquiryId,
                    "file": file[i],
                    "filename": file[i].name
                }
                docFinalList.push(data);
            }
        }
        else {
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": inquiryId,
                    "file": file[i],
                    "filename": file[i].name
                }
                docFinalList.push(data);
            }
        }

        let finalData = [...docFinalList]
        setDocList(finalData)
        // setAttachmentFiles(finalData)
    }

    //Remove Document from array
    const onClickRemoveDocuments = (index) => {
        let data = [...docFinalList];
        data.splice(index, 1)
        setDocFinalList(data)
        setDocList(data)
    }



    //Send Bid 
    const sendInvitation = async () => {

        var inquiryContainerData = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId));
        console.log("inquiryContainerData :", inquiryContainerData);

        let mailTableData = [];
        for (var i = 0; i < inquiryContainerData.length; i++) {
            var containerTypeData = await DataStore.query(ContainerType, (c) => c.id.eq(inquiryContainerData[i].containerTypeId))
            var containerUnitData = await DataStore.query(Units, (c) => c.id.eq(inquiryContainerData[i].unitI))
            var inquirySignDetails = await DataStore.query(Units, inquiryContainerData[i].dimentionId)


            let data = {
                count: inquiryContainerData[i].count,
                type: containerTypeData[0].name,
                size: (inquiryContainerData[i].length) ? inquiryContainerData[i].length + '*' + inquiryContainerData[i].width + '*' + inquiryContainerData[i].height + " " + inquirySignDetails.sign : '-',
                totalWeight: inquiryContainerData[i].totalWeight + ' ' + containerUnitData[0].name
            }

            mailTableData.push(data);
        }



        console.log("aaaaaaa=>" + forworderName);

        console.log("Forwarder=>" + forworderName.length);



        if (forworderName == 0) { toast.error('Please Select Freight forwarder.'); return; }
        else if (mailSubject === '') { toast.error('Please Enter Subject.'); return; }
        else if (mailDescription === '') { toast.error('Please Enter Description.'); return; }



        setPleaseWaitButton(true);

        forworderName.map(v => (




            //Send Inquiry Bid
            DataStore.save(
                new InquiryBid({
                    "freightForwarderId": v,
                    "emailSubject": mailSubject,
                    "emailDescription": mailDescription,
                    "inquiryId": inquiryId,
                    "bidRequestedByEmployeeId": inquiryId,
                    "bidStatus": BidStatus.INVITED,
                    "inquiryInformation": invitationInformationJSON,
                    "requesterInformation": requestedDetailsJSON,
                    "attachmentId": attachmentId,
                })
            ).then((response) => {

                console.log("RES=>" + JSON.stringify(response));

                //get email of each selected freight forwarder
                let freightForwarderEmail;
                let freightForwarderName;

                allFreightForwarders.map((item) => {
                    if (item.id === response.freightForwarderId) {
                        freightForwarderEmail = item.email;
                        freightForwarderName = item.name;
                    }
                })

                //let emailTemplate = '<p style="text-align:center"><span style="font-size:22px">Bid</span></p>  <p><span style="font-size:18px">Hello BlueDart,</span></p>  <p>&nbsp;</p>  <p>Please find below inquiry.</p>  <p>&nbsp;</p>  <table border="1" cellpadding="1" cellspacing="1" style="width:500px"> 	<tbody> 		<tr> 			<td style="border-bottom:1px solid #000000">Description</td> 			<td style="border-bottom:1px solid #000000; text-align:center">Quantity</td> 			<td style="border-bottom:1px solid #000000; text-align:right">Total</td> 		</tr> 		<tr> 			<td>Item 1</td> 			<td style="text-align:center">1</td> 			<td style="text-align:right">$1,000</td> 		</tr> 		<tr> 			<td>Item 2</td> 			<td style="text-align:center">1</td> 			<td style="text-align:right">$500</td> 		</tr> 		<tr> 			<td>&nbsp;</td> 			<td style="text-align:center"><strong>Total</strong></td> 			<td style="text-align:right"><strong>$1,500</strong></td> 		</tr> 	</tbody> </table>  <p>&nbsp;</p>  <p>&nbsp;</p> <p>Please <a href="http://localhost:3000/inquirybid/' + response.id + '">click here</a> to process further.</p><p>Thanks</p>  <p>ProConnect Team</p>';

                var htmlTmp1 = '<p><span style="font-size:18px">Dear ' + freightForwarderName + ',</span></p><p>Please provide your quote the earliest Door to Port By Air.</p><table ><tbody><tr></tr>';

                mailTableData.map((item, i) => {
                    htmlTmp1 += `<tr>
                        <td style="text-align:left">${i + 1}</td>
                        <td style="text-align:left">${item.count}</td>
                        <td style="text-align:left">${item.type}</td>
                        <td style="text-align:left">${item.size}</td>
                        <td style="text-align:left">${item.totalWeight}</td>
                        </tr>`;

                })

                htmlTmp1 += '</table ><div style="line-height: 5px;margin-top: 5%;"><span style="font-family:Calibri">TOTAL NET WT : 76.00 KGS</span><p>TOTAL GROSS WT : 90.00 KGS</p></div><p>Commodity – Battery</p><div><p style="font-weight:bold;text-decoration:underline;line-height: 0;margin-top: 6%;">Collection Address :</p><p style="margin-right: 44%"> Sarah International Flat no A70 Sr No 61/62/63, Oxford Village,Phase-III, BL-A5, Wanowarie, Pune-411040 Maharashtra. India  Contact Person - Mr Kutbuddin.S Mobile # 9822068176 sarahinternational2017@gmail.com </p></div><p style="font-size:11pt; font-family: Cambria;color: #1f497d;">ALL RATES MENTIONED ABOVE EXCLUDE INSURANCE, DUTY AND VAT, IF ANY, AT ACTUALS</p><p>Please <a href="https://proconnect.edbrix.info/inquirybid/' + response.id + '">click here</a> to process further.</p><p>Thanking You</p><p>' + reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')
                + '</p><div style="color:#da0000;font-family:Calibri;">PROCONNECT SUPPLY CHAIN LOGISTICS LLC</div><div>Plot No. S 30902, Jebel Ali Free Zone South P.O. Box 262674, Dubai, U.A.E.</div><div>Mobile: +971 0508983221</div><div>Toll free number: 600575755</div></tbody >';

                console.log('template :', htmlTmp1)
                sendEmailToFrightForward(htmlTmp1, freightForwarderEmail);

            })
        ))

        props.closePopUp();
        setForwerdName([]);
        setMailSubject('')
        setMailDescription('')
        setPleaseWaitButton(false);
        props.bindList();

        uploadAttachments();


        toast.success('Invitation Sent');

    }

    async function sendEmailToFrightForward(emailTemplate, freightForwarderEmail) {

        if (isMicrosoftAuthenticated) {
            var customQueryURL = graphConfig.graphMeSendMessages;
            var requestBody = {
                "message": {
                    "subject": mailSubject,
                    "body": {
                        "contentType": "html",
                        "content": emailTemplate
                    },
                    "toRecipients": [
                        {
                            "emailAddress": {
                                "address": freightForwarderEmail
                            }
                        }
                    ],
                }
            };
            instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            }).then((response) => {
                callMsGraph(response.accessToken, customQueryURL, "POST", requestBody).then(async (currentResponse) => {
                });
            });
        }
    }

    const actionsOnRoles = (row) => {
        return (
            <React.Fragment >
                <div className="flex justify-center gap-8 ">
                    <Link onClick={() => {
                        console.log('pppppp :' + row.Value);
                        removeInquiryInfo(row.Value);
                    }}  ><img src={close_icon} /></Link>
                </div>

            </React.Fragment>
        );
    }

    const openInquiryInfoPopup = async () => {

        setPopupTitle('Inquiry Information');
        let infoDataArray = [...checkedInquiryInfo];

        var serviceData = [

            {
                "id": "S",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.S
            },
            {
                "id": "O",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.O
            },
            {
                "id": "D",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.D
            },
            {
                "id": "C",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.C
            },
            {
                "id": "M",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.M
            },
            {
                "id": "P",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.P
            },
            {
                "id": "CD",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.CD
            },
            {
                "id": "OI",
                "Value": INQUIRY_INFORMATION_FIELD_NAMES.OI
            },

            // {
            //     "Value": <div className=''> <input type="checkbox" onChange={handleChange} value="OI" id="serviceDataOI" className="relative top-0.5 h-4 w-4 border-[#C6CBD2] rounded-lg" />
            //         <label htmlFor='serviceDataOI' className="ml-1 text-md font-normal text-[#101828]">{INQUIRY_INFORMATION_FIELD_NAMES.OI}</label>
            //     </div>
            // },
        ]

        let requestData = [];

        serviceData.map((item) => {

            if (checkedInquiryDetails.includes(item.id))
                var checked = 'checked';
            else
                var checked = '';


            let data = {
                "Value": <div className=''> <input type="checkbox" defaultChecked={checked} onChange={handleChangeRequest} value={item.id} id={item.id} className="relative top-0.5 h-4 w-4 border-[#C6CBD2] rounded-lg" />
                    <label htmlFor={item.id} className="ml-1 text-md font-normal text-[#101828]">{item.Value}</label>
                </div>
            }

            requestData.push(data);

        })

        setAllInquiryInfo(requestData);

        // setServiceDataArray()
        setInviteInformation(1)
    }

    const openRequestPopup = async () => {

        setPopupTitle('Requested Details');

        console.log(attachmentId);
        //get freight forwarder data
        var allFreightForwarderQuestionsArray = await DataStore.query(FreightForwarderQuestion);
        let requestData = [];
        //freight forwarder questions display
        allFreightForwarderQuestionsArray.map((item) => {

            if (checkedRequestDetails.includes(item.id))
                var checkded = 'checked';
            else
                var checkded = '';



            let data = {
                "Value": <div className=''> <input type="checkbox" defaultChecked={checkded} onChange={handleChangeRequest} value={item.id} id={item.id} className="relative top-0.5 h-4 w-4 border-[#C6CBD2] rounded-lg" />
                    <label htmlFor={item.id} className="ml-1 text-md font-normal text-[#101828]">{item.question}</label>
                </div>
            }

            requestData.push(data);

        })
        setAllFreightForwarderQuestions(requestData);


        setInviteRequestedDetails(1)
    }

    //Send Bid 
    const onClickToMail = async (e) => {

        let array = [];
        array.push(e.value);
        console.log('array :', array)
    }

    // Save data in Reqeusted Details List
    const saveRequestedDetails = async () => {
        console.log("Testing==>" + checkedRequestDetails);

        var array = []
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')
        console.log("checkedRequestDetails--")


        if (checkboxes.length == 0) {
            setRequestedDetailsData([]);
            setCheckedRequestDetails([]);
        }

        for (var i = 0; i < checkboxes.length; i++) {

            console.log('11111 :', checkboxes[i].value)

            array.push(checkboxes[i].value)

            if (i === checkboxes.length - 1) {
                setCheckedRequestDetails(array);
            }
        }

        // console.log(array);

        // console.log("Testing==>" + checkedInquiryInfo);

        let myArray = [];

        for (var i = 0; i < array.length; i++) {
            var enumData = array[i]


            var freightForwarderQuestionsDetails = await DataStore.query(FreightForwarderQuestion, enumData);

            //console.log("freightForwarderQuestionsDetails==="+JSON.stringify(freightForwarderQuestionsDetails));

            var title = freightForwarderQuestionsDetails ? freightForwarderQuestionsDetails.question : '';

            let data = {
                "Value": title,
                "Action": '<div className="flex gap-8">',
            }
            myArray.push(data);
            if (i === array.length - 1) {
                setRequestedDetailsData(myArray)
            }
        }
        // console.log("inquiryInfoData----"+ JSON.stringify(inquiryInfoData))

        var requestedDetailsJSON = (JSON.stringify(Object.assign({}, array)));
        setRequestedDetailsJSON(requestedDetailsJSON);

        console.log(JSON.stringify(requestedDetailsJSON));
        setInviteRequestedDetails(0);
        setPopupTitle('Bid Invitation')
    }

    //Save data in information list
    const saveInquiryInformation = async () => {

        var array = []
        var checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

        console.log('checkboxes :', checkboxes)

        if (checkboxes.length == 0) {
            // setRequestedDetailsData([]);
            setCheckedInquiryDetails([]);
        }

        for (var i = 0; i < checkboxes.length; i++) {

            console.log('0000000000 :', checkboxes[i].value)

            array.push(checkboxes[i].value)

            if (i === checkboxes.length - 1) {
                setCheckedInquiryDetails(array);
            }
        }


        let myArray = [];

        for (var i = 0; i < array.length; i++) {
            var enumData = array[i]

            let data = {
                "Value": INQUIRY_INFORMATION_FIELD_NAMES[enumData],
                "Action": '<div className="flex gap-8">',
            }
            myArray.push(data);
            if (i === array.length - 1) {
                setInquiryInfoData(myArray)
            }
        }
        // console.log("inquiryInfoData----"+ JSON.stringify(inquiryInfoData))

        var inquiryInformationJSON = (JSON.stringify(Object.assign({}, array)));
        setInvitationInformationJSON(inquiryInformationJSON);
        setInviteInformation(0);
        setPopupTitle('Bid Invitation')
    }

    const removeInquiryInfo = (info) => {

        console.log("All Displayed Info :", inquiryInfoData)
        console.log("All Displayed Request :", requestedDetailsData)
        console.log("Removed Info :", info)

        inquiryInfoData.map((item) => {
            if (item.Value === info) {
                const index = inquiryInfoData.indexOf(item);
                console.log('index is :', index)
                if (index > -1) { // only splice array when item is found
                    inquiryInfoData.splice(index, 1); // 2nd parameter means remove one item only

                    checkedInquiryDetails.splice(index, 1);
                }
            }
        })

        console.log('checkedInquiryDetails :', checkedInquiryDetails);

        requestedDetailsData.map((item) => {
            if (item.Value === info) {
                const index = requestedDetailsData.indexOf(item);
                console.log('index is :', index)
                if (index > -1) { // only splice array when item is found
                    requestedDetailsData.splice(index, 1); // 2nd parameter means remove one item only

                    checkedRequestDetails.splice(index, 1);
                }
            }
        })
        console.log('checkedRequestDetails :', checkedRequestDetails);

    }

    const uploadAttachments = async () => {

        let finalData = [...docFinalList]

        if (finalData.length > 0) {
            try {

                for (var i = 0; i < finalData.length; i++) {
                    const fileName = 'public/inquiry/' + inquiryId + '/bids/' + attachmentId + '/' + finalData[i].filename;
                    // console.log(fileName);
                    await UploadFileOnS3(finalData[i].file, fileName).catch(error => toast.error(error));
                }

            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
        else {
            console.log('no file');
        }
    }

    async function BindList() {

        var inquiryDetails = await DataStore.query(Inquiry, inquiryId);


        var frightForwordersData = await DataStore.query(FreightForwarder, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });

        setAllFreightForwarders(frightForwordersData);

        console.log("ff :", frightForwordersData)
        console.log("allFreightForwarders :", allFreightForwarders)

        if (frightForwordersData) {
            var myFrightForwaderArray = []; myFrightForwaderArray = ConvertResponseForSelect(frightForwordersData);
            setFrightForworders(myFrightForwaderArray);
        }

        console.log("ff l:", myFrightForwaderArray)


        var orginalPort = '-';
        var destinationPort = '-';


        // Origin Port name
        var portOriginPortDetails = await DataStore.query(InquiryPort, inquiryDetails.inquiryOriginPort)
        if (portOriginPortDetails) {
            orginalPort = portOriginPortDetails.name;
            setInquiryOriginPort(portOriginPortDetails.name)
        }
        console.log("Data", portOriginPortDetails)

        // Destination Port name
        var portDestinationPortDetails = await DataStore.query(InquiryPort, inquiryDetails.inquiryDestinationPort)
        if (portDestinationPortDetails) {
            destinationPort = portDestinationPortDetails.name;
            setInquiryDestinationPort(portDestinationPortDetails.name)


        }

        // Set default subject for mail
        setMailSubject('Inquiry ID: ' + reactLocalStorage.get('inquiryNo') + ' Request Quote for Service - Port of ' + orginalPort + ' to ' + destinationPort + '.')

        setAttachmentId(uniqid() + moment().format('HHmmSS'));
    }

    useEffect(() => {
        BindList();
    }, []);

    return (
        <div style={{ width: "50vw", animation: "popupDiv-slide-in 0.5s forwards" }} id="createRecordCont" className="sidenav dashboard-main-wrapper">
            <div >
                <img src={Bussiness} alt="" style={{ width: "50vw" }} />
                <div className="absolute right-0 left-0 top-0 w-full text-white">
                    <div className="flex justify-between px-6 py-2 bg-black/[0.5]">
                        <div className="text-md"><h6 className='text-[white]'>Bids</h6></div>
                        <div><Link onClick={() => props.closePopUp()} className="closebtn text-2xl leading-none removeClass">&times;</Link></div>
                    </div>

                    <div className="px-6 mt-8">
                        <span>
                            <Menu as="div" className="relative inline-block text-left">
                                <div className="flex items-center align-center">
                                    <Menu.Button className='flex items-center'>
                                    </Menu.Button>
                                </div>
                            </Menu>
                            <div className="text-sm text-[white]">New</div>
                            <div className="text-2xl">{popupTitle}
                            </div>
                        </span>
                    </div>

                </div>




                {/* Inquiry Info Popup Start */}

                {
                    isInviteInformation === 1 && isInviteRequestedDetails === 0
                        ?
                        <div>
                            <div className="pb-3  mb-10">
                                <DataTable
                                    value={allInquiryInfo}
                                    responsiveLayout="scroll">
                                    <Column field="Value" header="Select Fields" >
                                    </Column>
                                </DataTable>
                            </div>

                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => {
                                        setInviteInformation(0)
                                        setPopupTitle('Bid Invitation')
                                    }} className="bg-white border border-[#C6CBD2] dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                    >Cancel</Link></div>
                                    <div><Link onClick={() => saveInquiryInformation()}
                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Save</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {/* Inquiry Info Popup End */}

                {
                    isInviteInformation === 0 && isInviteRequestedDetails === 1
                        ?
                        <div>
                            <div className="pb-3  mb-10">
                                <DataTable
                                    value={allFreightForwarderQuestions}
                                    responsiveLayout="scroll"   >
                                    <Column field="Value" header="Add Requested Fields" ></Column>
                                </DataTable>
                            </div>

                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => {
                                        setInviteRequestedDetails(0)
                                        setPopupTitle('Bid Invitation')
                                    }} className="bg-white border border-[#C6CBD2] dark:text-[white] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                    >Cancel</Link></div>
                                    <div><Link onClick={() => saveRequestedDetails()}
                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">Save</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    isInviteRequestedDetails === 0 && isInviteInformation === 0 ?
                        <>
                            <div className="py-2 px-5  height-custom">

                                <div className="mb-5 mt-5">
                                    <div className='flex'>
                                        <h4 className="mb-2">To</h4>
                                        <div className='text-[#FF0000]'>*</div>
                                    </div>
                                    <MultiSelect
                                        placeholder='Select'
                                        filter={true}
                                        value={forworderName}
                                        options={frightForworders}
                                        onChange={(e) => {
                                            setForwerdName(e.value)
                                            onClickToMail(e)
                                        }}
                                        optionLabel="label"
                                        display="chip"
                                        style={{ width: "100%", marginLeft: "0px" }} />
                                </div>
                                <div className='flex'>
                                    <h4 className="mb-2 ">Subject</h4>
                                    <div className='text-[#FF0000]'>*</div>
                                </div>
                                {/* <div className="border p-3 border-[#C6CBD2] rounded-lg mb-5">
                                    <p>Enquiry ID: Request Quote for Service - Port of Origin to Destination</p>
                                </div> */}

                                <div className="relative mb-4">
                                    <input value={(mailSubject)} onChange={e => setMailSubject(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-nonefocus:border-gray-900 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Subject" />
                                </div>

                                <div className='flex'>
                                    <h4 className="mb-2 ml-1">Description</h4>
                                    <div className='text-[#FF0000]'>*</div>
                                </div>
                                <textarea onChange={e => setMailDescription(e.target.value)} cols="76" row="30" className="border h-40 w-full p-2 rounded-lg border-[#C6CBD2] mb-2" placeholder="Greeting for the day!!" value={(mailDescription)}></textarea>
                                <div className="pb-3 ">
                                    <p className="font-medium font-20 mb-5">Request you to please share your quotation for the following:</p>
                                </div>
                                <div className="p-3 shadow border-t-[2px]  flex justify-between">
                                    Inquiry Information
                                    <Link onClick={() => openInquiryInfoPopup()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2"
                                    ><i className='pi pi-plus-circle mr-2'></i>Add</Link>
                                </div>

                                <div className="pb-3  mb-10">
                                    <DataTable
                                        value={inquiryInfoData}

                                        responsiveLayout="scroll">
                                        {/* <Column field="Sr No" header="Sr No" ></Column> */}
                                        <Column field="Value" header="Fields" sortable></Column>
                                        <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column>
                                    </DataTable>
                                </div>

                                <div className="flex border-t-[2px] justify-between p-3 shadow">
                                    Requested Details

                                    <Link onClick={() => openRequestPopup()} className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2"
                                    ><i className='pi pi-plus-circle mr-2'></i>Add</Link>

                                </div>
                                <div className="pb-3  mb-2">
                                    <DataTable
                                        value={requestedDetailsData}
                                        responsiveLayout="scroll">
                                        {/* <Column field="Sr No" header="Sr No" sortable></Column> */}
                                        <Column field="Value" header="Fields" sortable></Column>
                                        <Column alignHeader={'center'} field='Action' header="Action" body={actionsOnRoles} ></Column>
                                    </DataTable>
                                </div>
                                <div className="pt-5 displaynone">
                                    <button className="border text-[#1B55AF] flex bg-[white] rounded-md p-2  border-[#1B55AF] pr-3 cursor-pointer">
                                        <input
                                            onChange={(e) => { changeAttachment(e,) }}
                                            multiple="multiple"
                                            type="file"
                                            className="cursor-pointer" />
                                        <img src={attach_icon} className="mr-3 img ml-2 " />Add Attachment</button>
                                </div>

                                {/* display all selected files */}
                                <div className="grid grid-cols-2 gap-3 mt-3">
                                    {
                                        docList.map((item, index) => {
                                            return (

                                                <div key={index} className='block mt-1'>
                                                    <div className='flex gap-2 border border-[#C6CBD2] p-2 rounded-lg viewfile justify-between '>
                                                        <div className='flex gap-2'>
                                                            <i className='mt-1 pi pi-check-circle'></i>
                                                            <div>
                                                                <p className='text-[15px] font-medium text-[#344054]'>{item.filename}</p>
                                                            </div>
                                                        </div>
                                                        <div className='flex gap-2'>
                                                            <i onClick={() => {
                                                                onClickRemoveDocuments(index)
                                                            }} className='pi pi-times-circle cursor-pointer relative top-1'></i>

                                                        </div>
                                                    </div>
                                                </div>

                                            )

                                        })
                                    }
                                </div>



                                {/* <label htmlFor="" className="text-[#344054] text-[14px] font-medium">
                                Start Date <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                </label></label>
                            <Calendar placeholder="End Date" className="mt-2" value={fromDate} onChange={(e) => setFromDate(e.value)} id="icon" showIcon /> */}
                                <div className="border border-[#E4E7EC] mt-5 p-2 mb-6">
                                    <h4 className="font-normal">Thank You</h4>
                                    <h2 className="text-md font-medium mb-2">{reactLocalStorage.get('loggedEmployeeFirstName') + ' ' + reactLocalStorage.get('loggedEmployeeLastName')}</h2>
                                    <h2 className="font-medium">ProConnect Supply Chain Logistics</h2>
                                    <p className="font-normal pb-4">Plot No. S 30902 Jebel Ali Free Zone South
                                        P.O.BOX 262674 , Dubai, U.A.E
                                        Mobile No: 123567890</p>
                                </div>
                            </div>

                            <div className="lg:absolute left-0 right-0 bottom-0 bg-[#E5F3EC] p-6  dark:bg-[#071008]">
                                <div className="flex justify-end gap-3">
                                    <div><Link onClick={() => props.closePopUp()} className="bg-white 
                                                    dark:text-[white] border border-[#C6CBD2] rounded-md text-sm px-4 py-2 removeClass dark:bg-[#4A5056]"
                                    >Cancel</Link></div>
                                    <div><Link onClick={() => sendInvitation()}
                                        className="bg-[#029046] border border-[#029046] rounded-md text-sm text-white px-4 py-2">{showPleaseWaitButton ? 'Please wait...' : 'Send'}</Link>
                                    </div>
                                </div>
                            </div>

                        </>
                        : null
                }

            </div>
        </div>
    )
}
