import { DataStore,Predicates, SortDirection } from "@aws-amplify/datastore";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import capitalizeFirst from '../../../../../components/common/capitalizeFirst';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import { Roles, Customer,SalesPerson,State, Country } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import ValidateEmail from "../../../../../components/common/ValidateEmail";

import { ConvertResponseForSelect, ConvertResponseForSelectTwoNames } from '../../../../../components/helper/commonfunctions';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { Google_API_KEY } from '../../../../../components/helper/enum';
import Autocomplete from "react-google-autocomplete";
import Select from 'react-select';
import PhoneInput from "react-phone-input-2";
import { getArea, getCity, getCountry, getState, getZipCode } from '../../../../../components/helper/getAutoCompleteAddress';
export default function CustomerCreate() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editCustomerId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    //const [salesPersonId, setsalesPersonId] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [salesPersonName, setSalesPersonName] = useState("");
    const [salesPersonId, setSalesPersonId] = useState("");
    const [stateName, setStateName] = useState("");
    const [stateId, setStateId] = useState("");
    const [states, setStates] = useState([]);

    const [countryName, setCountryName] = useState("");
    const [countryId, setCountryId] = useState("");
    const [countrys, setCountrys] = useState([]);


    //const [customerId, setCustomerId] = useState("");    
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [addressOne, setAddressOne] = useState("");
    const [addressTwo, setAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [salesPerson, setSalesPerson] = useState([]);
    const [customerIDForEdit, setCustomerIDForEdit] = useState("");
    let navigateTo = useNavigate();

    //Save Fields
    const [role, setRole] = useState("");

    //Create Record
    const saveCustomer = async () => {
         
         if (firstName === '') { toast.error('Please enter First Name.'); return; }
        else if (lastName === '') { toast.error('Please enter Last Name.'); return; }
        else if (salesPersonName === '') { toast.error('Please enter Salesperson.'); return; }
        else if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }
        else if (stateName === '') { toast.error('Please enter State.'); return; }
        else if (companyName === '') { toast.error('Please enter Company Name.'); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            const original = await DataStore.query(Customer, editCustomerId);
            await DataStore.save(
                Customer.copyOf(original, (updated) => {
                    //updated.name = capitalizeFirst(role);
                   // updated.role_id = roleId;
                    updated.firstName = firstName;
                    updated.lastName = lastName;
                    updated.email = email;
                    updated.salesPersonId = salesPersonId ? salesPersonId : "";
                    updated.address1 = addressOne ? addressOne : "";
                    updated.address2 = addressTwo ? addressTwo : "";
                    updated.phoneNo = phoneNo;
                    updated.city = city;
                    updated.zipCode = zipCode;
                    updated.stateId = stateId;
                   // updated.country = country;
                    updated.companyName = companyName;
                })
            );
        } else {
            //await DataStore.save(new Roles({ name: capitalizeFirst(role) }));
            const checkDuplicateEmail = await DataStore.query(Customer, (c) => c.email.eq(email));
            //console.log(checkDuplicateEmail.length)
            if (checkDuplicateEmail.length == 1) {
                toast.error('Email already exists.');
                setPleaseWaitButton(false);
            } else {
            await DataStore.save(
                new Customer({
            "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "salesPersonId": salesPersonId,
                        "address1": addressOne,
                        "address2": addressTwo,
                        "phoneNo": phoneNo,
                        "city": city,
                        "zipCode": zipCode,
                        "stateId": stateId,
                        //"country": country,
                        "companyName": companyName,
                    })
                    ).then((response) => {
                        toast.success('Added');
                        //if (profilePicPath) { updateProfilePicture(response.id) } else { goBack() }
                    })
                }
        }

        setPleaseWaitButton(false);
        navigateTo("/employee/settings/customers");
    };

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) { userHasAuthenticated(false); }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

   
    async function BindExistingData() {

        // Edit existing record
        if (editCustomerId != null) {
            setCustomerIDForEdit(editCustomerId);
            const original = await DataStore.query(Customer, editCustomerId);
            if (original != null) {
                setEditMode(true);             
                setFirstName(original.firstName);
                setLastName(original.lastName);
                setEmail(original.email);
                
                setAddressOne(original.address1);
                setAddressTwo(original.address2);
                setPhoneNo(original.phoneNo);
                setCity(original.city)
                setZipCode(original.zipCode);
                //setStates(original.stateId);
                //setCountry(original.country);
                setCompanyName(original.companyName);

                var salesResponses = await DataStore.query(SalesPerson, original.salesPersonId);
                //console.log(salesResponses);
                setSalesPersonName({ label: salesResponses.firstName+' '+salesResponses.lastName, value: salesResponses.id });
                setSalesPersonId(salesResponses.id);
                if(countryId){
                    //
                }else{
                    var statesResponses = await DataStore.query(State, original.stateId);
                    //console.log(statesResponses);
                    setStateName({ label: statesResponses.name, value: statesResponses.id });
                    setStateId(statesResponses.id);
                    var countryResponses = await DataStore.query(Country, statesResponses.countryId);
                    //console.log(statesResponses);
                    setCountryName({ label: countryResponses.name, value: countryResponses.id });
                    setCountryId(countryResponses.id);
                    //setCustomerId(original.id)
                }
            }
            else {
                toast.error('Invalid record.');
                goBack()
            }
        }
    }


    const goBack = () => {
        setPleaseWaitButton(false);
        navigateTo("/employee/settings/customers");
    }
     //Get Address
     const onPlaceSelected = (place) => {
        const address = place.formatted_address,
            addressArray = place.address_components,
            city = getCity(addressArray),
            area = getArea(addressArray),
            country = getCountry(addressArray),
            zipCode = getZipCode(addressArray),
            state = getState(addressArray);
        // Set these values in the state.
        setCountry(country)
        setState(state)
        setZipCode(zipCode)
        setCity(city)
        setAddressOne(address)
        console.log(addressArray);
    };


    useEffect(() => {
        onLoad();

        async function BindList() {
            //Sales List 
            var salesResponses = await DataStore.query(SalesPerson, Predicates.ALL, {
                sort: s => s.firstName(SortDirection.ASCENDING)
            });
            var mySalesArray = []; mySalesArray = ConvertResponseForSelectTwoNames(salesResponses); setSalesPerson(mySalesArray);


          

            var countryResponses = await DataStore.query(Country, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            var myCountryArray = []; myCountryArray = ConvertResponseForSelect(countryResponses); setCountrys(myCountryArray);

            
            var stateResponses = await DataStore.query(State, c => c.countryId.eq(countryId) , Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            var myStateArray = []; myStateArray = ConvertResponseForSelect(stateResponses); setStates(myStateArray);

            }
        BindList();
        BindExistingData();

    }, [countryId]);

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />
                <div className="pl-16">
                    <div className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white dark:bg-[#2A2E32] rounded-md shadow ">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Customer
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                    <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                        <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    First Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(firstName)} onChange={e => setFirstName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter First Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Last Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(lastName)} onChange={e => setLastName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter Last Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium ">
                                                    Sales Person<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label>
                                                </label>

                                                <Select
                                                    value={salesPersonName}
                                                    options={salesPerson}
                                                    onChange={e => {
                                                        setSalesPersonName(e)
                                                        setSalesPersonId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Email <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label> </label>
                                                <input disabled={isEditMode ? true : false} value={email} onChange={e => setEmail(e.target.value)} type="text" 
                                                    className={isEditMode ? "disabled bg-[#e1e1e1] text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" : "text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"}
                                                    placeholder="Enter Email" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Phone No
                                                </label>
                                                <PhoneInput
                                                    enableSearch
                                                    buttonStyle={{ background: 'transparent', borderWidth: 0 }}
                                                    inputStyle={{ borderWidth: 0, fontSize: 16, background: 'transparent' }}
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-1 mt-1 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                    country={'us'}
                                                    value={phoneNo}
                                                    onChange={(phone) => setPhoneNo("+" + phone)}
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 1
                                                </label>
                                                <Autocomplete
                                                    defaultValue={addressOne}
                                                    apiKey={Google_API_KEY}
                                                    onPlaceSelected={(place) => onPlaceSelected(place)}
                                                    options={{
                                                        types: ["establishment"],
                                                        componentRestrictions: { country: ["fr", "ch", "be", "us", "in"] },
                                                    }}
                                                    className="text-[16px] rounded-lg   flex-1 appearance-none border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 2
                                                </label>
                                                <input
                                                    value={addressTwo}
                                                    onChange={(e) => setAddressTwo(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Address 2"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    City
                                                </label>
                                                <input
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="City"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Zip Code
                                                </label>
                                                <input
                                                    value={zipCode}
                                                    onChange={(e) => setZipCode(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Zip Code"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Country
                                                </label>
                                                <Select
                                                    value={countryName}
                                                    options={countrys}
                                                    onChange={e => {
                                                        setCountryName(e)
                                                        setCountryId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />

                                                {/*<CountryDropdown
                                                    value={country}
                                                    onChange={(val) => setCountry(val)}
                                                    className="rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />*/}
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    State
                                                    <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label>
                                                </label>
                                                <Select
                                                    value={stateName}
                                                    options={states}
                                                    onChange={e => {
                                                        setStateName(e)
                                                        setStateId(e.value)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                               {/* <RegionDropdown
                                                    country={country}
                                                    value={state}
                                                    onChange={(val) => setState(val)}
                                                    className="rounded-lg border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                />*/}
                                            </div>                                            
                                            
                                            <div className="relative mb-4">
                                                <label
                                                    htmlFor=""
                                                    className="text-[#344054] text-[14px] font-medium "
                                                >
                                                    Company name
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#FF0000] text-[14px] font-medium"
                                                    >
                                                        *{" "}
                                                    </label>
                                                </label>
                                                <input
                                                    value={(companyName)}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                    type="text"
                                                    id=""
                                                    className="text-[16px] rounded-lg  flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] "
                                                    placeholder="Enter Company"
                                                />
                                            </div>
                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link
                                                to="/employee/settings/customers"
                                                className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#4A5056] dark:text-white"
                                            >
                                                Cancel
                                            </Link>
                                            <button
                                                onClick={() => saveCustomer()}
                                                className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md"
                                            >
                                                {showPleaseWaitButton ? "Please wait..." : "Submit"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}
