import React, { useEffect, useState } from 'react';
import { json, Link, useNavigate, useParams } from "react-router-dom";
import LeftSideBar from '../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../components/common/employee/topnav.component';
import { ValidateEmployeeRole } from "../../../components/helper/validateRole";
import EmpLogin from "../../../setup/auth/emp.component";
import Upload_Icon_Green from '../../../assets/svg/connectpro/upload_icon_green.svg';
import Add_Icon from '../../../assets/svg/connectpro/add_icon.svg';
import Close_Icon from '../../../assets/svg/connectpro/close_icon.svg';
import Upload_Icon from '../../../assets/svg/connectpro/upload_icon.svg';
import Select from 'react-select'
import { Customer, Inco, Country, State, Inquiry, InquirySource, InquiryServices, InquiryCargoType, Products, SalesPerson, MovementType, ContainerType, InquiryContainers, StuffingType, Currency, InquiryNotes, InquiryOriginServices, InquiryDestinationServices, InquiryDocument, Units, InquiryPort } from '../../../models';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { ConvertResponseForSelectTwoNames, ConvertResponseForSelect } from '../../../components/helper/commonfunctions';
import ValidateEmail from "../../../components/common/ValidateEmail";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { GetAWSObjectURL, UploadFileOnS3 } from '../../../components/helper/aws';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import AWS from 'aws-sdk'


export default function InquiryDashboard() {

    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);

    const navigateTo = useNavigate()
    const { inquiryId } = useParams();
    let isEditMode = reactLocalStorage.get('isInquiryEdit')

    //tabs 
    const [isBidsTab, setIsBidsTab] = useState(false);
    const [isQuotationTab, setIsQuotationTab] = useState(false);
    const [isTimelineTab, setIsTimelineTab] = useState(false);
    const [isMailsTab, setIsMailsTab] = useState(false);

    //popup 
    const [isRequestInformation, setIsRequestInformation] = useState(true);
    const [isBasicInformation, setIsBasicInformation] = useState(false);
    const [isMoreInformation, setIsMoreInformation] = useState(false);
    const [isNotesAndAttachment, setIsNotesAndAttachment] = useState(false);

    //______________________Requestor Information ___________________________//

    //List 
    const [requestorList, setRequestorList] = useState([]);
    const [salesPersonList, setSalesPersonList] = useState([]);
    const [inquirySourceList, setinquirySourceList] = useState([{
        "label": "WEBSITE",
        "value": "A512212",
    }]);

    //state
    const [requestorId, setRequestorId] = useState("");
    const [requestorName, setRequestorName] = useState("");
    const [salesPersonId, setSalesPersonId] = useState("");
    const [salesPersonName, setSalesPersonName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [requestorEmail, setRequestorEmail] = useState("");
    const [requestorContactNo, setRequestorContactNo] = useState("");
    const [dateOfEnquiry, setDateOfEnquiry] = useState("");
    const [inquirySource, setInquirySource] = useState();
    const [inquirySourceId, setInquirySourceId] = useState();
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [pinCode, setPinCode] = useState("");


    //onClick Requestor Dropdown
    const onRequestorSelectChange = async (e) => {

        setRequestorId(e.value)

        var selectedRequestorResponse = await DataStore.query(Customer, e.value);
        if (selectedRequestorResponse.stateId) {
            var stateResponse = await DataStore.query(State, selectedRequestorResponse.stateId);
            setState(stateResponse.name)
            if (stateResponse.countryId) {
                var countryResponse = await DataStore.query(Country, stateResponse.countryId);
                setCountry(countryResponse.name)
            } else { setCountry("") }
        } else { setState("") }

        setCompanyName(selectedRequestorResponse.companyName)
        setRequestorEmail(selectedRequestorResponse.email)
        setRequestorContactNo(selectedRequestorResponse.phoneNo)
        setAddress1(selectedRequestorResponse.address1)
        setAddress2(selectedRequestorResponse.address2)
        setCity(selectedRequestorResponse.city)
        setPinCode(selectedRequestorResponse.zipCode)
    }



    //Check Requestor Information is updated on Bids tab
    const checkInquiryRequestorInfo = async () => {
        if (requestorId === '') { toast.error('Please Select Requestor.'); return; }
        else if (salesPersonId === '') { toast.error('Please Select Sales Person.'); return; }
        else if (dateOfEnquiry === '') { toast.error('Please Select Date of Enquiry.'); return; }
        else if (!ValidateEmail(requestorEmail)) { toast.error('Invalid email.'); return; }
        navigateTo("/employee/inquiry/" + inquiryId)
    }

    //Update Requestor Information
    const updateRequestorInfo = async () => {

        if (requestorId === '') { toast.error('Please Select Requestor.'); return; }
        else if (salesPersonId === '') { toast.error('Please Select Sales Person.'); return; }
        else if (dateOfEnquiry === '') { toast.error('Please Select Date of Enquiry.'); return; }
        else if (requestorContactNo === '') { toast.error('Please Enter Contact Number.'); return; }
        else if (!ValidateEmail(requestorEmail)) { toast.error('Invalid email.'); return; }

        if (inquiryId) {
            const original = await DataStore.query(Inquiry, inquiryId);
            await DataStore.save(
                Inquiry.copyOf(original, (updated) => {
                    updated.customerId = requestorId;
                    updated.salesPersonId = salesPersonId;
                    updated.inquiryDate = moment(dateOfEnquiry).format();
                    updated.inquirySource = inquirySource === "WEBSITE" ? InquirySource.WEBSITE : InquirySource.WEBSITE;
                })
            );
        }

        setIsRequestInformation(false)
        setIsBasicInformation(true)
        setIsMoreInformation(false)
        setIsNotesAndAttachment(false)
    }

    //______________________Requestor Information ___________________________//


    //______________________Inquiry Basic Information ___________________________//

    //List 
    const [inquiryServicesList, setInquiryServicesList] = useState([]);
    const [cargoTypeList, setCargoTypeList] = useState([]);
    const [iNCUTermList, setINCUTermList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [movementTypeList, setMovementTypeList] = useState([]);

    //state
    const [inquiryServicesId, setInquiryServicesId] = useState("");
    const [inquiryServiceName, setInquiryServiceName] = useState("");
    const [origin, setOrigin] = useState("");
    const [originValue, setOriginValue] = useState("");
    const [destination, setDestination] = useState("");
    const [destinationValue, setDestinationValue] = useState("");
    const [cargoTypeId, setCargoTypeId] = useState("");
    const [cargoTypeName, setCargoTypeName] = useState("");
    const [movementTypeId, setMovementTypeId] = useState("");
    const [movementTypeName, setMovementTypeName] = useState("");
    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");
    const [productDetails, setProductDetails] = useState("");
    const [cargoReadyDate, setCargoReadyDate] = useState("");
    const [internationalReference, setInternationalReference] = useState("");
    const [contractDurationDate, setContractDurationDate] = useState("");
    const [inquiryClosingDate, setInquiryClosingDate] = useState("");
    const [sailingRangeDate, setSailingRangeDate] = useState("");

    //Container Details****************************************************************

    //Container Details list_____________
    const [containerTypeList, setContainerTypeList] = useState([]);

    //Unit list 
    const [unitList, setUnitList] = useState([]);

    //Container Details state_____________
    const [termsAndConditions, setTermsAndConditions] = useState("");
    const [inputFields, setInputFields] = useState([{
        "count": "",
        "type": {},
        "typeId": "",
        "size": "",
        "totalCargo": "",
        "unit": {},
        "unitId": "",
        "length": "",
        "width": "",
        "height": "",
        "dimension": "",
        "dimensionId": ""
    }])

    //onclick Add container details
    const onClickAddContainerDetails = async () => {

        let data = {
            "count": "",
            "type": {},
            "typeId": "",
            "size": "",
            "totalCargo": "",
            "unit": {},
            "unitId": "",
            "length": "",
            "width": "",
            "height": "",
            "dimension": "",
            "dimensionId": ""
        }
        setInputFields([...inputFields, data])
    }

    //onclick remove container details
    const onClickRemoveContainerDetails = (index) => {

        let data = [...inputFields];
        if (data.length > 1) {
            data.splice(index, 1)
            setInputFields(data)
        }
    }

    //onChange Count container
    const countHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["count"] = event.target.value;
        setInputFields(data)
    }

    //onSelect type container
    const typeHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["type"] = event
        data[index]["typeId"] = event.value
        setInputFields(data)
    }

    //onChange size container
    const sizeHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["size"] = event.target.value;
        setInputFields(data)
    }

    //onSelect type container
    const unitHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["unit"] = event
        data[index]["unitId"] = event.value
        setInputFields(data)
    }

    //onSelect dimensions
    const dimensionsHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["dimension"] = event
        data[index]["dimensionId"] = event.value
        setInputFields(data)
    }

    //onChange total container
    const totalCargoHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["totalCargo"] = event.target.value;
        setInputFields(data)
    }

    //onChange unit container
    const unitCargoHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["unit"] = event.target.value;
        setInputFields(data)
    }

    //onChange length
    const lengthHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["length"] = event.target.value;
        setInputFields(data)
    }
    //onChange width
    const widthHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["width"] = event.target.value;
        setInputFields(data)
    }
    //onChange height
    const heightHandleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index]["height"] = event.target.value;
        setInputFields(data)
    }

    //End Container Details****************************************************************

    //onclick origin
    const onclickOrigin = async (e) => {
        let currentItemId = []
        currentItemId.push(e.value)
        //set Country  List
        var inquiryPortList = await DataStore.query(InquiryPort, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });

        let inquiryPortIdArray = inquiryPortList.map((item) => item.id)
        let differenceArray = currentItemId.filter(x => !inquiryPortIdArray.includes(x)).concat(inquiryPortIdArray.filter(x => !currentItemId.includes(x)));
        let finalArray = []
        for (let i = 0; i < differenceArray.length; i++) {
            let totalFinancialYear = await DataStore.query(InquiryPort, differenceArray[i], {
                sort: s => s.name(SortDirection.ASCENDING)
            })
            finalArray.push(totalFinancialYear)

            // if (i === differenceArray.length - 1) {
            //     var myInquiryPortArray = []; myInquiryPortArray = ConvertResponseForSelect(finalArray);
            //     setDestinationList(myInquiryPortArray)
            // }

        }


    }

    //Update Inquiry Basic Information
    const updateInquiryBasicInfo = async () => {

        if (inquiryServicesId === '') { toast.error('Please Select Service.'); return; }

        else if (originCountry === '') { toast.error('Please Select Origin Country.'); return; }
        else if (destinationCountry === '') { toast.error('Please Select Destination Country.'); return; }

        else if (origin === '') { toast.error('Please Enter Origin Port.'); return; }
        else if (destination === '') { toast.error('Please Enter Destination Port.'); return; }
        else if (inquiryClosingDate === '') { toast.error('Please Enter Inquiry Closing Date.'); return; }
        if (inputFields.length) {
            for (let i = 0; i < inputFields.length; i++) {

                if (inputFields[i].count === '') { toast.error('Please Select Count.'); return; }
                if (inputFields[i].typeId === '') { toast.error('Please Select Type.'); return; }
                if (inputFields[i].totalCargo === '') { toast.error('Please Select Total Cargo Wt.'); return; }
                if (inputFields[i].unitId === '') { toast.error('Please Select Unit.'); return; }
            }
        }
        if (inquiryId) {
            const original = await DataStore.query(Inquiry, inquiryId);

            console.log("originaloriginal :", original);


            await DataStore.save(
                Inquiry.copyOf(original, (updated) => {
                    updated.inquiryServiceId = inquiryServicesId;

                    updated.originCountry = originCountry;
                    updated.destinationCountry = destinationCountry;

                    updated.inquiryOriginPort = origin;
                    updated.inquiryDestinationPort = destination;
                    updated.inquiryCargoTypeId = cargoTypeId;
                    updated.movementTypeId = movementTypeId;
                    updated.productId = productId;
                    updated.productDetails = productDetails;
                    updated.inquiryCargoReadyDate = cargoReadyDate === "" ? "1970-01-01Z" : moment(cargoReadyDate).format('YYYY-MM-DD');
                    updated.internationalRef = internationalReference;
                    updated.sailingRateDate = sailingRangeDate === "" ? "1970-01-01Z" : moment(sailingRangeDate).format('YYYY-MM-DD');
                    updated.contractDurationDate = contractDurationDate === "" ? "1970-01-01Z" : moment(contractDurationDate).format('YYYY-MM-DD');
                    updated.inquiryClosingDate = inquiryClosingDate === "" ? "1970-01-01Z" : moment(inquiryClosingDate).format('YYYY-MM-DD');
                    updated.termsConditions = termsAndConditions
                })
            ).then(async (response) => {

                //update Inquiry Containers
                var inquiryContainersResponse = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId));
                console.log("inquiryContainersResponse :", inquiryContainersResponse);


                if (inquiryContainersResponse.length > 0) {

                    await DataStore.delete(InquiryContainers, (c) => c.inquiryId.eq(inquiryId)).then(() => {
                        inputFields.map(async (currentItem) => {
                            await DataStore.save(
                                new InquiryContainers({
                                    "inquiryId": inquiryId,
                                    "count": currentItem.count,
                                    "containerTypeId": currentItem.typeId,
                                    "size": currentItem.size,
                                    "totalWeight": currentItem.totalCargo,
                                    "unitI": currentItem.unitId,
                                    "length": currentItem.length,
                                    "width": currentItem.width,
                                    "height": currentItem.height,
                                    "dimentionId": currentItem.dimensionId

                                })
                            ).then((result) => { })
                        })

                        setIsRequestInformation(false)
                        setIsBasicInformation(false)
                        setIsMoreInformation(true)
                        setIsNotesAndAttachment(false)

                    })
                }
                else {
                    inputFields.map(async (currentItem) => {
                        await DataStore.save(
                            new InquiryContainers({
                                "inquiryId": inquiryId,
                                "count": currentItem.count,
                                "containerTypeId": currentItem.typeId,
                                "size": currentItem.size,
                                "totalWeight": currentItem.totalCargo,
                                "unitI": currentItem.unitId,
                                "dimentionId": currentItem.dimensionId,
                                "length": currentItem.length,
                                "width": currentItem.width,
                                "height": currentItem.height,
                                "dimentionId": currentItem.dimensionId
                            })
                        ).then((result) => { })
                    })

                    setIsRequestInformation(false)
                    setIsBasicInformation(false)
                    setIsMoreInformation(true)
                    setIsNotesAndAttachment(false)
                }
            })
        }

    }

    //______________________Inquiry Basic Information ___________________________//


    //______________________Inquiry More Information ___________________________//

    //list
    const [stuffingTypeList, setStuffingTypeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [portList, setPortList] = useState([]);
    const [destinationCountryList, setDestinationCountryList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [originServiceList, setOriginServiceList] = useState([])
    const [destinationServiceList, setDestinationServiceList] = useState([])
    const [referenceRateType, setReferenceRateType] = useState([
        {
            "value": "4554",
            "label": "Price"
        },
        {
            "value": "4664",
            "label": "Container"
        }
    ]);


    //state
    const [originCountry, setOriginCountry] = useState("");
    const [originCountryValue, setOriginCountryValue] = useState("");
    const [destinationCountry, setDestinationCountry] = useState("");
    const [destinationCountryValue, setDestinationCountryValue] = useState("");
    const [stuffingId, setStuffingId] = useState("");
    const [stuffing, setStuffing] = useState("");
    const [deStuffingId, setDeStuffingId] = useState("");
    const [deStuffing, setDeStuffing] = useState("");
    const [requiredDestinationFreeDays, setRequiredDestinationFreeDays] = useState("");
    const [requiredOriginFreeDays, setRequiredOriginFreeDays] = useState("");
    const [preferredShippingLines, setPreferredShippingLines] = useState("");
    const [documents, setDocuments] = useState("");
    const [referenceRateOne, setReferenceRateOne] = useState("");
    const [currencyId, setCurrencyId] = useState("");
    const [currency, setCurrency] = useState("");
    const [referenceRateTypeName, setReferenceRateTypeName] = useState("");
    const [referenceRateTypeValue, setReferenceRateTypeValue] = useState("");


    //on check origin service
    const onClickOriginServiceCheck = (index, event) => {
        let data = [...originServiceList];
        data[index]["isCheck"] = !data[index]["isCheck"];
        setOriginServiceList(data)
    }

    //on check destination service
    const onClickDestinationServiceCheck = (index, event) => {
        let data = [...destinationServiceList];
        data[index]["isCheck"] = !data[index]["isCheck"];
        setDestinationServiceList(data)
    }

    //onclick origin country
    const onclickOriginCountry = async (e) => {
        let currentItemId = []
        currentItemId.push(e.value)
        //set Country  List
        var countryList = await DataStore.query(Country, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        let countryIdArray = countryList.map((item) => item.id)
        let differenceArray = currentItemId.filter(x => !countryIdArray.includes(x)).concat(countryIdArray.filter(x => !currentItemId.includes(x)));
        let finalArray = []
        for (let i = 0; i < differenceArray.length; i++) {
            let totalFinancialYear = await DataStore.query(Country, differenceArray[i], {
                sort: s => s.name(SortDirection.ASCENDING)
            })
            finalArray.push(totalFinancialYear)

            if (i === differenceArray.length - 1) {
                var myCountryArray = []; myCountryArray = ConvertResponseForSelect(finalArray);
                setDestinationCountryList(myCountryArray)
            }

        }

        //country change port value set null
        setOriginValue("");

        //fetch specific county wise ports
        console.log("selected country :", e.value)
        var portList = await DataStore.query(InquiryPort, (c) => c.countryId.eq(e.value), Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        console.log("selected country ports :", portList)

        var myPortArray = []; myPortArray = ConvertResponseForSelect(portList);
        setPortList(myPortArray)

    }

    //onclick destination

    const onclickDestinationCountry = async (e) => {


        setDestinationValue("");

        //fetch specific county wise ports
        console.log("selected country dest :", e.value)
        var portList = await DataStore.query(InquiryPort, (c) => c.countryId.eq(e.value), Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        console.log("selected country destbports :", portList)

        var myPortArray = []; myPortArray = ConvertResponseForSelect(portList);
        setDestinationList(myPortArray)

    }



    //Update Inquiry More Information
    const updateInquiryMoreInfo = async () => {
        // if (originCountry === '') { toast.error('Please Select Origin Country.'); return; }
        // else if (destinationCountry === '') { toast.error('Please Select Destination Country.'); return; }

        if (inquiryId) {
            const original = await DataStore.query(Inquiry, inquiryId);
            await DataStore.save(
                Inquiry.copyOf(original, (updated) => {
                    // updated.originCountry = originCountry;
                    // updated.destinationCountry = destinationCountry;
                    updated.stuffingTypeId = stuffingId;
                    updated.deStuffingTypeId = deStuffingId;
                    updated.requiredDestinationFreedays = requiredDestinationFreeDays;
                    updated.requiredOriginFreedays = requiredOriginFreeDays;
                    updated.preferredShippingLines = preferredShippingLines;
                    updated.referenceRate = referenceRateOne;
                    updated.referenceRateCurrencyId = currencyId;
                    updated.referenceRateForWhom = referenceRateTypeName;
                })
            ).then(async (response) => {
                //save origin list
                var inquiryOriginResponse = await DataStore.query(InquiryOriginServices, (c) => c.inquiryId.eq(inquiryId));

                if (inquiryOriginResponse.length > 0) {

                    await DataStore.delete(InquiryOriginServices, (c) => c.inquiryId.eq(inquiryId)).then(() => {
                        //save origin list
                        originServiceList.map(async (origin) => {
                            if (origin.isCheck === true) {
                                await DataStore.save(
                                    new InquiryOriginServices({
                                        "inquiryId": inquiryId,
                                        "inquiryServiceId": origin.inquiryOriginServiceId,
                                    })
                                );
                            }
                        })
                    })
                }
                else {
                    //save origin list
                    originServiceList.map(async (origin) => {
                        if (origin.isCheck === true) {
                            await DataStore.save(
                                new InquiryOriginServices({
                                    "inquiryId": inquiryId,
                                    "inquiryServiceId": origin.inquiryOriginServiceId,
                                })
                            );
                        }
                    })
                }

                //save destination list
                var inquiryDestinationResponse = await DataStore.query(InquiryDestinationServices, (c) => c.inquiryId.eq(inquiryId));
                if (inquiryDestinationResponse.length > 0) {

                    await DataStore.delete(InquiryDestinationServices, (c) => c.inquiryId.eq(inquiryId)).then(() => {
                        //save destination list
                        destinationServiceList.map(async (origin) => {
                            if (origin.isCheck === true) {
                                await DataStore.save(
                                    new InquiryDestinationServices({
                                        "inquiryId": inquiryId,
                                        "inquiryServiceId": origin.inquiryDestinationServiceId,
                                    })
                                );
                            }
                        })
                    })
                }
                else {
                    //save destination list
                    destinationServiceList.map(async (origin) => {
                        if (origin.isCheck === true) {
                            await DataStore.save(
                                new InquiryDestinationServices({
                                    "inquiryId": inquiryId,
                                    "inquiryServiceId": origin.inquiryDestinationServiceId,
                                })
                            );
                        }
                    })
                }

                setIsRequestInformation(false)
                setIsBasicInformation(false)
                setIsMoreInformation(false)
                setIsNotesAndAttachment(true)



            })


        }
    }

    //______________________Inquiry More Information ___________________________//


    const [documentList, setDocumentList] = useState([{
        "itemId": 0,
        "inquiryId": inquiryId,
        "title": "",
        "filename": "",
        "file": "",

    }])

    //const [InquiryDocument, setInquiryDocument] = useState([]);
    const [inquiryDocList, setInquiryDocList] = useState([]);
    const [docInquiryFinalList, setDocInquiryFinalList] = useState([]);
    const [tempArray, setTempArray] = useState([]);


    //onclick Add documents
    const onClickAddDocument = async () => {

        let data = {
            "itemId": 0,
            "inquiryId": inquiryId,
            "title": "",
            "filename": "",
            "file": "",
        }
        setDocumentList([...documentList, data])
    }

    //
    //onclick remove inquiry document
    const onClickRemoveInquiryDoc = (index) => {
        let data = [...documentList];

        console.log("data :", data);

        if (data.length > 1) {
            data.splice(index, 1)
            setDocumentList(data)
        }
        console.log(index);
    }

    //onChange documents
    const titleHandleFormChange = (index, event) => {
        let data = [...documentList];
        data[index]["title"] = event.target.value;

        setDocumentList(data)
    }

    const inquiryHandleFormChange = (index, event) => {
        let data = [...documentList];
        data[index]["title"] = event.target.value;

        setDocumentList(data)
    }

    //select Inquiry Documents
    async function selectInquiryDocuments(e, index) {
        let file = e.target.files;
        let data = [...documentList];
        // data[index]["file"] = e.target.value;

        data[index]["file"] = e.target.files[0];
        data[index]["filename"] = file[0].name;
        docInquiryFinalList.push(data);
        setDocumentList(data)
        console.log(documentList);

        let finalData = [...docInquiryFinalList]
        setInquiryDocList(finalData)

        //console.log(inquiryDocList)
    }

    const bindDocumentListing = async () => {

        var inquiryDocsResponse = await DataStore.query(InquiryDocument, (c) => c.inquiryId.eq(inquiryId));
        let inqDocFinalData = []
        if (inquiryDocsResponse.length > 0) {
            for (let i = 0; i < inquiryDocsResponse.length; i++) {
                let data = {
                    "itemId": inquiryDocsResponse[i].id,
                    "title": inquiryDocsResponse[i].title,
                    "filename": inquiryDocsResponse[i].fileName,
                    "file": inquiryDocsResponse[i].fileName,
                }
                inqDocFinalData.push(data)
            }
            setDocumentList(inqDocFinalData)
            setDocInquiryFinalList(inqDocFinalData)
        }
    }
    //Remove Document from array
    const onClickRemoveInquiryDocuments = async (e, id) => {
        var inquiryDocsResponse = await DataStore.query(InquiryDocument, (c) => c.inquiryId.eq(inquiryId));
        console.log(inquiryDocsResponse)
        if (inquiryDocsResponse.length > 1) {
            const objDeleteRole = await DataStore.query(InquiryDocument, id);
            alertify.confirm('Confirmation', 'Do you want to delete this Document?', async function () {
                DataStore.delete(objDeleteRole);
                bindDocumentListing();
                toast.error("Deleted");
            }
                , function () { });
        } else { toast.error("You cannot delete all documents"); }

    }
    //
    //Update Document And Attachment
    const updateDocumentAttachment = async () => {
        let temp = [];
        var inquiryDocumentResponse = await DataStore.query(InquiryDocument, (c) => c.inquiryId.eq(inquiryId));

        if (inquiryDocumentResponse.length > 0) {

            await DataStore.delete(InquiryDocument, (c) => c.inquiryId.eq(inquiryId)).then(() => {
                documentList.map(async (currentItem, index) => {
                    if (currentItem.title !== "") {
                        await DataStore.save(
                            new InquiryDocument({
                                "inquiryId": inquiryId,
                                "title": currentItem.title,
                                "fileName": currentItem.filename,

                            })
                        )

                        let item = { "file": currentItem.file };
                        temp[index] = item;
                        setTempArray(temp)
                    }
                })
            })
        }
        else {
            documentList.map(async (currentItem, index) => {
                if (currentItem.title !== "") {
                    await DataStore.save(
                        new InquiryDocument({
                            "inquiryId": inquiryId,
                            "title": currentItem.title,
                            "fileName": currentItem.filename,
                        })
                    )

                    let item = { "file": currentItem.file };
                    temp[index] = item;
                    setTempArray(temp)
                }
            })
        }

    }

    const uploadDocumentAttachment = async () => {

        let temp = [];
        var inquiryDocResponses = await DataStore.query(InquiryDocument, (c) => c.inquiryId.eq(inquiryId));

        let inquiryDocArray = inquiryDocResponses;
        if (inquiryDocArray.length > 0) {
            for (var i = 0; i < inquiryDocArray.length; i++) {
                let item = { "id": inquiryDocArray[i].id };
                temp[i] = item;
            }
        }
        console.log('tempArray:', documentList)
        console.log('temp:', temp)

        // //upload documents
        if (documentList.length > 0) {
            try {

                for (var i = 0; i < documentList.length; i++) {

                    const fileName = 'public/inquiry/' + inquiryId + '/documents/' + temp[i].id + '/' + documentList[i].filename;
                    console.log("filename :", fileName);
                    console.log(documentList[i].file);
                    await UploadFileOnS3(documentList[i].file, fileName).catch(error => toast.error(error));
                }

            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
        else {
        }

        navigateTo("/employee/inquiry/" + inquiryId)
    }


    //______________________Inquiry Notes And Attachment ___________________________//

    const [docList, setDocList] = useState([]);
    const [docFinalList, setDocFinalList] = useState([]);
    const [notesList, setNotesList] = useState([{
        "notes": ""
    }])

    const [docUploadedFileList, setdocUploadedFileList] = useState([]);
    //onclick Add notes
    const onClickAddNotes = async () => {

        let data = {
            "notes": ""
        }
        setNotesList([...notesList, data])
    }





    //onclick remove notes
    const onClickRemoveNotes = (index) => {
        let data = [...notesList];
        if (data.length > 1) {
            data.splice(index, 1)
            setNotesList(data)
        }
    }

    //onChange notes
    const notesHandleFormChange = (index, event) => {
        let data = [...notesList];
        data[index]["notes"] = event.target.value;
        setNotesList(data)
    }

    //select Documents
    async function selectDocuments(e) {
        let file = e.target.files;
        console.log(file)
        setDocList([])
        if (file.length > 1) {
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": inquiryId,
                    "file": file[i],
                    "filename": file[i].name
                }
                docFinalList.push(data);
            }
        }
        else {
            for (let i = 0; i < file.length; i++) {
                let data = {
                    "documentId": inquiryId,
                    "file": file[i],
                    "filename": file[i].name
                }
                docFinalList.push(data);
            }
        }
        let finalData = [...docFinalList]
        setDocList(finalData)
    }


    const onClickRemoveDocumentsPath = (filePath, index) => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
            signatureVersion: 'v4',
            region: process.env.REACT_APP_AWS_Region,
            secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey
        });
        const params = {
            Bucket: process.env.REACT_APP_AWS_Bucket,
            Key: filePath
        };


        let dataUploaded = [...docUploadedFileList];
        alertify.confirm('Confirmation', 'Do you want to delete this Document?', async function () {
            s3.deleteObject(params, function (err, data) {
                if (err) { console.log(err, err.stack); } // an error occurred
                else {
                    console.log(data);

                    dataUploaded.splice(index, 1)
                    setdocUploadedFileList(dataUploaded)
                    toast.error("Deleted");
                }// successful response
            });

        }, function () { });



        //getNotesAttachment()

    }


    //Remove Document from array
    const onClickRemoveDocuments = (index) => {
        let data = [...docFinalList];
        data.splice(index, 1)
        setDocFinalList(data)
        setDocList(data)
    }

    //Update Notes And Attachment
    const updateNotesAndAttachment = async () => {

        var inquiryNotesResponse = await DataStore.query(InquiryNotes, (c) => c.inquiryId.eq(inquiryId));

        if (inquiryNotesResponse.length > 0) {

            await DataStore.delete(InquiryNotes, (c) => c.inquiryId.eq(inquiryId)).then(() => {
                notesList.map(async (currentItem) => {
                    if (currentItem.notes !== "") {
                        await DataStore.save(
                            new InquiryNotes({
                                "inquiryId": inquiryId,
                                "note": currentItem.notes,
                            })
                        )
                    }
                })
            })
        }
        else {
            notesList.map(async (currentItem) => {
                if (currentItem.notes !== "") {
                    await DataStore.save(
                        new InquiryNotes({
                            "inquiryId": inquiryId,
                            "note": currentItem.notes,
                        })
                    )
                }
            })
        }

        // //upload documents
        if (docList.length > 0) {
            try {

                for (var i = 0; i < docList.length; i++) {
                    const fileName = 'public/inquiry/' + inquiryId + '/documents/' + docList[i].filename;
                    // console.log(fileName);
                    await UploadFileOnS3(docList[i].file, fileName).catch(error => toast.error(error));
                }

            } catch (error) {
                console.log("Error uploading file: ", error);
            }
        }
        else {
        }

        getAllInquiryTabs()
        navigateTo("/employee/inquiry/bid/dashboard/" + inquiryId)
    }

    const getNotesAttachment = async () => {
        const fileName = 'public/inquiry/' + inquiryId + '/documents/';
        let filesData = [];
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
            signatureVersion: 'v4',
            region: process.env.REACT_APP_AWS_Region,
            secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey
        });
        const params = {
            Bucket: process.env.REACT_APP_AWS_Bucket,
            Delimiter: '/',
            Prefix: fileName
        };


        let finalFilesArray = [];

        s3.listObjects(params, function (err, data) {
            if (err) {
                //return 'There was an error viewing your album: ' + err.message
            } else {
                // return data.Contents;
                console.log(data.Contents, "<<<all content");
                //   //return data.Contents;

                data.Contents.forEach(function (obj, index) {
                    if (obj.Key !== '') {
                        console.log(obj.Key)
                        const filename = obj.Key;
                        let basename = filename.split('/');
                        basename = basename[basename.length - 1]
                        console.log(basename)
                        finalFilesArray.push({ filename: basename, path: filename })
                    }

                })
            }
        })

        //setDocList(finalFilesArray) 
        setdocUploadedFileList(finalFilesArray)

    }

    //______________________Inquiry Notes And Attachment ___________________________//


    //Load Existing Data
    async function loadExistingData() {

        if (inquiryId) {

            var inquiryResponse = await DataStore.query(Inquiry, inquiryId);

            //set requestor info
            var selectedRequestorResponse = [];
            if (inquiryResponse.customerId) {
                var selectedRequestorResponse = await DataStore.query(Customer, inquiryResponse.customerId);
                if (selectedRequestorResponse) {
                    setRequestorName({ "label": selectedRequestorResponse.firstName + ' ' + selectedRequestorResponse.lastName, "value": selectedRequestorResponse.id })
                    setRequestorId(selectedRequestorResponse.id)
                } else {
                    setRequestorName({ "label": "", "value": "" })
                    setRequestorId("")
                }
            } else {
                // setRequestorName({ "label": "", "value": "" })
                // setRequestorId("")
            }

            if (selectedRequestorResponse.stateId) {
                var stateResponse = await DataStore.query(State, selectedRequestorResponse.stateId);
                setState(stateResponse.name)
                if (stateResponse.countryId) {
                    var countryResponse = await DataStore.query(Country, stateResponse.countryId);
                    setCountry(countryResponse.name)
                } else { setCountry("") }
            } else { setState("") }


            setCompanyName(selectedRequestorResponse.companyName)
            setRequestorEmail(selectedRequestorResponse.email)
            setRequestorContactNo(selectedRequestorResponse.phoneNo)
            setAddress1(selectedRequestorResponse.address1)
            setAddress2(selectedRequestorResponse.address2)
            setCity(selectedRequestorResponse.city)
            setPinCode(selectedRequestorResponse.zipCode)
            if (inquiryResponse.inquirySource) {
                setInquirySource({ "label": inquiryResponse.inquirySource, "value": "A512212" })
                setInquirySourceId("A512212")
            }
            else {
                setInquirySource({ "label": "", "value": "" })
                setInquirySourceId("")
            }


            if (inquiryResponse.salesPersonId) {
                var salesPersonResponse = await DataStore.query(SalesPerson, inquiryResponse.salesPersonId);
                if (salesPersonResponse) {
                    setSalesPersonName({ "label": salesPersonResponse.firstName + ' ' + salesPersonResponse.lastName, "value": salesPersonResponse.id })
                    setSalesPersonId(salesPersonResponse.id)
                }
                else {
                    setSalesPersonName({ "label": "", "value": "" })
                    setSalesPersonId("")
                }
            }
            else {
                setSalesPersonName({ "label": "", "value": "" })
                setSalesPersonId("")
            }

            console.log('TODAY=>' + moment(inquiryResponse.inquiryDate).toDate());
            setDateOfEnquiry(moment(inquiryResponse.inquiryDate).toDate())

            //set basic info
            if (inquiryResponse.inquiryServiceId) {
                var inquiryServiceResponse = await DataStore.query(InquiryServices, inquiryResponse.inquiryServiceId);
                if (inquiryServiceResponse) {
                    setInquiryServiceName({ "label": inquiryServiceResponse.name, "value": inquiryServiceResponse.id })
                    setInquiryServicesId(inquiryServiceResponse.id)
                }
                else {
                    setInquiryServiceName({ "label": "", "value": "" })
                    setInquiryServicesId("")
                }
            }
            else {
                setInquiryServiceName({ "label": "", "value": "" })
                setInquiryServicesId("")
            }

            if (inquiryResponse.inquiryOriginPort) {
                var originResponse = await DataStore.query(InquiryPort, (c) => c.id.eq(inquiryResponse.inquiryOriginPort));
                if (originResponse) {
                    setOriginValue({ "label": originResponse[0].name, "value": originResponse[0].id })
                    setOrigin(originResponse[0].id)
                }
                else {
                    setOriginValue({ "label": "", "value": "" })
                    setOrigin("")
                }
            } else {
                setOriginValue({ "label": "", "value": "" })
                setOrigin("")
            }


            if (inquiryResponse.inquiryDestinationPort) {
                var destinationResponse = await DataStore.query(InquiryPort, (c) => c.id.eq(inquiryResponse.inquiryDestinationPort));
                if (destinationResponse) {
                    setDestinationValue({ "label": destinationResponse[0].name, "value": destinationResponse[0].id })
                    setDestination(destinationResponse[0].id)
                }
                else {
                    setDestinationValue({ "label": "", "value": "" })
                    setDestination("")
                }
            } else {
                setDestinationValue({ "label": "", "value": "" })
                setDestination("")
            }


            if (inquiryResponse.inquiryCargoTypeId) {
                var inquiryCargoTypeResponse = await DataStore.query(InquiryCargoType, inquiryResponse.inquiryCargoTypeId);
                if (inquiryCargoTypeResponse) {
                    setCargoTypeName({ "label": inquiryCargoTypeResponse.name, "value": inquiryCargoTypeResponse.id })
                    setCargoTypeId(inquiryCargoTypeResponse.id)
                }
                else {
                    setCargoTypeName({ "label": "", "value": "" })
                    setCargoTypeId("")
                }
            } else {
                setCargoTypeName({ "label": "", "value": "" })
                setCargoTypeId("")
            }

            if (inquiryResponse.movementTypeId) {
                var movementTypeResponse = await DataStore.query(MovementType, inquiryResponse.movementTypeId);
                if (movementTypeResponse) {
                    setMovementTypeName({ "label": movementTypeResponse.name, "value": movementTypeResponse.id })
                    setMovementTypeId(movementTypeResponse.id)
                }
                else {
                    setMovementTypeName({ "label": "", "value": "" })
                    setMovementTypeId("")
                }
            }
            else {
                setMovementTypeName({ "label": "", "value": "" })
                setMovementTypeId("")
            }

            //set dates
            let inquiryCargoReadyDate = inquiryResponse.inquiryCargoReadyDate === "1970-01-01" || inquiryResponse.inquiryCargoReadyDate === "1970-01-01Z" ? "" : moment(inquiryResponse.inquiryCargoReadyDate).toDate()
            let sailingRateDate = inquiryResponse.sailingRateDate === "1970-01-01" || inquiryResponse.sailingRateDate === "1970-01-01Z" ? "" : moment(inquiryResponse.sailingRateDate).toDate()
            let contractDurationDate = inquiryResponse.contractDurationDate === "1970-01-01" || inquiryResponse.contractDurationDate === "1970-01-01Z" ? "" : moment(inquiryResponse.contractDurationDate).toDate()
            let inquiryClosingDate = inquiryResponse.inquiryClosingDate === "1970-01-01" || inquiryResponse.inquiryClosingDate === "1970-01-01Z" ? "" : moment(inquiryResponse.inquiryClosingDate).toDate()

            setCargoReadyDate(inquiryCargoReadyDate)
            setInternationalReference(inquiryResponse.internationalRef)
            setSailingRangeDate(sailingRateDate)
            setContractDurationDate(contractDurationDate)
            setInquiryClosingDate(inquiryClosingDate)

            if (inquiryResponse.productId) {
                var productResponse = await DataStore.query(Products, inquiryResponse.productId);
                if (productResponse) {
                    setProductName({ "label": productResponse.name, "value": productResponse.id })
                    setProductId(productResponse.id)
                }
                else {
                    setProductName({ "label": "", "value": "" })
                    setProductId("")
                }
            } else {
                setProductName({ "label": "", "value": "" })
                setProductId("")
            }



            setProductDetails(inquiryResponse.productDetails)

            var inquiryContainersResponse = await DataStore.query(InquiryContainers, (c) => c.inquiryId.eq(inquiryId));
            let inquiryContainersFinalData = []
            if (inquiryContainersResponse.length > 0) {
                for (let i = 0; i < inquiryContainersResponse.length; i++) {

                    let type = ""
                    let typeId = ""
                    if (inquiryContainersResponse[i].containerTypeId) {
                        var containerTypeResponse = await DataStore.query(ContainerType, inquiryContainersResponse[i].containerTypeId);
                        if (containerTypeResponse) {
                            type = { "label": containerTypeResponse.name, "value": containerTypeResponse.id }
                            typeId = containerTypeResponse.id
                        }
                        else {
                            type = { "label": "", "value": "" }
                            typeId = ""
                        }
                    } else {
                        type = { "label": "", "value": "" }
                        typeId = ""
                    }

                    let unit = "";
                    let unitid = "";
                    let dimension = "";
                    let dimensionId = "";
                    if (inquiryContainersResponse[i].unitI) {
                        var unitResponse = await DataStore.query(Units, inquiryContainersResponse[i].unitI);
                        if (unitResponse) {
                            unit = { "label": unitResponse.name, "value": unitResponse.id }
                            unitid = unitResponse.id
                        }
                        else {
                            unit = { "label": "", "value": "" }
                            unitid = ""
                        }
                    } else {
                        unit = { "label": "", "value": "" }
                        unitid = ""
                    }

                    if (inquiryContainersResponse[i].dimentionId) {
                        var dimensionResponse = await DataStore.query(Units, inquiryContainersResponse[i].dimentionId);

                        if (dimensionResponse) {
                            dimension = { "label": dimensionResponse.name, "value": dimensionResponse.id }
                            dimensionId = dimensionResponse.id
                        }
                        else {
                            dimension = { "label": "", "value": "" }
                            dimensionId = ""
                        }
                    } else {
                        dimension = { "label": "", "value": "" }
                        dimensionId = ""
                    }


                    let data = {
                        "count": inquiryContainersResponse[i].count,
                        "type": type,
                        "typeId": typeId,
                        "size": inquiryContainersResponse[i].size,
                        "totalCargo": inquiryContainersResponse[i].totalWeight,
                        "unit": unit,
                        "unitId": unitid,
                        "dimension": dimension,
                        "dimensionId": dimensionId,
                        "length": inquiryContainersResponse[i].length,
                        "width": inquiryContainersResponse[i].width,
                        "height": inquiryContainersResponse[i].height
                    }
                    inquiryContainersFinalData.push(data)
                }
                setInputFields(inquiryContainersFinalData)
            }
            else {
                setInputFields([{
                    "count": "",
                    "type": {},
                    "typeId": "",
                    "size": "",
                    "totalCargo": "",
                    "unit": {},
                    "unitId": "",
                    "dimension": {},
                    "dimensionId": "",
                    "dimension": '',
                    "dimensionId": '',
                    "length": "",
                    "width": "",
                    "height": ""
                }])
            }


            setTermsAndConditions(inquiryResponse.termsConditions)

            //set more info
            if (inquiryResponse.originCountry) {
                var originCountryResponse = await DataStore.query(Country, (c) => c.id.eq(inquiryResponse.originCountry));
                if (originCountryResponse) {
                    setOriginCountryValue({ "label": originCountryResponse[0].name, "value": originCountryResponse[0].id })
                    setOriginCountry(originCountryResponse[0].id)
                }
                else {
                    setOriginCountryValue({ "label": "", "value": "" })
                    setOriginCountry("")
                }
            }
            else {
                setOriginCountryValue({ "label": "", "value": "" })
                setOriginCountry("")
            }

            if (inquiryResponse.destinationCountry) {
                var destinationCountryResponse = await DataStore.query(Country, (c) => c.id.eq(inquiryResponse.destinationCountry));
                if (destinationCountryResponse) {
                    setDestinationCountryValue({ "label": destinationCountryResponse[0].name, "value": destinationCountryResponse[0].id })
                    setDestinationCountry(destinationCountryResponse[0].id)
                }
                else {
                    setDestinationCountryValue({ "label": "", "value": "" })
                    setDestinationCountry("")
                }
            }
            else {
                setDestinationCountryValue({ "label": "", "value": "" })
                setDestinationCountry("")
            }

            if (inquiryResponse.stuffingTypeId) {
                var stuffingResponse = await DataStore.query(StuffingType, inquiryResponse.stuffingTypeId);
                if (stuffingResponse) {
                    setStuffing({ "label": stuffingResponse.name, "value": stuffingResponse.id })
                    setStuffingId(stuffingResponse.id)
                }
                else {
                    setStuffing({ "label": "", "value": "" })
                    setStuffingId("")
                }
            } else {
                setStuffing({ "label": "", "value": "" })
                setStuffingId("")
            }

            if (inquiryResponse.deStuffingTypeId) {
                var deStuffingResponse = await DataStore.query(StuffingType, inquiryResponse.deStuffingTypeId);
                if (deStuffingResponse) {
                    setDeStuffing({ "label": deStuffingResponse.name, "value": deStuffingResponse.id })
                    setDeStuffingId(deStuffingResponse.id)
                }
                else {
                    setDeStuffing({ "label": "", "value": "" })
                    setDeStuffingId("")
                }
            } else {
                setDeStuffing({ "label": "", "value": "" })
                setDeStuffingId("")
            }


            var originServiceListResponse = await DataStore.query(InquiryServices, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            let originServiceFinalData = []
            for (var i = 0; i < originServiceListResponse.length; i++) {

                var inquiryOriginServices = await DataStore.query(InquiryOriginServices, (c) => c.and(c => [
                    c.inquiryId.eq(inquiryId),
                    c.inquiryServiceId.eq(originServiceListResponse[i].id)
                ]));

                if (inquiryOriginServices.length > 0 && originServiceListResponse[i].id === inquiryOriginServices[0].inquiryServiceId) {
                    let data = {
                        "isCheck": true,
                        "inquiryOriginServiceId": originServiceListResponse[i].id,
                        "name": originServiceListResponse[i].name,
                    }
                    originServiceFinalData.push(data)
                }
                else {

                    let data = {
                        "isCheck": false,
                        "inquiryOriginServiceId": originServiceListResponse[i].id,
                        "name": originServiceListResponse[i].name,
                    }
                    originServiceFinalData.push(data)

                }
            }
            setOriginServiceList(originServiceFinalData)

            var destinationServiceListResponse = await DataStore.query(InquiryServices, Predicates.ALL, {
                sort: s => s.name(SortDirection.ASCENDING)
            });
            let destinationServiceFinalData = []
            for (var i = 0; i < destinationServiceListResponse.length; i++) {

                var inquiryDestinationServices = await DataStore.query(InquiryDestinationServices, (c) => c.and(c => [
                    c.inquiryId.eq(inquiryId),
                    c.inquiryServiceId.eq(destinationServiceListResponse[i].id)
                ]));

                if (inquiryDestinationServices.length > 0 && destinationServiceListResponse[i].id === inquiryDestinationServices[0].inquiryServiceId) {
                    let data = {
                        "isCheck": true,
                        "inquiryDestinationServiceId": destinationServiceListResponse[i].id,
                        "name": destinationServiceListResponse[i].name,
                    }
                    destinationServiceFinalData.push(data)
                }
                else {

                    let data = {
                        "isCheck": false,
                        "inquiryDestinationServiceId": destinationServiceListResponse[i].id,
                        "name": destinationServiceListResponse[i].name,
                    }
                    destinationServiceFinalData.push(data)

                }
            }
            setDestinationServiceList(destinationServiceFinalData)

            setRequiredDestinationFreeDays(inquiryResponse.requiredDestinationFreedays)
            setRequiredOriginFreeDays(inquiryResponse.requiredOriginFreedays)
            setPreferredShippingLines(inquiryResponse.preferredShippingLines)
            setReferenceRateOne(inquiryResponse.referenceRate)

            if (inquiryResponse.referenceRateCurrencyId) {
                var currencyResponse = await DataStore.query(Currency, inquiryResponse.referenceRateCurrencyId);
                if (currencyResponse) {
                    setCurrency({ "label": currencyResponse.name, "value": currencyResponse.id })
                    setCurrencyId(currencyResponse.id)
                }
                else {
                    // setCurrency({ "label": "", "value": "" })
                    setCurrency("")
                    setCurrencyId("")
                }
            } else {
                // setCurrency({ "label": "", "value": "" })
                setCurrency("")
                setCurrencyId("")
            }


            if (inquiryResponse.referenceRateForWhom) {
                let currentReferenceRateType = referenceRateType.filter((response) => response.label === inquiryResponse.referenceRateForWhom)
                if (currentReferenceRateType) {
                    setReferenceRateTypeName(currentReferenceRateType[0].label)
                    setReferenceRateTypeValue(currentReferenceRateType)
                }
                else {
                    setReferenceRateTypeName("")
                    setReferenceRateTypeValue("")
                }
            }
            else {
                setReferenceRateTypeName("")
                setReferenceRateTypeValue("")
            }



            //set notes and attachments
            var notesResponse = await DataStore.query(InquiryNotes, (c) => c.inquiryId.eq(inquiryId));
            let noteFinalData = []
            if (notesResponse.length > 0) {
                for (let i = 0; i < notesResponse.length; i++) {
                    let data = {
                        "notes": notesResponse[i].note
                    }
                    noteFinalData.push(data)
                }
                setNotesList(noteFinalData)
                getAllInquiryTabs()
            }
            else {
                setNotesList([{
                    "notes": ""
                }])
            }

            //set inquiryDocuments and attachments
            var inquiryDocsResponse = await DataStore.query(InquiryDocument, (c) => c.inquiryId.eq(inquiryId));
            let inqDocFinalData = []
            if (inquiryDocsResponse.length > 0) {
                for (let i = 0; i < inquiryDocsResponse.length; i++) {
                    let data = {
                        "itemId": inquiryDocsResponse[i].id,
                        "title": inquiryDocsResponse[i].title,
                        "filename": inquiryDocsResponse[i].fileName,
                        "file": inquiryDocsResponse[i].fileName,
                    }
                    inqDocFinalData.push(data)
                }
                setDocumentList(inqDocFinalData)
                console.log(documentList)
                getAllInquiryTabs()
            }
            else {
                setDocumentList([{
                    "itemId": 0,
                    "inquiryId": inquiryId,
                    "title": "",
                    "filename": "",
                    "file": "",
                }])
            }




        }

    }

    //get All tabs enable
    async function getAllInquiryTabs() {
        setIsBidsTab(true)
        setIsQuotationTab(true)
        setIsTimelineTab(true)
        setIsMailsTab(true)
    }
    //Bind List
    async function BindList() {

        //set Requestor List
        var requestorList = await DataStore.query(Customer, Predicates.ALL, {
            sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        });
        var myRequestorArray = []; myRequestorArray = ConvertResponseForSelectTwoNames(requestorList);
        setRequestorList(myRequestorArray)

        //set Sales Person List
        var salesPersonList = await DataStore.query(SalesPerson, Predicates.ALL, {
            sort: s => s.firstName(SortDirection.ASCENDING).lastName(SortDirection.ASCENDING)
        });
        var mySalesPersonArray = []; mySalesPersonArray = ConvertResponseForSelectTwoNames(salesPersonList);
        setSalesPersonList(mySalesPersonArray)

        //set InquiryServices List
        var inquiryServicesList = await DataStore.query(InquiryServices, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myInquiryServicesArray = []; myInquiryServicesArray = ConvertResponseForSelect(inquiryServicesList);
        setInquiryServicesList(myInquiryServicesArray)

        //set cargo Type List
        var cargoTypeList = await DataStore.query(InquiryCargoType, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myCargoTypeArray = []; myCargoTypeArray = ConvertResponseForSelect(cargoTypeList);
        setCargoTypeList(myCargoTypeArray)


        //set incu term List
        var incuTermList = await DataStore.query(Inco, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myincuTermArray = []; myincuTermArray = ConvertResponseForSelect(incuTermList);
        setINCUTermList(myincuTermArray);

        //set Product List
        var productList = await DataStore.query(Products, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myProductArray = []; myProductArray = ConvertResponseForSelect(productList);
        setProductList(myProductArray)

        //set Movement Type List
        var movementList = await DataStore.query(MovementType, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myMovementArray = []; myMovementArray = ConvertResponseForSelect(movementList);
        setMovementTypeList(myMovementArray)

        //set Stuffing Type List
        var stuffingTypeList = await DataStore.query(StuffingType, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myStuffingTypeArray = []; myStuffingTypeArray = ConvertResponseForSelect(stuffingTypeList);
        setStuffingTypeList(myStuffingTypeArray)




        //set Country  List
        var countryList = await DataStore.query(Country, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myCountryArray = []; myCountryArray = ConvertResponseForSelect(countryList);
        setCountryList(myCountryArray)

        //set Currency List
        var currencyList = await DataStore.query(Currency, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myCurrencyArray = []; myCurrencyArray = ConvertResponseForSelect(currencyList);
        setCurrencyList(myCurrencyArray)

        //set Container Type List
        var containerTypeList = await DataStore.query(ContainerType, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myContainerTypeArray = []; myContainerTypeArray = ConvertResponseForSelect(containerTypeList);
        setContainerTypeList(myContainerTypeArray)

        //set Unit List
        var unitList = await DataStore.query(Units, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myUnitArray = []; myUnitArray = ConvertResponseForSelect(unitList);
        setUnitList(myUnitArray)


        //set Origin Service List
        var originServiceListResponse = await DataStore.query(InquiryServices, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        let originServiceFinalData = []
        for (var i = 0; i < originServiceListResponse.length; i++) {
            let data = {
                "isCheck": false,
                "inquiryOriginServiceId": originServiceListResponse[i].id,
                "name": originServiceListResponse[i].name,
            }
            originServiceFinalData.push(data);
        }
        setOriginServiceList(originServiceFinalData)

        //set Destination Service List
        var destinationServiceListResponse = await DataStore.query(InquiryServices, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        let destinationServiceFinalData = []
        for (var i = 0; i < destinationServiceListResponse.length; i++) {
            let data = {
                "isCheck": false,
                "inquiryDestinationServiceId": destinationServiceListResponse[i].id,
                "name": destinationServiceListResponse[i].name,
            }
            destinationServiceFinalData.push(data);
        }
        setDestinationServiceList(destinationServiceFinalData)
    }

    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    useEffect(() => {
        onLoad();
        BindList();
        if (isEditMode) {
            loadExistingData()
            getNotesAttachment()

        }
    }, []);

    return isAuthenticated ? (
        <div className="pt-16">
            <TopNavBar />
            <LeftSideBar />

            <div className="target-main-wrapper inquiesid pl-16">
                <div className="p-5">
                    <div className="flex justify-between">
                        <div className="sm:flex items-center space-x-4">
                            <Link to="/employee/inquiries" className="bg-white text-[#344054] text-[14px] dark:text-white py-2 px-3 rounded-md border border-[#D0D5DD]"><i className="icon arrow_left_double mr-6 "></i>Back</Link>
                            <div className="text-2xl font-semibold dark:text-white">Inquiry ID {reactLocalStorage.get('inquiryNo')}</div>
                        </div>
                    </div>

                    <div className="lg:flex justify-between my-6">
                        <div className="tabs_two">
                            <ul className="flex">
                                <li><Link to={"/employee/inquiry/" + inquiryId} className="active">Inquiry</Link></li>
                                <li><Link to={"/employee/inquiry/bid/dashboard/" + inquiryId} className="" onClick={() => { checkInquiryRequestorInfo() }}>Bids</Link></li>
                                <li><Link to={"/employee/inquiry/quotation/dashboard/" + inquiryId} className="">Quotation</Link></li>
                                <li><Link to={"/employee/inquiry/timeline/dashboard/" + inquiryId} className="">Timeline</Link></li>
                                <li><Link to={"/employee/inquiry/mails/dashboard/" + inquiryId} className="">Mails</Link></li>
                            </ul>
                        </div>
                        <div className="mt-5 lg:mt-0">
                        </div>
                    </div>

                    <div className={"block w-full mt-5"}>
                        <div>
                            <div className='mt-10 flex-row grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 sm:grid-cols-1  gap-8 mb-10'>
                                <div className='col-span-3'>
                                    <div className='list-tab pr-5'>
                                        <ul>
                                            <li onClick={() => {
                                                setIsRequestInformation(true)
                                                setIsBasicInformation(false)
                                                setIsMoreInformation(false)
                                                setIsNotesAndAttachment(false)
                                            }} className={isRequestInformation && 'active'}><i className='icon requestorinfo_icon activeicon'></i>  Requestor  Information</li>
                                            <li onClick={() => {
                                                // setIsRequestInformation(false)
                                                // setIsBasicInformation(true)
                                                // setIsMoreInformation(false)
                                                // setIsNotesAndAttachment(false)
                                                updateRequestorInfo()
                                            }} className={isBasicInformation && 'active'}><i className='icon basicinfo_icon activeicon'></i>Inquiry - Basic Information</li>
                                            <li onClick={() => {
                                                // setIsRequestInformation(false)
                                                // setIsBasicInformation(false)
                                                // setIsMoreInformation(true)
                                                // setIsNotesAndAttachment(false)
                                                updateInquiryBasicInfo()
                                            }} className={isMoreInformation && 'active'} ><i className='icon moreinfo_icon activeicon'></i>Inquiry More Information</li>
                                            <li onClick={() => {
                                                // setIsRequestInformation(false)
                                                // setIsBasicInformation(false)
                                                // setIsMoreInformation(false)
                                                // setIsNotesAndAttachment(true)
                                                updateInquiryMoreInfo()
                                            }} className={isNotesAndAttachment && 'active'}><i className='icon attchement_icon activeicon'></i>Notes and Attachments</li>

                                        </ul>
                                    </div>
                                </div>
                                <div className='col-span-9'>

                                    {/* Inquiry - Request Information */}
                                    {
                                        isRequestInformation &&
                                        <div className='bg-white dark:bg-[#2A2E32] py-5 px-6 rounded-xl'>
                                            <div className='flex mb-5'>
                                                <h5 className="w-full text-lg font-semibold  dark:text-white border-b-2 border-[#E5E5E5] pb-3">Requestor Information</h5>
                                            </div>
                                            <div className='mt-4 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-10'>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Requestor Name  <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label> </label>
                                                    <Select placeholder='Select Requestor' options={requestorList} value={requestorName} onChange={e => {
                                                        setRequestorName(e)
                                                        onRequestorSelectChange(e)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Sales Person<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label> </label>
                                                    <Select placeholder='Select' options={salesPersonList} value={salesPersonName} onChange={e => {
                                                        setSalesPersonName(e)
                                                        setSalesPersonId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Company Name </label>
                                                    <input
                                                        value={(companyName)}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E] "
                                                        placeholder="Enter Company Name"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>  Email </label>
                                                    <input
                                                        value={(requestorEmail)}
                                                        onChange={(e) => setRequestorEmail(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                        placeholder="Enter Email"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>  Contact Number<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label>  </label>
                                                    <input
                                                        value={(requestorContactNo)}
                                                        onChange={(e) => setRequestorContactNo(e.target.value)}
                                                        type="text"
                                                        readOnly={true}
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                        placeholder="Enter Contact Number"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>  Date of Inquiry  <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label></label>
                                                    <Calendar placeholder="Date of Inquiry" className="mt-1" value={dateOfEnquiry} onChange={(e) => setDateOfEnquiry(e.value)} id="icon" showIcon />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Inquiry Source</label>
                                                    <Select placeholder='Select' options={inquirySourceList} value={inquirySource} onChange={e => {
                                                        setInquirySource(e)
                                                        setInquirySourceId(e.value)
                                                    }} className="mt-2 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Address Line 1</label>
                                                    <input
                                                        value={(address1)}
                                                        onChange={(e) => setAddress1(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E] "
                                                        placeholder="Enter Address Line 1"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Address Line 2</label>
                                                    <input
                                                        value={(address2)}
                                                        onChange={(e) => setAddress2(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E] "
                                                        placeholder="Enter Address Line 2"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Country</label>
                                                    <input
                                                        value={(country)}
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E] "
                                                        placeholder="Enter Country"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>State</label>
                                                    <input
                                                        value={(state)}
                                                        onChange={(e) => setState(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E] "
                                                        placeholder="Enter State"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>City</label>
                                                    <input
                                                        value={(city)}
                                                        onChange={(e) => setCity(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E] "
                                                        placeholder="Enter City"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Pin code</label>
                                                    <input
                                                        value={(pinCode)}
                                                        onChange={(e) => setPinCode(e.target.value)}
                                                        readOnly={true}
                                                        type="text"
                                                        id=""
                                                        className="text-[16px] rounded-lg disabled bg-[#f9f9f9] flex-1 appearance-none border border-[#D0D5DD] w-full py-2.5 px-4 bg-grey text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                        placeholder="Enter Pin code"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-end mt-5 lg:mt-0">
                                                <Link
                                                    onClick={() => {
                                                        updateRequestorInfo()
                                                    }}
                                                    className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Next</Link>
                                            </div>
                                        </div>
                                    }

                                    {/* Inquiry - Basic Information */}
                                    {
                                        isBasicInformation &&
                                        <div className='bg-white dark:bg-[#2A2E32] py-5 px-6 rounded-xl mt-1 inquiry-form'>
                                            <div className='flex mb-5'>
                                                <h5 className="w-full text-lg font-semibold  dark:text-white border-b-2 border-[#E5E5E5] pb-3">Inquiry - Basic Information</h5>
                                            </div>
                                            <div className='mt-4 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-10 inquiry-form'>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Service<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label></label>
                                                    <Select placeholder='Select Service' options={inquiryServicesList} value={inquiryServiceName} onChange={e => {
                                                        setInquiryServiceName(e)
                                                        setInquiryServicesId(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Origin Country<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label> </label>
                                                    <Select placeholder='Select Country' options={countryList} value={originCountryValue}
                                                        onChange={e => {
                                                            console.log("selected :", e)
                                                            setOriginCountryValue(e)
                                                            setOriginCountry(e.value)
                                                            onclickOriginCountry(e)
                                                        }}
                                                        className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Origin Port<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label></label>
                                                    <Select placeholder='Select'
                                                        options={portList}
                                                        value={originValue}
                                                        onChange={e => {
                                                            setOriginValue(e)
                                                            setOrigin(e.value)
                                                            onclickOrigin(e)
                                                        }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Destination Country<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                    <Select placeholder='Select' options={destinationCountryList} value={destinationCountryValue} onChange={e => {
                                                        setDestinationCountryValue(e)
                                                        setDestinationCountry(e.value)
                                                        onclickDestinationCountry(e)

                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>


                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>  Destination Port<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                    <Select placeholder='Select' options={destinationList} value={destinationValue} onChange={e => {
                                                        setDestinationValue(e)
                                                        setDestination(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'> INCO Terms </label>
                                                    <Select placeholder='Select'
                                                        options={iNCUTermList}
                                                        // value={cargoTypeName} 
                                                        // onChange={e => {
                                                        //     setCargoTypeName(e)
                                                        //     setCargoTypeId(e.value)
                                                        // }} 
                                                        className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'> Cargo Type </label>
                                                    <Select placeholder='Select Cargo Type' options={cargoTypeList} value={cargoTypeName} onChange={e => {
                                                        setCargoTypeName(e)
                                                        setCargoTypeId(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'> Movement Type </label>
                                                    <Select placeholder='Select' options={movementTypeList} value={movementTypeName} onChange={e => {
                                                        setMovementTypeName(e)
                                                        setMovementTypeId(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Cargo Ready Date</label>
                                                    <Calendar placeholder="Cargo Ready Date" className="mt-1" value={cargoReadyDate} onChange={(e) => setCargoReadyDate(e.value)} id="icon" showIcon />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>International Reference</label>
                                                    <input
                                                        value={(internationalReference)}
                                                        onChange={(e) => setInternationalReference(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] "
                                                        placeholder="Enter International Reference"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Sailing Range/ Contract Duration</label>
                                                    <div className='flex gap-3'>
                                                        <Calendar placeholder="Sailing Range" className="mt-1" value={sailingRangeDate} onChange={(e) => setSailingRangeDate(e.value)} id="icon" showIcon />
                                                        <Calendar placeholder="Contract Duration" className="mt-1" value={contractDurationDate} onChange={(e) => setContractDurationDate(e.value)} id="icon" showIcon />
                                                    </div>
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Inquiry Closing Date</label><label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label>
                                                    <Calendar placeholder="Inquiry Closing Date" className="mt-1" value={inquiryClosingDate} onChange={(e) => setInquiryClosingDate(e.value)} id="icon" showIcon />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'> Product </label>
                                                    <Select placeholder='Select' options={productList} value={productName} onChange={e => {
                                                        setProductName(e)
                                                        setProductId(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'> Product Details</label>
                                                    <input
                                                        value={(productDetails)}
                                                        onChange={(e) => setProductDetails(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] "
                                                        placeholder="Enter Product Details"
                                                    />
                                                </div>
                                            </div>


                                            <div className='flex mb-5 items-center'>
                                                <h5 className="w-full text-lg font-semibold  dark:text-white  pb-3">Cargo Details</h5>

                                                <Link
                                                    onClick={() => {
                                                        onClickAddContainerDetails()
                                                    }}
                                                    className='mt-7 w-36 flex gap-3 bg-white border text-[15px] font-normal rounded-md  px-3 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                                    <i className='pi pi-plus-circle relative top-1'></i>   <p>Add More</p>
                                                </Link>
                                            </div>
                                            {
                                                inputFields.map((item, index) => {
                                                    return (
                                                        <div>
                                                            <div
                                                                key={index}
                                                                className='mt-4 flex-row grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-12 sm:grid-cols-4  gap-4 mb-2'>
                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Count<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                                    <input placeholder='Count' type="text" value={item.count} onChange={(event) => { countHandleFormChange(index, event) }} className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" />
                                                                </div>
                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Type<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                                    <Select placeholder='Select' options={containerTypeList} value={item.type} onChange={e => {
                                                                        typeHandleFormChange(index, e)
                                                                    }} className="mt-1 my-react-select-container"
                                                                        classNamePrefix="my-react-select" />
                                                                </div>
                                                                {/* <div className='block'>
                                                                <label className='text-[15px] font-medium text-[#344054]'>Size</label>
                                                                <input placeholder='Size' type="text" value={item.size} onChange={event => sizeHandleFormChange(index, event)} className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" />
                                                            </div> */}
                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Total Cargo Wt.<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                                    <input placeholder='Total Cargo Wt.' type="text" value={item.totalCargo} onChange={event => totalCargoHandleFormChange(index, event)} className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" />
                                                                </div>
                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Unit<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                                    <Select placeholder='Select' options={unitList}
                                                                        value={item.unit}
                                                                        onChange={e => {
                                                                            unitHandleFormChange(index, e)
                                                                        }} className="mt-1 my-react-select-container"
                                                                        classNamePrefix="my-react-select" />
                                                                </div>

                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Length</label>
                                                                    <input placeholder='Enter Length' type="number"
                                                                        value={item.length}
                                                                        onChange={event =>
                                                                            lengthHandleFormChange(index, event)
                                                                        }
                                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" />
                                                                </div>
                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Width</label>
                                                                    <input placeholder='Enter Width' type="number"
                                                                        value={item.width}
                                                                        onChange={event => widthHandleFormChange(index, event)}
                                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" />
                                                                </div>
                                                                <div className='block'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Height</label>
                                                                    <input placeholder='Enter Height' type="number"
                                                                        value={item.height}
                                                                        onChange={event => heightHandleFormChange(index, event)}
                                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" />
                                                                </div>
                                                                <div className='block flex gap-5'>
                                                                    <div className='w-44'>
                                                                        <label className='text-[15px] font-medium text-[#344054]'>Dimensions</label>
                                                                        <Select placeholder='Select' options={unitList}
                                                                            value={item.dimension}
                                                                            onChange={e => {
                                                                                dimensionsHandleFormChange(index, e)
                                                                            }}
                                                                            className="mt-1 my-react-select-container"
                                                                            classNamePrefix="my-react-select" />
                                                                    </div>

                                                                    <div className='flex gap-5 block'>
                                                                        {/* {(index === 0) &&
                                                                    // <Link
                                                                    //     onClick={() => {
                                                                    //         onClickAddContainerDetails()
                                                                    //     }}
                                                                    //     className='mt-7 w-36 flex gap-3 bg-white border border-[#C6CBD2] text-[16px] font-medium rounded-md  px-3 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                                                    //     <img src={Add_Icon} alt='' />   Add More
                                                                    // </Link>
                                                                    // <>di</>
                                                                } */}
                                                                        {(index > 0) ? <Link
                                                                            onClick={() => {
                                                                                onClickRemoveContainerDetails(index)
                                                                            }}
                                                                            className='mt-7 bg-white border border-[#C6CBD2] text-[16px] font-medium rounded-md  px-3 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                                                            {/* <img src={Add_Icon} alt='' /> */}
                                                                            <i className="pi pi-times-circle relative top-0.5"></i></Link> : null}

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <hr className='mt-3' />
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div
                                                className='mt-4 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-10'>
                                                <div className='block col-span-3'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Terms & Conditions</label>
                                                    <textarea type="text"
                                                        placeholder='Terms & Conditions'
                                                        value={(termsAndConditions)}
                                                        onChange={(e) => setTermsAndConditions(e.target.value)}
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]"></textarea>
                                                </div>
                                            </div>
                                            <div className='flex justify-between'>
                                                <div className=" justify-start mt-5 lg:mt-0">
                                                    <Link
                                                        onClick={() => {
                                                            //     updateInquiryBasicInfo()
                                                            setIsRequestInformation(true)
                                                            setIsBasicInformation(false)
                                                            setIsMoreInformation(false)
                                                            setIsNotesAndAttachment(false)
                                                        }}
                                                        className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Previous</Link>
                                                </div>
                                                <div className="justify-end mt-5 lg:mt-0">
                                                    <Link
                                                        onClick={() => {
                                                            updateInquiryBasicInfo()
                                                        }}
                                                        className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Next</Link>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Inquiry - More Information */}
                                    {
                                        isMoreInformation &&
                                        <div className='bg-white dark:bg-[#2A2E32] py-5 px-6 rounded-xl'>
                                            <div className='flex mb-5'>
                                                <h5 className="w-full text-lg font-semibold  dark:text-white border-b-2 border-[#E5E5E5] pb-3">More Information</h5>
                                            </div>
                                            <div className='mt-4 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-2'>
                                                {/* <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Origin Port<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">
                                                        * </label></label>
                                                    <Select placeholder='Select' options={portList} value={originValue} onChange={e => {
                                                        setOriginValue(e)
                                                        setOrigin(e.value)
                                                        onclickOrigin(e)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>  Destination Port<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                    <Select placeholder='Select' options={destinationList} value={destinationValue} onChange={e => {
                                                        setDestinationValue(e)
                                                        setDestination(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div> */}
                                                {/* <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Origin Country <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label> </label>
                                                    <Select placeholder='Select' options={countryList} value={originCountryValue} onChange={e => {
                                                        setOriginCountryValue(e)
                                                        setOriginCountry(e.value)
                                                        onclickOriginCountry(e)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Destination Country<label htmlFor="" className="text-[#FF0000] text-[14px] font-medium">*</label></label>
                                                    <Select placeholder='Select' options={destinationCountryList} value={destinationCountryValue} onChange={e => {
                                                        setDestinationCountryValue(e)
                                                        setDestinationCountry(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div> */}


                                            </div>


                                            <div className='mt-4 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-4'>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Stuffing </label>
                                                    <Select placeholder='Select' options={stuffingTypeList} value={stuffing} onChange={e => {
                                                        setStuffing(e)
                                                        setStuffingId(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>De - Stuffing</label>
                                                    <Select placeholder='Select' options={stuffingTypeList} value={deStuffing} onChange={e => {
                                                        setDeStuffing(e)
                                                        setDeStuffingId(e.value)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                            </div>

                                            <div className='mb-5'>
                                                <div className="flex flex-row items-center mt-4">
                                                    <label className='text-[15px] font-medium text-[#344054]'>Origin Services</label>
                                                </div>
                                                <div className="flex flex-row items-center mt-2 gap-6">
                                                    {
                                                        originServiceList.map((item, index) => {
                                                            return (
                                                                <div className=''>
                                                                    <input onChange={(event) => { onClickOriginServiceCheck(index, event) }} type="checkbox" id={`Port${index}`} defaultChecked={item.isCheck}
                                                                        key={item.isCheck} className=" relative top-0.5 h-4 w-4 border-[#C6CBD2] rounded-lg" />
                                                                    <label htmlFor={`Port${index}`} className="ml-1 text-md font-normal text-[#101828]">{item.name}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>

                                            <div className='mb-4'>
                                                <div className="flex flex-row items-center mt-4">
                                                    <label className='text-[15px] font-medium text-[#344054]'>Destination Services</label>
                                                </div>
                                                <div className="flex flex-row items-center mt-2 gap-6">
                                                    {
                                                        destinationServiceList.map((item, index) => {
                                                            return (
                                                                <div className=''>
                                                                    <input
                                                                        onChange={(event) => { onClickDestinationServiceCheck(index, event) }}
                                                                        defaultChecked={item.isCheck}
                                                                        key={item.isCheck}
                                                                        type="checkbox"
                                                                        id={`Dest${index}`}
                                                                        className=" relative top-0.5 h-4 w-4 border-[#C6CBD2] rounded-lg" />
                                                                    <label htmlFor={`Dest${index}`} className="ml-1 text-md font-normal text-[#101828]">{item.name}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>

                                            <div className='mt-5 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-2'>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Required Destination Free Days</label>
                                                    <input
                                                        value={(requiredDestinationFreeDays)}
                                                        onChange={(e) => setRequiredDestinationFreeDays(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                                        placeholder="Enter Required Destination Free Days"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Required Origin Free Days</label>
                                                    <input
                                                        value={(requiredOriginFreeDays)}
                                                        onChange={(e) => setRequiredOriginFreeDays(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                                        placeholder="Enter Required Origin Free Days"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Preferred Shipping Lines</label>
                                                    <input
                                                        value={(preferredShippingLines)}
                                                        onChange={(e) => setPreferredShippingLines(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                                        placeholder="Enter Preferred Shipping Lines"
                                                    />
                                                </div>



                                                <div className='block mb-5'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Reference Rate</label>
                                                    <input
                                                        value={(referenceRateOne)}
                                                        onChange={(e) => setReferenceRateOne(e.target.value)}
                                                        type="text"
                                                        id=""
                                                        className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333] "
                                                        placeholder="Enter Reference Rate"
                                                    />
                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Currency Mode</label>
                                                    <Select
                                                        placeholder="Select Currency"
                                                        options={currencyList}
                                                        value={currency}
                                                        onChange={e => {
                                                            setCurrency(e)
                                                            setCurrencyId(e.value)
                                                        }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />

                                                </div>
                                                <div className='block'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Type</label>
                                                    <Select placeholder='Select Type' options={referenceRateType} value={referenceRateTypeValue} onChange={e => {
                                                        setReferenceRateTypeValue(e)
                                                        setReferenceRateTypeName(e.label)
                                                    }} className="mt-1 my-react-select-container"
                                                        classNamePrefix="my-react-select" />
                                                </div>

                                            </div>

                                            <div>
                                                <div className='flex justify-between'>
                                                    <label className='text-[15px] font-medium text-[#344054] mt-5'>Documents</label>
                                                    {/* {(index === 0) && */}
                                                    <Link to='' onClick={() => {
                                                        onClickAddDocument()
                                                    }}
                                                        className='flex flex-right p-2 justify-center mt-2  flex mt-5 w-32 flex gap-3 bg-white border text-[15px] font-normal rounded-md  px-3 py-2 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                                        <i class="pi pi-plus-circle relative top-1"></i>   Add More
                                                    </Link>
                                                    {/* } */}
                                                </div>
                                                {documentList.length > 0 && documentList.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div className='grid grid-cols-5 gap-4'>
                                                                <div key={index} className="col-span-2" >

                                                                    <div className='flex'>
                                                                        <input
                                                                            value={item.title}
                                                                            onChange={(event) => { titleHandleFormChange(index, event) }}
                                                                            type="text"
                                                                            name={"docs" + index} id={index}
                                                                            className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-10 py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]"
                                                                            placeholder="Enter Document Title"
                                                                        />

                                                                    </div>

                                                                </div>

                                                                <div className='flex gap-2' >
                                                                    <div className="mt-1 displaynone">
                                                                        <button className="border text-[#1B55AF] flex bg-[white] rounded-md p-2  border-[#C6CBD2] w-44 pr-3 ">
                                                                            <input
                                                                                // style={{border:'1px solid red',width:'20px'}}
                                                                                onChange={(e) => { selectInquiryDocuments(e, index) }}
                                                                                // multiple="multiple"
                                                                                type="file"
                                                                                className="cursore-pointer" />
                                                                            <img src={Upload_Icon} className="mr-3 img ml-8 cursor-pointer" />Upload</button>
                                                                    </div>

                                                                </div>

                                                                <div className='flex col-span-2' >

                                                                    {(item.filename) ?
                                                                        <div className='flex gap-2'>
                                                                            <Link
                                                                                onClick={(e) => {
                                                                                    item.itemId === 0 ? onClickRemoveInquiryDoc(index) : onClickRemoveInquiryDocuments(e, item.itemId)
                                                                                }}
                                                                                className='flex gap-2 border border-[#C6CBD2] p-2 mt-1 rounded-lg viewfile justify-between text-[14px]'>
                                                                                {item.filename}<img src={Close_Icon} className='w-5' />
                                                                            </Link>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                            <div className='flex justify-between mt-10'>
                                                <div className=" justify-start mt-5 lg:mt-0">
                                                    <Link
                                                        onClick={() => {
                                                            //     updateInquiryBasicInfo()
                                                            setIsRequestInformation(false)
                                                            setIsBasicInformation(true)
                                                            setIsMoreInformation(false)
                                                            setIsNotesAndAttachment(false)
                                                        }}
                                                        className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Previous</Link>
                                                </div>


                                                <div className="flex justify-end mt-5 lg:mt-0">
                                                    <Link
                                                        onClick={() => {
                                                            updateInquiryMoreInfo()
                                                            updateDocumentAttachment()
                                                            uploadDocumentAttachment()
                                                        }}
                                                        className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Next</Link>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* Inquiry - Notes and Attachments   */}
                                    {
                                        isNotesAndAttachment &&
                                        <div className='bg-white dark:bg-[#2A2E32] py-5 px-6 rounded-xl'>
                                            <div className='flex mb-5'>
                                                <h5 className="w-full text-lg font-semibold  dark:text-white border-b-2 border-[#E5E5E5] pb-3">Notes and Attachments</h5>
                                            </div>
                                            <div className='mt-4 flex-row grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-12 sm:grid-cols-1  gap-4 mb-2'>

                                                {
                                                    notesList.map((item, index) => {
                                                        return (
                                                            <div key={index} className='block col-span-3' >
                                                                <div className='flex justify-between items-center mb-3'>
                                                                    <label className='text-[15px] font-medium text-[#344054]'>Notes</label>
                                                                    <div className='flex gap-5 block item-center'>
                                                                        {(index === 0) &&
                                                                            <Link
                                                                                onClick={() => {
                                                                                    onClickAddNotes()
                                                                                }}
                                                                                className='flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-md  px-2 py-1.5 h-8 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                                                                <i className='pi pi-plus'></i>  Add More</Link>
                                                                        }

                                                                        {(index > 0) ? <Link
                                                                            onClick={() => {
                                                                                onClickRemoveNotes(index)
                                                                            }}
                                                                            className='flex items-center gap-2 bg-white border border-[#C6CBD2] text-[14px] font-medium rounded-md  px-2 py-1.5 h-8 removeClass dark:bg-[#4A5056] dark:border-[#4A5056] dark:text-white'>
                                                                            <i className='pi pi-times'></i>  Remove</Link> : null

                                                                        }

                                                                    </div>
                                                                </div>
                                                                <textarea placeholder='Enter Notes' value={item.notes} type="text" onChange={(event) => { notesHandleFormChange(index, event) }} className="text-[14px] rounded-lg flex-1  border border-[#D0D5DD] w-full py-2.5 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 ring-offset-green-900 focus:ring-green-900 mt-1 placeholder:text-[#667085] dark:placeholder:text-[#333]" ></textarea>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className='block col-span-3 mb-3'>
                                                    <label className='text-[15px] font-medium text-[#344054]'>Attachments</label>
                                                    <div className="border border-dashed border-[#029046] relative mt-4 bg-[#EEF8F4] rounded-md">
                                                        <input
                                                            onChange={(e) => { selectDocuments(e,) }}
                                                            multiple="multiple" type="file" className="cursor-pointer relative block opacity-0 w-full h-full p-5 z-50 h-32" />
                                                        <div className="text-center p-5 absolute top-0 right-0 left-0 m-auto">
                                                            <img src={Upload_Icon_Green} alt='' className='mx-auto' />
                                                            <h4 className='mt-2 text-[13px] text-[#029046]'>
                                                                Click or Drag here to upload
                                                            </h4>

                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    docList.map((item, index) => {
                                                        return (
                                                            <div key={index} className='block'>
                                                                <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between'>
                                                                    <div className='flex gap-2 pdf_icon image_check file_text file_excel'>
                                                                        {
                                                                            item.filename.search(".jpg") > 0 ? <i className='pi pi-image'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".jpeg") > 0 ? <i className='pi pi-image'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".png") > 0 ? <i className='pi pi-image'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".pdf") > 0 ? <i className='pi pi-file-pdf'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".txt") > 0 ? <i className='pi pi-file-o'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".xlsx") > 0 ? <i className='pi pi-file-excel'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".eml") > 0 ? <i className='pi pi-file-o'></i> : ""
                                                                        }
                                                                        <div>
                                                                            <p className='text-[14px] font-medium text-[#344054]'>{item.filename.length > 15 ? item.filename.substr(0, 15) + "..." : item.filename.substr(0, 15)}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-2'>
                                                                        <i className='icon close_icon_green'></i>
                                                                        <i onClick={() => {
                                                                            onClickRemoveDocuments(index)
                                                                        }} className='icon delete_icon_red'></i>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }



                                                {

                                                    docUploadedFileList && docUploadedFileList.map((item, index) => {
                                                        return (
                                                            <div className='block'>
                                                                <div className='flex gap-2 border cursor-pointer border-[#C6CBD2] p-4 rounded-lg viewfile justify-between'>
                                                                    <div className='flex gap-2 pdf_icon image_check file_text file_excel'>
                                                                        {
                                                                            item.filename.search(".jpg") > 0 ? <i className='pi pi-image'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".jpeg") > 0 ? <i className='pi pi-image'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".png") > 0 ? <i className='pi pi-image'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".pdf") > 0 ? <i className='pi pi-file-pdf'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".txt") > 0 ? <i className='pi pi-file-o'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".xlsx") > 0 ? <i className='pi pi-file-excel'></i> : ""
                                                                        }
                                                                        {
                                                                            item.filename.search(".eml") > 0 ? <i className='pi pi-file-o'></i> : ""
                                                                        }
                                                                        <div>
                                                                            <p className='text-[15px] font-medium text-[#344054]'>{item.filename.length > 15 ? item.filename.substr(0, 15) + "..." : item.filename.substr(0, 15)}</p>
                                                                            {/* <p className='text-[12px] font-normal text-[#98A2B3]'>315kb / 500kb</p> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex gap-2'>
                                                                        <i className='icon close_icon_green'></i>
                                                                        <i onClick={() => {
                                                                            onClickRemoveDocumentsPath(item.path, index)
                                                                        }} className='icon delete_icon_red'></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className='flex justify-between mt-10'>
                                                <div className=" justify-start mt-5 lg:mt-0">
                                                    <Link
                                                        onClick={() => {
                                                            //     updateInquiryBasicInfo()
                                                            setIsRequestInformation(false)
                                                            setIsBasicInformation(false)
                                                            setIsMoreInformation(true)
                                                            setIsNotesAndAttachment(false)
                                                        }}
                                                        className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Previous</Link>
                                                </div>
                                                <div className="flex justify-end mt-5 lg:mt-0">
                                                    <button
                                                        onClick={() => {
                                                            updateNotesAndAttachment()
                                                        }}
                                                        className="bg-[#029046] text-white rounded-md px-4 py-2 lg:py-2 text-[16px] lg:text-md shadow-md inline-block addclassName" >Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="selector"></div>


        </div >
    )
        : (
            <EmpLogin sessionExpired={1} />
        );
}