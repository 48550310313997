import Select from 'react-select';
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ValidateEmployeeRole } from '../../../../../components/helper/validateRole';
import LeftSideBar from '../../../../../components/common/employee/leftnav.component';
import TopNavBar from '../../../../../components/common/employee/topnav.component';
import { SalesPerson, State } from '../../../../../models';
import EmpLogin from "../../../../../setup/auth/emp.component";
import ValidateEmail from "../../../../../components/common/ValidateEmail";
import PhoneInput from "react-phone-input-2";
import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import { ConvertResponseForSelect } from '../../../../../components/helper/commonfunctions';


export default function EmployeeCreateSalesPersons() {
    //Validate User Logged In
    const [isAuthenticated, userHasAuthenticated] = useState(true);
    const [showPleaseWaitButton, setPleaseWaitButton] = useState(false);
    const { editSalesPersonId } = useParams();
    const [isEditMode, setEditMode] = useState(false);
    let navigateTo = useNavigate();
    const [employeeIDForEdit, setSalesPersonIDForEdit] = useState("");
    const [stateId, setStateId] = useState("");

    // lists
    const [salesPerson, setSalesPersons] = useState([]);

    //SalesPerson Info
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [state, setState] = useState("");


    //Create Record
    const saveSalesPerson = async () => {
        if (firstName === '') { toast.error('Please enter First Name.'); return; }
        else if (lastName === '') { toast.error('Please enter Last Name.'); return; }
        else if (!ValidateEmail(email)) { toast.error('Invalid email.'); return; }

        setPleaseWaitButton(true);
        if (isEditMode) {
            const original = await DataStore.query(SalesPerson, editSalesPersonId);
            await DataStore.save(
                SalesPerson.copyOf(original, (updated) => {
                    updated.firstName = firstName;
                    updated.lastName = lastName;
                    updated.email = email;
                    updated.address1 = address1;
                    updated.address2 = address2;
                    updated.phoneNo = phoneNo;
                    updated.city = city;
                    updated.zip = zipCode;
                    updated.stateId = stateId;
                })
            );
            toast.success('Updated');
            { goBack() }
        } else {
            const checkDuplicateEmail = await DataStore.query(SalesPerson, (c) => c.email.eq(email));
            console.log(checkDuplicateEmail.length)
            if (checkDuplicateEmail.length == 1) {
                toast.error('Email already exists.');
                setPleaseWaitButton(false);
            } else {
                //Save SalesPerson
                await DataStore.save(
                    new SalesPerson({
                        "firstName": firstName,
                        "lastName": lastName,
                        "email": email,
                        "address1": address1,
                        "address2": address2,
                        "phoneNo": phoneNo,
                        "city": city,
                        "zipCode": zipCode,
                        "stateId": stateId,
                    })
                ).then(() => {
                    toast.success('Added');
                })
                { goBack() }
            }
        }
    }
    const goBack = () => {
        setPleaseWaitButton(false);
        navigateTo("/employee/settings/salesperson");
    }

    async function BindExistingData() {
        // Edit existing record
        if (editSalesPersonId != null) {
            setSalesPersonIDForEdit(editSalesPersonId);
            const original = await DataStore.query(SalesPerson, editSalesPersonId);
            const state_original = await DataStore.query(State, original.stateId);
            if (original != null) {
                setEditMode(true);
                setFirstName(original.firstName);
                setLastName(original.lastName);
                setEmail(original.email);
                setAddress1(original.address1);
                setAddress2(original.address2);
                setPhoneNo(original.phoneNo);
                setCity(original.city)
                setZipCode(original.zipCode);
                setState({ label: state_original.name, value: state_original.id });
                setStateId(state_original.id)
            }
            else {
                toast.error('Invalid record.');
                goBack()
            }
        }
    }


    async function onLoad() {
        try {
            if (!ValidateEmployeeRole()) {
                userHasAuthenticated(false);
            }
        } catch (e) {
            userHasAuthenticated(false);
        }
    }

    async function BindList() {
        //State List
        var stateResponses = await DataStore.query(State, Predicates.ALL, {
            sort: s => s.name(SortDirection.ASCENDING)
        });
        var myStateArray = []; myStateArray = ConvertResponseForSelect(stateResponses); setSalesPersons(myStateArray);

    }


    useEffect(() => {
        onLoad();
        BindExistingData();
        BindList();
    }, []);

    return isAuthenticated ? (
        <div>
            <div className="pt-16 xl:h-full lg:h-full md:h-full 2xl:h-full ">
                <TopNavBar />
                <LeftSideBar />
                <div className="pl-16">
                    <div
                        className="flex grid flex-row flex-wrap justify-center flex-1 gap-4 p-5 pl-5 pr-5 mt-5 align-center 2xl:grid-cols-8 xl:grid-cols-10 lg:grid-cols-8 md:grid-cols-8 sm:grid-cols-1 contract-grid-wrapper">
                        <div className="col-span-6 col-start-3 lg:col-start-3 xl:col-start-3 2xl:col-start-2">
                            <div className="p-3 py-5 bg-white rounded-md shadow dark:bg-[#252423]">
                                <div className="flex justify-center mt-3 mb-2">
                                    <h2 className="page-title">
                                        {isEditMode ? "Edit" : "Create"} Sales Person
                                    </h2>
                                </div>
                                <div x-data="{tab: 1}"></div>
                                <div className={"block w-full"}>
                                    <div className="px-4" x-show="tab === 1">
                                        <div className="flex grid flex-row flex-wrap flex-1 mb-6 align-center 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-x-4">
                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    First Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(firstName)} onChange={e => setFirstName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter First Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Last Name <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label></label>
                                                <input value={(lastName)} onChange={e => setLastName(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Enter Last Name" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 1
                                                </label>
                                                <input value={(address1)} onChange={e => setAddress1(e.target.value)} type="text" className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" placeholder="Address 2" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Address 2
                                                </label>
                                                <input
                                                    defaultValue={address2}
                                                    onChange={(e) => setAddress2(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Address 2"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    City
                                                </label>
                                                <input
                                                    value={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="City"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Zip Code
                                                </label>
                                                <input
                                                    value={zipCode}
                                                    onChange={(e) => setZipCode(e.target.value)}
                                                    type="text"
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"
                                                    placeholder="Zip Code"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Email <label htmlFor="" className="text-[#FF0000] text-[14px] font-medium ">
                                                        * </label> </label>
                                                <input disabled={isEditMode ? true : false} value={email} onChange={e => setEmail(e.target.value)} type="text"
                                                    className={isEditMode ? "disabled bg-[#e1e1e1] text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]" : "text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 mt-1.5 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#FFFFFF]"}
                                                    placeholder="Enter Email" />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    Phone No
                                                </label>
                                                <PhoneInput
                                                    enableSearch
                                                    buttonStyle={{ background: 'transparent', borderWidth: 0 }}
                                                    inputStyle={{ borderWidth: 0, fontSize: 16, background: 'transparent' }}
                                                    className="text-[16px] rounded-lg   flex-1  border border-[#D0D5DD] w-full py-1 mt-1 px-4 bg-white text-gray-700 placeholder-gray-500 shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 placeholder:text-[#667085] dark:bg-[#333231] dark:border-[#333231] dark:text-[#8E8E8E]"
                                                    country={'us'}
                                                    value={phoneNo}
                                                    onChange={(phone) => setPhoneNo("+" + phone)}
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                                <label className="text-[#344054] text-[14px] font-medium dark:text-[#FFFFFF]">
                                                    State
                                                </label>
                                                <Select
                                                    value={state}
                                                    options={salesPerson}
                                                    onChange={e => {
                                                        setStateId(e.value);
                                                        setState(e)
                                                    }}
                                                    className="mt-2 my-react-select-container"
                                                    classNamePrefix="my-react-select"
                                                />
                                            </div>

                                            <div className="relative mb-4">
                                            </div>
                                        </div>
                                        <div className="grow flex justify-center gap-3 mb-3">
                                            <Link to="/employee/settings/salesperson" className=" py-2 px-5 bg-white text-[#344054] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md dark:bg-[#4A5056]  dark:border-[#333231] dark:text-[#FFFFFF]">Cancel</Link>
                                            <button onClick={() => saveSalesPerson()} className=" py-2 px-6 bg-btn-blue text-[#fff] transition ease-in duration-200 text-[14px] text-center font-medium  shadow-md focus:outline-none leading-6  justify-center rounded-lg border border-[#D0D5DD] rounded-md">{showPleaseWaitButton ? 'Please wait...' : 'Submit'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <EmpLogin sessionExpired={1} />
    );
}
